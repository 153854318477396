import React from 'react';
import './CourseOverView.css';
import { CourseSkills } from 'components/_v2/Course/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const CourseOverView = ({
	description,
	preRequisites,
	objectives,
	skills,
	fullWidth = false,
}: {
	description: string;
	preRequisites?: string;
	objectives: { objective: string }[];
	skills: CourseSkills[];
	fullWidth?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();
	return (
		<section className={fullWidth ? '' : isSmallScreen ? 'py-3' : 'course-overview-wrapper p-3'}>
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-10'}`}>
						<div className="row">
							<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-9'} text-justify`}>
								<h2 className="overview-title lora">Course description</h2>
								<div>
									<p
										className="course-desc-label g_reg"
										dangerouslySetInnerHTML={{ __html: description }}></p>
								</div>
								{preRequisites && (
									<div>
										<h2 className="pre-title lora">Pre requisites:</h2>
										<p
											className="pre-label g_reg"
											dangerouslySetInnerHTML={{ __html: preRequisites }}></p>
									</div>
								)}
								<div>
									<h2 className="pre-title lora">Learning objectives</h2>
									<ul className="text-decoration-none">
										{objectives.map((objective, index) => (
											<li key={objective.objective + index} className="objective-label g_reg">
												{objective.objective}
											</li>
										))}
									</ul>
								</div>
								<div>
									<h2 className="pre-title lora">Skills covered</h2>
									<div>
										{skills.map((skill) => (
											<button key={skill.id} className="btn btn-custom-style my-md-0 my-2">
												{skill.name}
											</button>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseOverView;
