import React from 'react';
import './herosection.css';
import { toAbsoluteUrl } from '../../../assets/helpers/AssetHelpers';
import { useAccordionButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { EXPLORE_COURSE_SECTION } from 'utils/consts';

const HeroSection = () => {
	const navigate = useNavigate();
	const handlecourseredirect = (sectionid: any) => {
		const contactsection = document.getElementById(sectionid);
		if (contactsection) {
			contactsection.scrollIntoView({ behavior: 'smooth' });
		}
	};
	return (
		<>
			<section className="hero-section-wrapper">
				<video autoPlay muted loop>
					<source
						src={toAbsoluteUrl('/media/herosection/hero-video.mp4')}
						type="video/mp4"
						className="mask"
					/>
					Your browser does not support the video tag.
				</video>
				<div className="video-overlay"></div>
				<div className="container-fluid content">
					<div className="row">
						<div className="col-lg-8 col-md-10 col-11 col-sm-11 mx-auto text-center">
							<p className="hero-title lora">
								Navigate the Stock Market with Confidence &nbsp;
								<span className="color2">Learn, Invest, Succeed</span>
							</p>
						</div>
						<div className="col-lg-6 col-md-11 col-11 col-sm-11 text-center mx-auto mt-lg-3 mt-md-3 mt-3 mt-sm-3 mb-lg-5 mb-md-5 mb-5 mb-sm-5">
							<p className="hero-subtitle lora">
								Make yourself loss avert by learning the latest trading skills including strategy
								making, algo trading, derivatives and many more
							</p>
						</div>
						<div>
							{/* <button className="button button-yellow g_mid">
                Start Your Growth
              </button> */}
							<button
								className="button start-btn mt-2 start-btn-hover g_mid"
								onClick={() => handlecourseredirect('explore-course')}>
								Start Your Growth
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HeroSection;
