import { Mentor } from 'components/_v2/Course/type';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';

export const mentorNamesRenderer = (mentors: { mentor: Mentor }[]) =>
	mentors.map((mentorDetails, index) => {
		let textToRender = mentorDetails.mentor.firstName + ' ' + mentorDetails.mentor.lastName;
		if (index < mentors.length - 1) {
			textToRender += ', ';
		}
		return textToRender;
	});

export const mentorProfileNameRenderer = (
	mentors?: { mentor: Mentor }[],
	isSmallScreen = false,
) => {
	if (!mentors) {
		return;
	}
	return mentors.map((mentor, index) => (
		<div
			key={mentor.mentor.id + index}
			className={isSmallScreen ? 'd-flex d-md-none w-100' : 'd-none d-sm-flex'}>
			<div>
				<img
					src={toAbsoluteUrl(
						mentor.mentor.userProfile?.[0]?.imageUrl ?? '/media/detail-hero-section/detail-dp.png',
					)}
					style={{ borderRadius: '50%' }}
					className="me-2"
					alt="Mentor "
					height="48px"
					width="48px"
				/>
			</div>
			<div className="">
				<span className="profile-mentor">Mentor</span> <br />
				<span className="profile-mentro-name g_mid">{mentor.mentor.firstName}</span>
			</div>
		</div>
	));
};
