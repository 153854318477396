import React, { useCallback, useMemo } from "react";
import "./best.css";

interface BestProps {
  wrapperClass?: string;
  containerClass?: string;
  headingClass?: string;
  descriptionClass?: string;
  titleClass?: string;
}

const Best: React.FC<BestProps> = ({ wrapperClass = "", containerClass = "", headingClass = "", descriptionClass = "", titleClass = " " }) => {
  const data = useMemo(
    () => (heading: string, description: string) => {
      return (
        <div className={`col-lg-3 col-md-6 col-12 text-center my-4 ${containerClass}`}>
          <p className={`number-style lora ${headingClass}`}>{heading}</p>
          <p className={`number-desc g_reg ${descriptionClass}`}>{description}</p>
        </div>
      );
    },
    [containerClass, headingClass, descriptionClass, wrapperClass],
  );

  return (
    <>
      <section className={`best-wrapper ${wrapperClass}`}>
        <div className={`container ${containerClass}`}>
          <div className="row py-4">
            <div className="col-lg-10 col-md-10 col-10 col-sm-10 layout-set my-5">
              <h2 className={`lora text-center best-section-title ${titleClass}`}>
                Learn From The Greatest To Be The Best
              </h2>
              <div className="d-flex flex-wrap justify-content-between mt-lg-5 mt-md-3 mt-4">
                {data("12+", "Years of Experiences")}
                {data("99%", "Accuracy Rate")}
                {data("500+", "Positive Reviews")}
                {data("10+", "Trusted Partners")}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Best;
