import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import './hgheader.css';
import { BLOGS_SECTION, FAQS_SECTION, STORIES_SECTION } from 'utils/consts';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL, COURSE_URL, DETAIL_URL, ZOOM_MEETING } from 'pages/routing/routePages';
import { EHeaderType } from 'types/globalTypes';
import { useUserContext } from 'context/UserContext';
import LoggedInUser from './LoggedInUser';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import ProfileListItems from 'components/_v2/Header/ProfileListItems';

const HGHeader = ({ headerType = EHeaderType.WHITE }: { headerType: EHeaderType }) => {
	const { user } = useUserContext();
	const { isZoomVisited } = useZoomMeeting();
	const isSmallScreen = useIsSmallScreen(768);
	const isTabletView = useIsSmallScreen(1200);
	const navigate = useNavigate();
	const location = useLocation();
	const isHomePage = location.pathname === '/';
	const [scrolled, setScrolled] = useState(false);
	const isZoomMeetingPage = location.pathname.includes(ZOOM_MEETING);

	// Hide the header if both parameters are present
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 50) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	//Remove header on Zoom Meeting page
	if (isZoomMeetingPage || isZoomVisited) return null;

	const headerlinks = (headerlink: string, id: string) => {
		return (
			<li
				className={
					'header-nav-item nav-item ' + (headerType === EHeaderType.WHITE ? 'black' : 'white')
				}
				onClick={() => navigate(`${BASE_PAGE_URL}#${id}`)}>
				<span style={{ cursor: 'pointer' }}>{headerlink}</span>
			</li>
		);
	};

	const headerLink = (headerLink: string, url: string) => {
		return (
			<li
				className={
					'header-nav-item nav-item ' + (headerType === EHeaderType.WHITE ? 'black' : 'white')
				}>
				<Link className="active" aria-current="page" to={url}>
					{headerLink}
				</Link>
			</li>
		);
	};

	return (
		<nav
			className={`header-wrapper ${
				isHomePage
					? scrolled
						? 'black-header'
						: 'transparent-header'
					: headerType === EHeaderType.WHITE
					? 'white-header'
					: 'black-header'
			} navbar navbar-white fixed-top px-lg-5 nav`}>
			<div className="container-fluid w-100 col-lg-10 mx-lg-auto px-lg-4 col-md-12 py-lg-3 py-md-3 px-sm-4 py-2">
				<Link to="/">
					<img
						src={toAbsoluteUrl(
							`/media/logo/hoGrowth${headerType === EHeaderType.WHITE ? 'Black' : 'White'}.svg`,
						)}
						alt="HoGrowth_logo"
					/>
				</Link>

				<ul className="navbar-nav text-center">
					{headerLink('Courses', COURSE_URL)}
					{headerlinks('Student Stories', STORIES_SECTION)}
					{headerlinks('FAQs', FAQS_SECTION)}
					{headerlinks('Blogs', BLOGS_SECTION)}
				</ul>

				<div className="auth d-flex">
					{/* Login and Signup Button and After login view */}
					<div className="justify-content-end">
						{user ? (
							// <div className={`align-items-center ${isSmallScreen ? "ms-5" : ""}`}>
							<div className={`align-items-center me-md-4 me-3`}>
								<LoggedInUser
									user={user}
									headerType={headerType}
									userProfileIcon={!isTabletView}
									notification_icon={true}
									disableClick={false}
								/>
							</div>
						) : (
							<div className="authorization d-md-inline-flex me-md-4">
								<LoginButton headerType={headerType} />
								<SignupButton />
							</div>
						)}
					</div>

					{/* Hamburg Icon View */}
					<button
						className="navbar-toggler custom-toggler border-0"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#offcanvasDarkNavbar"
						aria-controls="offcanvasDarkNavbar">
						{/* <span className="navbar-toggler-icon"></span> */}
						<img
							src={toAbsoluteUrl(
								`/media/hgheader/hamburger-menu-${
									headerType === EHeaderType.WHITE ? 'black' : 'white'
								}.svg`,
							)}
							alt="HoGrowth_logo"
							width="35px"
							height="40px"
						/>
					</button>
				</div>

				<div
					className="drawer-align offcanvas offcanvas-end text-bg-white"
					tabIndex={-1}
					data-bs-scroll="true"
					data-bs-backdrop="true"
					style={{ zIndex: 1111 }}
					id="offcanvasDarkNavbar"
					aria-labelledby="offcanvasDarkNavbarLabel">
					<div className="offcanvas-header nav_close_btn">
						<h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel"></h5>
						<button
							type="button"
							className="btn-close btn-close-black"
							data-bs-dismiss="offcanvas"
							aria-label="Close"></button>
					</div>
					<div className="justify-content-end mt-3">
						{user ? (
							<div className="mt-2">
								<LoggedInUser
									user={user}
									headerType={headerType}
									userProfileIcon={isTabletView}
									notification_icon={false}
									disableClick={isTabletView}
								/>
							</div>
						) : (
							<div className="nav-auth-btn" data-bs-dismiss="offcanvas">
								<LoginButton headerType={headerType} />
								<SignupButton />
							</div>
						)}
					</div>
					<div className={`${user ? 'mt-md-4 border-bottom mt-4' : 'mt-md-0 mt-2 border-0'}`}></div>
					{user && (
						<div className="px-4 pt-4" data-bs-dismiss="offcanvas">
							<ProfileListItems />
						</div>
					)}
					{(user || isSmallScreen) && <div className="border-bottom mt-4"></div>}
					<div className="offcanvas-body white">
						<li className="col-md-12 col-12 nav-item" data-bs-dismiss="offcanvas">
							<Link className="nav-link nav-text" aria-current="page" to={COURSE_URL}>
								Courses
							</Link>
						</li>
						<li className="col-md-12 col-12 nav-item">
							<Link
								className="nav-link"
								to={`${BASE_PAGE_URL}#${STORIES_SECTION}`}
								data-bs-dismiss="offcanvas">
								Student Stories
							</Link>
						</li>
						<li className="col-md-12 col-12 nav-item">
							<Link
								className="nav-link"
								to={`${BASE_PAGE_URL}#${FAQS_SECTION}`}
								data-bs-dismiss="offcanvas">
								FAQs
							</Link>
						</li>
						<li className="col-md-12 col-12 nav-item">
							<Link
								className="nav-link"
								to={`${BASE_PAGE_URL}#${BLOGS_SECTION}`}
								data-bs-dismiss="offcanvas">
								Blogs
							</Link>
						</li>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default HGHeader;
