import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { RESET_PASSWORD_TOKEN, RESET_PASSWORD } from '../../Courses-Page/core/requests';
import './fgpassword.css';

const ChangePasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required')
});

const VerificationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special case character'
    )
    .required('New Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  otp: Yup.string().required('OTP is required')
});

export const ForGotPassword = (props: any) => {
  const { showForgotPasswordModal, handleForgotPasswordClose } = props;
  const [requestPasswordResetTokenMutation] = useMutation(RESET_PASSWORD_TOKEN);
  const [resetForgotPasswordMutation] = useMutation(RESET_PASSWORD);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(89);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
    } else {
      setResendEnabled(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const initialValues = { email: '', type: 'APP' };
  const verificationInitialValues = {
    newPassword: '',
    confirmNewPassword: '',
    otp: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { resetForm }) => {
      try {
        requestPasswordResetTokenMutation({
          variables: {
            forgotPasswordInput: {
              email: values.email,
              type: values.type
            }
          },
          onCompleted: () => {
            toast.success('Password reset token sent successfully.');
            setEmail(values.email);
            setShowVerificationModal(true);
            setTimer(89); // Reset the timer when token is sent
            setResendEnabled(false); // Disable resend OTP button initially
            resetForm();
          },
          onError: (err) => {
            toast.error(err.message);
          }
        });
        handleForgotPasswordClose();
      } catch (error) {
        toast.error('An error occurred while sending the password reset token.');
      }
    }
  });

  const verificationFormik = useFormik({
    initialValues: verificationInitialValues,
    validationSchema: VerificationSchema,
    onSubmit: (values, { resetForm }) => {
      try {
        resetForgotPasswordMutation({
          variables: {
            resetForgotPasswordInput: {
              resetPasswordKey: values.otp,
              newPassword: values.newPassword,
              confirmNewPassword: values.confirmNewPassword
            }
          },
          onCompleted: () => {
            toast.success('Password reset successfully.');
            setShowVerificationModal(false);
          },
          onError: (err) => {
            toast.error(err.message);
          }
        });
      } catch (error) {
        toast.error('An error occurred while resetting the password.');
      }
    }
  });

  const handleVerificationModalClose = () => setShowVerificationModal(false);

  const handleResendOTP = () => {
    setTimer(89); // Reset the timer when OTP is resent
    setResendEnabled(false);
    // Logic to resend OTP can be added here
    toast.success('OTP resent successfully.');
  };

  return (
    <>
      <Modal
        show={showForgotPasswordModal}
        onHide={handleForgotPasswordClose}
        centered
        className="">
        <Modal.Header className="">
          <Modal.Title className="text-center">
            <div className="text-center">
              <img src={toAbsoluteUrl('/media/logo/fgpassword.png')} alt="" />
            </div>
            <h2 className="fg-title lora">Password Reset</h2>
            <p className="fg-label g_reg">
              Enter email address associated with your HoGrowth account
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-2">
          <form onSubmit={formik.handleSubmit}>
            <>
              <div className="col-12">
                <label className="label-style g_reg label-fg-layout">
                  Email <span style={{ color: "red" }}>*</span>
                </label>
                <div className="input-group">
                  <input
                    type={'email'}
                    placeholder="Enter Email Address"
                    autoComplete="off"
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control input-inner-style g_reg',
                      {
                        'is-invalid': formik.touched.email && formik.errors.email
                      },
                      {
                        "is-valid":
                          formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <div className="text-danger mt-1 fs-7">
                    <i className="fa fa-exclamation-circle me-1"></i>
                    {formik.errors.email}
                  </div>
                )}
              </div>
              <div className="mt-5 mb-4 col-12 text-center flex-column-reverse d-md-block d-flex gap-4">
                <button
                  className="btn g_mid me-md-3 new-btn-fg-cancel-layout"
                  type="button"
                  onClick={handleForgotPasswordClose}
                  disabled={formik.isSubmitting}>
                  Cancel
                </button>
                <button
                  className="btn bg-color2 g_mid new-btn-fg-layout"
                  type="submit"
                  disabled={formik.isSubmitting}>
                  Continue
                </button>
              </div>
            </>
          </form>
        </Modal.Body>
      </Modal>

      {/* Verification Modal */}
      {/* <Modal show={showVerificationModal} onHide={handleVerificationModalClose} centered>
        <Modal.Body>
          <div className="text-center mx-2 mt-4">
            <div>
              <img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
            </div>
            <h2 className="veri-title lora">Verification</h2>
            <p className="veri-label g_reg">
              Please enter the 6-digit code sent to <br />
              <span className="veri-label-strong">{email}</span>
            </p>
            <form onSubmit={verificationFormik.handleSubmit}>
              <div className="text-start mb-3">
                <label className="label-style g_reg" htmlFor="">
                  New Password<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="password"
                  className="form-control input-inner-veri-style g_reg"
                  placeholder="Enter New Password"
                  autoComplete="off"
                  {...verificationFormik.getFieldProps('newPassword')}
                />
                {verificationFormik.touched.newPassword &&
                  verificationFormik.errors.newPassword && (
                    <div className="text-danger mt-1 fs-7">
                      <i className="fa fa-exclamation-circle me-1"></i>
                      {verificationFormik.errors.newPassword}
                    </div>
                  )}
              </div>
              <div className="text-start mb-3">
                <label className="label-style g_reg" htmlFor="">
                  Confirm Password<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="password"
                  className="form-control input-inner-veri-style g_reg"
                  placeholder="Enter Confirm Password"
                  autoComplete="off"
                  {...verificationFormik.getFieldProps('confirmNewPassword')}
                />
                {verificationFormik.touched.confirmNewPassword &&
                  verificationFormik.errors.confirmNewPassword && (
                    <div className="text-danger mt-1 fs-7">
                      <i className="fa fa-exclamation-circle me-1"></i>
                      {verificationFormik.errors.confirmNewPassword}
                    </div>
                  )}
              </div>
              <div className="text-start mb-3">
                <label className="label-style g_reg" htmlFor="">
                  OTP<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-inner-veri-style g_reg"
                  placeholder="Enter OTP"
                  autoComplete="off"
                  {...verificationFormik.getFieldProps('otp')}
                />
                {verificationFormik.touched.otp && verificationFormik.errors.otp && (
                  <div className="text-danger mt-1 fs-7">
                    <i className="fa fa-exclamation-circle me-1"></i>
                    {verificationFormik.errors.otp}
                  </div>
                )}
              </div>
              <p className="veri-desc text-start my-3">
                {resendEnabled ? (
                  <button
                    className="btn resend-btn p-0 m-0 align-baseline"
                    onClick={handleResendOTP}>
                    Resend OTP
                  </button>
                ) : (
                  <>
                    Please wait &nbsp;
                    <span className="veri-desc-strong">{timer}s</span>
                    &nbsp; before requesting another code
                  </>
                )}
              </p>
              <div className="my-3 col-12 text-center">
                <button
                  className="btn g_mid me-3 new-btn-fg-cancel-layout"
                  type="button"
                  onClick={handleVerificationModalClose}
                  disabled={verificationFormik.isSubmitting}>
                  Cancel
                </button>
                <button
                  className="btn bg-color2 g_mid new-btn-fg-layout"
                  type="submit"
                  disabled={verificationFormik.isSubmitting}>
                  Save Password
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal> */}
      <Modal show={showVerificationModal} onHide={handleVerificationModalClose} centered>
        <Modal.Body>
          <div className="text-center mx-2 mt-4">
            <div>
              <img src={toAbsoluteUrl('/media/logo/veri.png')} alt="Verification" />
            </div>
            <h2 className="veri-title lora">Verification</h2>
            <p className="veri-label g_reg">
              Please enter the 6-digit code sent to <br />
              <span className="veri-label-strong">{email}</span>
            </p>
            <form onSubmit={verificationFormik.handleSubmit}>
              <div className="text-start mb-3 position-relative">
                <label className="label-style g_reg" htmlFor="">
                  New Password<span style={{ color: 'red' }}>*</span>
                </label>
                <div className="password-input-wrapper position-relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control input-inner-veri-style g_reg"
                    placeholder="Enter New Password"
                    autoComplete="off"
                    {...verificationFormik.getFieldProps('newPassword')}
                  />
                  <button
                    type="button"
                    className="btn eye-icon eye-icon-outline position-absolute"
                    style={{
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }}
                    onClick={() => setShowPassword(!showPassword)}>
                    <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>
                </div>
                {verificationFormik.touched.newPassword &&
                  verificationFormik.errors.newPassword && (
                    <div className="text-danger mt-1 fs-7">
                      <i className="fa fa-exclamation-circle me-1"></i>
                      {verificationFormik.errors.newPassword}
                    </div>
                  )}
              </div>

              <div className="text-start mb-3 position-relative">
                <label className="label-style g_reg" htmlFor="">
                  Confirm Password<span style={{ color: 'red' }}>*</span>
                </label>
                <div className="password-input-wrapper position-relative">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    className="form-control input-inner-veri-style g_reg"
                    placeholder="Enter Confirm Password"
                    autoComplete="off"
                    {...verificationFormik.getFieldProps('confirmNewPassword')}
                  />
                  <button
                    type="button"
                    className="btn eye-icon eye-icon-outline position-absolute"
                    style={{
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    <i className={`fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>
                </div>
                {verificationFormik.touched.confirmNewPassword &&
                  verificationFormik.errors.confirmNewPassword && (
                    <div className="text-danger mt-1 fs-7">
                      <i className="fa fa-exclamation-circle me-1"></i>
                      {verificationFormik.errors.confirmNewPassword}
                    </div>
                  )}
              </div>

              <div className="text-start mb-3">
                <label className="label-style g_reg" htmlFor="">
                  OTP<span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control input-inner-veri-style g_reg"
                  placeholder="Enter OTP"
                  autoComplete="off"
                  {...verificationFormik.getFieldProps('otp')}
                />
                {verificationFormik.touched.otp && verificationFormik.errors.otp && (
                  <div className="text-danger mt-1 fs-7">
                    <i className="fa fa-exclamation-circle me-1"></i>
                    {verificationFormik.errors.otp}
                  </div>
                )}
              </div>
              <p className="veri-desc text-start my-3">
                {resendEnabled ? (
                  <button
                    className="btn resend-btn p-0 m-0 align-baseline"
                    onClick={handleResendOTP}>
                    Resend OTP
                  </button>
                ) : (
                  <>
                    Please wait &nbsp;
                    <span className="veri-desc-strong">{timer}s</span>
                    &nbsp; before requesting another code
                  </>
                )}
              </p>
              <div className="my-3 col-12 text-center">
                <button
                  className="btn g_mid me-3 new-btn-fg-cancel-layout"
                  type="button"
                  onClick={handleVerificationModalClose}
                  disabled={verificationFormik.isSubmitting}>
                  Cancel
                </button>
                <button
                  className="btn bg-color2 g_mid new-btn-fg-layout"
                  type="submit"
                  disabled={verificationFormik.isSubmitting}>
                  Save Password
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
