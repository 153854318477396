import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Image } from 'react-bootstrap';
import { LessonType } from 'components/_v2/Course/type';
import { useQuizContext } from 'context/QuizContext';
import moment from 'moment';

const ImageTextRenderer = ({
	imageUrl,
	textContent,
}: {
	imageUrl: string;
	textContent: React.ReactElement | string;
}) => (
	<div>
		<Image src={toAbsoluteUrl(`/media/course-detail-images/${imageUrl}.svg`)} className="mb-1" />
		<span className="score-mark ms-2 mt-2">{textContent}</span>
	</div>
);

const QuizCompletedButtons = ({
	courseId,
	lessonData,
	totalMarksAttained,
	totalQuizMarks,
}: {
	lessonData: LessonType;
	courseId: string;
	totalMarksAttained: number;
	totalQuizMarks: number;
}) => {
	const { quizResult } = useQuizContext();

	return (
		<div className="dashedLine w-100 px-4">
			<div className="d-flex w-100 justify-content-between  mb-4 mt-3">
				<ImageTextRenderer
					imageUrl="score-attained"
					textContent={
						<span className="g_reg score-mark">
							{totalMarksAttained} / {totalQuizMarks}
						</span>
					}
				/>
				<ImageTextRenderer
					imageUrl="time-taken"
					textContent={
						<span>
							{moment(quizResult.quizSubmitTime)
								.diff(moment(quizResult.quizStartTime), 'minutes')
								.toFixed(0)}
							/ {(lessonData.quiz.duration / 60).toFixed(0)} Minutes
						</span>
					}
				/>
				<ImageTextRenderer imageUrl="skill-level" textContent="Intermediate" />
			</div>
		</div>
	);
};

export default QuizCompletedButtons;
