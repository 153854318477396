import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Course } from 'components/_v2/Course/type';
import { useUserContext } from 'context/UserContext';
import { getCoursePaymentSubUrl, getViewCourseWithSearchSlug } from 'pages/routing/routePages';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RUPEE_SYMBOL } from 'utils/consts';
import './DetailHeroSection.css';
import { Login } from 'pages/auth/Login';
import { Register } from 'pages/auth/Register';
import { useCourseContext } from 'context/CourseContext';
import useLoginRegister from 'hooks/useLoginRegister';

const PopoverCourseDetailCard = ({ course }: { course: Course }) => {
	const { user } = useUserContext();
	const { userCourses } = useCourseContext();
	const navigate = useNavigate();
	const {
		toggleLoginOpen,
		handleRegisterClose,
		open,
		openRegister,
		toggleRegisterOpen,
		setOpen,
		setOpenRegister,
	} = useLoginRegister();

	const isCourseSubscribed = userCourses.some((userCourse) => userCourse.id === course.id);

	const handleContinueLearning = useCallback(() => {
		navigate(getViewCourseWithSearchSlug(course.slug));
	}, [course]);

	const handleEnrollPageReroute = useCallback(() => {
		if (user) {
			navigate(getCoursePaymentSubUrl(course.slug));
		} else {
			setOpenRegister(true);
		}
	}, [course.slug, navigate, user]);

	function formatDate(datetimeString: any) {
		if (!datetimeString) return '';

		const date = new Date(datetimeString);
		const formattedDate = `${padZero(date.getDate())} ${getMonthName(date.getMonth())} '${date
			.getFullYear()
			.toString()
			.slice(-2)}`;
		return formattedDate;
	}

	function padZero(num: any) {
		return num < 10 ? '0' + num : num;
	}

	function formatTime(datetimeString: any) {
		if (!datetimeString) return '';

		const date = new Date(datetimeString);
		let hours = date.getUTCHours();
		const minutes = padZero(date.getUTCMinutes());
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12;
		return `${hours}:${minutes} ${ampm}`;
	}

	function getMonthName(month: any) {
		const monthNames = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		return monthNames[month];
	}
	return (
		// <div>
		// <div className="offset-1 card-section w-auto">
		<div
			className="card coursedetail-card footer-stop coursedetail-sticky-card z-3 d-none d-lg-block"
			style={{ position: 'fixed', top: '150px' }}>
			{course?.previewVideo && (
				<video className="card-img-top pop-over-video" controls controlsList="nodownload">
					<source src={course.previewVideo} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			)}
			<div className="card-body detail-inner-desc-layout">
				{course?.courseSettings?.dateVisible && course?.startDatetime && course?.endDatetime && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/cdate.png')}
								alt="Date"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">
								{formatDate(course.startDatetime)} - {formatDate(course.endDatetime)}
							</span>
						</div>
					</div>
				)}
				{course?.courseSettings?.timeVisible && course?.startDatetime && course?.endDatetime && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/ctime.png')}
								alt="Time"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">
								{formatTime(course.startDatetime)} - {formatTime(course.endDatetime)}
							</span>
						</div>
					</div>
				)}
				{course?.courseSettings?.languageVisible && course?.courseLanguages?.[0]?.name && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/clanguage.png')}
								alt="Language"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">
								{course.courseLanguages.map((language, index) => (
									<span key={index}>
										{language.name}
										{index < course.courseLanguages.length - 1 && ', '}
									</span>
								))}
							</span>
						</div>
					</div>
				)}
				{course?.courseSettings?.levelVisible && course?.courseLevel?.name && (
					<div className="d-flex align-items-center card-inner-layout">
						<div>
							<img
								src={toAbsoluteUrl('/media/detail-hero-section/clevel.png')}
								alt="Level"
								className="between-space"
							/>
						</div>
						<div>
							<span className="card-sticky-content g_reg">{course.courseLevel.name}</span>
						</div>
					</div>
				)}
				<div className="d-flex align-items-center card-inner-layout">
					<div>
						<img
							src={toAbsoluteUrl(
								'/media/detail-hero-section/' +
									(course?.courseMode?.name === 'Online' ? 'zoom' : 'clocation') +
									'.png',
							)}
							alt="Location"
							className="between-space"
						/>
					</div>
					<div>
						<span className="card-sticky-content g_reg">
							{course?.courseMode?.name === 'Online' ? 'Zoom' : course?.address}
						</span>
					</div>
				</div>
				<div className="card-border-bottom-style"></div>
				{!isCourseSubscribed && (
					<div className="d-flex pricing-layout flex-wrap">
						<div className="card-detail-sub-discount-price g_semi">
							<span>
								{RUPEE_SYMBOL}
								{course.sellPrice}/-
							</span>
						</div>
						<div className="card-detail-sub-actual-price g_reg">
							<del>
								<span>
									{RUPEE_SYMBOL}
									{course.amount}/-
								</span>
							</del>
						</div>
						<div className="card-detail-sub-actual-price g_reg">
							<span>
								({(((course?.amount - course?.sellPrice) / course?.amount) * 100).toFixed(0)}% off)
							</span>
						</div>
					</div>
				)}
				<div>
					<button
						className="button card-enroll-btn card-enroll-btn-hover g_mid w-100"
						onClick={isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute}>
						{isCourseSubscribed ? 'Keep Learning' : 'Enroll Now'}
					</button>
				</div>
			</div>
			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={toggleLoginOpen}
				/>
			)}
		</div>

		// </div>
		// </div>
	);
};

export default PopoverCourseDetailCard;
