import Loading from 'components/_v2/CommonComponents/Loading';
import { useState } from 'react';
import useIsSmallScreen from '../../../../hooks/useIsSmallScreen';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Image } from 'react-bootstrap';
import { Course } from 'components/_v2/Course/type';
import { mentorProfileNameRenderer } from 'components/_v2/CommonComponents/helper';

const LessonButtons = ({
	onNextClick,
	onPreviousClick,
	nextButtonText,
	previousButtonText,
	isFirstLessonOfFirstSection,
	isLastLessonOfLastSection,
	isDownloadCertificateButton,
	lessonName,
	course,
}: {
	nextButtonText: string;
	previousButtonText: string;
	onNextClick: (setLoading: (loading: boolean) => void) => void;
	onPreviousClick: () => void;
	isFirstLessonOfFirstSection: boolean;
	isLastLessonOfLastSection: boolean;
	isDownloadCertificateButton: boolean;
	lessonName: string;
	course: Course;
}) => {
	const [loading, setLoading] = useState(false);
	const isSmallScreen = useIsSmallScreen(576);
	if (isSmallScreen) {
		return (
			<div className="w-100">
				<div
					className="w-100 d-flex justify-content-between align-items-center gap-3 px-3"
					style={{ height: '56px', backgroundColor: '#FFCD001A' }}>
					{isFirstLessonOfFirstSection ? (
						<div></div>
					) : (
						<Image
							alt="prev"
							onClick={onPreviousClick}
							src={toAbsoluteUrl('/media/icons/previous-lecture.svg')}
						/>
					)}
					{lessonName}
					{isLastLessonOfLastSection ? (
						<div></div>
					) : (
						<Image
							alt="next"
							onClick={() => onNextClick(setLoading)}
							src={toAbsoluteUrl('/media/icons/next-lecture.svg')}
						/>
					)}
				</div>
				<div className="d-flex w-100 px-2 pt-2">
					{mentorProfileNameRenderer(course?.mentors, true)}
				</div>
			</div>
		);
	}

	return (
		<div className="dashedLine w-100 px-4">
			<div className="d-flex w-100 justify-content-between mb-4 mt-3">
				{isFirstLessonOfFirstSection ? (
					<div></div>
				) : (
					<button className="btn btn-previous g_mid" onClick={onPreviousClick}>
						{previousButtonText}
					</button>
				)}
				{isLastLessonOfLastSection ? (
					<div></div>
				) : (
					<button
						className="btn bg-color2 btn-next-lesson g_mid"
						disabled={loading}
						onClick={() => {
							if (isDownloadCertificateButton) {
								setLoading(true);
							}
							onNextClick(setLoading);
						}}>
						<div className="d-flex align-items-center justify-content-center gap-2">
							{loading && isDownloadCertificateButton && <Loading width="50px" height="25px" />}
							{nextButtonText}
						</div>
					</button>
				)}
			</div>
		</div>
	);
};

export default LessonButtons;
