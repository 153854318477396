import React, { useEffect, useState } from 'react';
import PaymentInformation from '../CommonComponents/PaymentInformation';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL, COURSE_URL } from 'pages/routing/routePages';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { VERIFY_ORDER_ADMIN_MUTATION } from 'components/Cart/core/requests';
import toast from 'react-hot-toast';
import { ORDER_STATUS } from './type';
import { useCourseContext } from '../../../context/CourseContext';
import useCourses from 'hooks/useCourses';

const GET_COURSE_SPECIFIC_NOTIFICATIONS = gql`
	subscription GetNotifications($topic: String!) {
		getNotifications(topic: $topic) {
			message
			title
		}
	}
`;

const PaymentStatus = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { courses, hasValueSet, setUserCourses } = useCourseContext();
	const { getCourses, getUserCourses } = useCourses();
	const queryParams = new URLSearchParams(location.search);
	const razorpay_payment_link_id = queryParams.get('razorpay_payment_link_id');
	const courseId = queryParams.get('courseId');
	const [isSubscribed, setIsSubscribed] = useState(false);
	const [courseSlug, setCourseSlug] = useState<string | null>(null);
	const [courseName, setCourseName] = useState<string | null>(null);
	const [hasCalledMutation, setHasCalledMutation] = useState(false);

	if (!razorpay_payment_link_id) {
		navigate(BASE_PAGE_URL);
	}

	const [status, setStatus] = useState<ORDER_STATUS>(ORDER_STATUS.PENDING);

	const getCourseDetails = (courseId: string) => courses.find((course) => course.id === courseId);

	const [verifyOrder] = useMutation(VERIFY_ORDER_ADMIN_MUTATION, {
		variables: {
			verifyOrderInputData: {
				transactionId: razorpay_payment_link_id,
			},
		},
		onError: async (err: any) => {
			if (err.message === 'Student has already enrolled for these courses!') {
				toast.success('Payment verified successfully!');
				setStatus(ORDER_STATUS.PAID);
				if (courseId && courses.length) {
					const courseDetails = getCourseDetails(courseId);
					setCourseSlug(courseDetails.slug);
					setCourseName(courseDetails.name);
				}
			} else {
				toast.error(err.message);
				navigate(COURSE_URL);
			}
		},
		onCompleted: async (data: any) => {
			if (courseId && courses.length) {
				const courseDetails = getCourseDetails(courseId);
				setIsSubscribed(true);
				toast.success('Payment verified successfully!');
				setStatus(data.verifyOrderAdmin.status);
				setCourseSlug(courseDetails.slug);
				setCourseName(courseDetails.name);
				setUserCourses([]);
				await getUserCourses();
			}
		},
	});

	useEffect(() => {
		const getPublicCourses = async () => {
			if (!hasValueSet) {
				await getCourses();
			}
		};

		const fetchOrder = async () => {
			await verifyOrder({
				variables: {
					verifyOrderInputData: {
						transactionId: razorpay_payment_link_id,
					},
				},
			});
		};

		getPublicCourses();

		if (!hasCalledMutation) {
			fetchOrder();
			setHasCalledMutation(true);
		}
	}, [hasValueSet, hasCalledMutation]);

	useSubscription(GET_COURSE_SPECIFIC_NOTIFICATIONS, {
		skip: !isSubscribed || !courseSlug,
		variables: { topic: courseSlug! },
		onSubscriptionData: ({ subscriptionData }) => {
			const notification = subscriptionData.data.getNotifications;
			toast.success(`Notification: ${notification.title} - ${notification.message}`);
		},
	});

	return (
		<div>
			{status === ORDER_STATUS.PAID && (
				<PaymentInformation
					image={toAbsoluteUrl('/media/payment-details/success.png')}
					alt="Payment_Success"
					title="Payment Success"
					description={
						<>
							Your purchase for the course &nbsp;
							<span className="highlight-text">“{courseName}” &nbsp;</span>
							was successful. You can find the course in my courses section.
						</>
					}
				/>
			)}
			{status === ORDER_STATUS.PENDING && (
				<PaymentInformation
					image={toAbsoluteUrl('/media/payment-details/pending.svg')}
					alt="Payment_Pending"
					title="Payment Is Pending"
					description={
						<>
							Your purchase for the course &nbsp;
							<span className="highlight-text">“{courseName}” &nbsp;</span>
							is still in review. You can find the course in my courses section once the payment is
							complete.
						</>
					}
				/>
			)}
			{status === ORDER_STATUS.FAILED && (
				<PaymentInformation
					image={toAbsoluteUrl('/media/payment-details/failed-payment.png')}
					alt="Payment_Failed"
					title="Payment Has Failed"
					description={
						<>
							Your purchase for the course &nbsp;
							<span className="highlight-text">“{courseName}” &nbsp;</span>
							has failed. Please try again. You can find the course in my courses section once the
							payment is complete.
						</>
					}
				/>
			)}
		</div>
	);
};

export default PaymentStatus;
