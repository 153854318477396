import { QuestionAnswerInputType, QuizQuestionObjectType } from './type';

export const calculateCorrectMarksFromQuestionAnswers = (
	questionAnswerArray: QuestionAnswerInputType[],
) => {
	return (questionAnswerArray || [])?.reduce((accumulator, currentValue) => {
		let isCorrectAnswer = true;
		currentValue.answer.forEach((givenAnswer: string) => {
			if (!currentValue.correctAnswer.includes(givenAnswer)) {
				isCorrectAnswer = false;
			}
		});
		if (isCorrectAnswer) {
			accumulator += parseInt(currentValue.mark);
		}
		return accumulator;
	}, 0);
};

export const calculateTotalMarksForQuiz = (quizQuestions: QuizQuestionObjectType[]) => {
	return (quizQuestions || [])?.reduce((accumulator, currentValue) => {
		accumulator += currentValue.mark;
		return accumulator;
	}, 0);
};
