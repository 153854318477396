import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import './ViewCourseHeader.css';
import { Course } from '../Course/type';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_PAGE_URL } from 'pages/routing/routePages';
import { useZoomMeeting } from 'context/ZoomMeetingContext';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { mentorProfileNameRenderer } from 'components/_v2/CommonComponents/helper';
import { useQuizContext } from 'context/QuizContext';
import CurrentQuestionProgress from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/CurrentQuestionProgress';
import TimerComponent from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/TimerComponent';
import useQuiz from 'hooks/useQuiz';

const ViewCourseHeader = ({ course }: { course: Course }) => {
	const navigate = useNavigate();
	const { isZoomVisited } = useZoomMeeting();
	const isTabletView = useIsSmallScreen(1200);
	const isMobileView = useIsSmallScreen(576);
	const handleHomePage = () => {
		navigate(BASE_PAGE_URL);
	};
	const circleRef = useRef(null);

	const { quiz, quizResult, currentSelectedLessonId, currentSelectedSectionId } = useQuizContext();
	const { handleQuizSubmit } = useQuiz();
	const showQuizSection = quiz.lessonId && !quizResult.isSubmit && quizResult.quizStartTime;
	const lessonData = course?.sections
		?.find((section) => section?.id === currentSelectedSectionId)
		?.lessons?.find((lesson) => lesson?.id === currentSelectedLessonId);

	const quizTimeEndedSubmit = () =>
		handleQuizSubmit({
			courseId: course.id,
			sectionId: currentSelectedSectionId,
			isSubmit: true,
			lessonId: currentSelectedLessonId,
			quizId: lessonData.quizId,
			questionAnswer: quiz.answersProvided,
		});

	useEffect(() => {
		if (circleRef.current) {
			const radius = circleRef.current.r.baseVal.value;
			const circumference = 2 * Math.PI * radius;
			const offset = circumference - ((course?.progressPercentage ?? 0) / 100) * circumference;
			circleRef.current.style.strokeDasharray = `${circumference} ${circumference}`;
			circleRef.current.style.strokeDashoffset = offset;
		}
	}, [course]);

	if (isZoomVisited) {
		return null;
	}

	return (
		<section className="header-wrapper bg-white">
			<div className="container">
				<div className="row mt-4">
					{isMobileView && showQuizSection ? (
						<div
							className="d-flex justify-content-between"
							style={{ height: 56, backgroundColor: '#FFCD000D' }}>
							<CurrentQuestionProgress
								questionNumber={quiz.currentQuestion}
								totalQuestions={lessonData?.quiz?.questions?.length}
							/>
							<TimerComponent
								remainingTime={quiz.remainingTime}
								onTimeFinish={quizTimeEndedSubmit}
							/>
						</div>
					) : (
						<>
							<div className="headerPadding col-8 col-sm-6 col-md-4 col-xl-8 d-flex align-items-center px-sm-4 pt-sm-0 px-3 pt-3">
								{!isTabletView && (
									<>
										<img
											src={toAbsoluteUrl(`/media/logo/hoGrowthBlack.svg`)}
											alt="HoGrowth_logo"
											onClick={handleHomePage}
										/>
										<div className="verticalLine" />
									</>
								)}
								<span className="g_mid ps-sm-2">{course?.name}</span>
							</div>
							<div className="headerPadding col-4 col-sm-6 col-md-8 col-xl-4 d-flex align-items-center justify-content-end">
								{mentorProfileNameRenderer(course?.mentors)}
								<div className="row">
									<div className="container">
										<div className="d-flex align-items-center ms-3">
											<div className="progress-circle me-2">
												<svg width="50" height="50">
													<circle cx="25" cy="25" r="20" className="background-circle"></circle>
													<circle
														ref={circleRef}
														cx="25"
														cy="25"
														r="20"
														className="progress-circle"></circle>
												</svg>
												<div className="progress-content">
													<img
														src={toAbsoluteUrl('/media/hgheader/trophy-fill.svg')}
														alt="Trophy"
														width="20"
														height="20"
													/>
												</div>
											</div>
											{!isMobileView && (
												<div className="pe-md-3 d-none d-md-block">
													<div className="course-progress-style">Course Progress</div>
													<div className="profile-mentro-name g_mid ">
														{(course?.progressPercentage ?? 0).toFixed(2)}% completed
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export default ViewCourseHeader;
