import React from "react";
import { useQuery, gql } from "@apollo/client";
import "./blog.css";
import { BLOGS_SECTION } from "../../../utils/consts";
import BlogCard from "./BlogCard";
import { toAbsoluteUrl } from "assets/helpers/AssetHelpers";
import Loading from "../CommonComponents/Loading";

// Define the GraphQL query
const GET_BLOGS = gql`
	query GetBlogs {
		getBlogs {
			id
			title
			summaryHtml
			description
			imageUrl
			handle
			tags
			blogUrl
		}
	}
`;

const Blog = () => {
	// Fetch the blog data
	const { loading, error, data } = useQuery(GET_BLOGS);

  if (loading) return <Loading width={200} />
	if (error) {
		console.error('Error fetching blogs:', error);
		return (
			<div className="course-card-section-spacing d-flex justify-content-center">
				There was some error in fetching blogs. Please try again is some time.
			</div>
		);
	}

	// Limit to the latest 3 blogs
	const latestBlogs = data.getBlogs.slice(0, 3);

	const handleViewAll = () => {
		window.open('https://blog.hogrowth.com/', '_blank');
	};

  return (
    <section className="blog-wrapper" id={BLOGS_SECTION}>
      <div className="container">
        <div className="row">

          <div className="col-lg-10 col-md-10 col-11 layout-set">

            <div className="text-center">
              <h2 className="lora blog-title">Market Insights & Trends</h2>
              <p className="blog-section-subtitle g_reg my-3">
                Stay ahead with the latest market insights and trends. Gain the
                knowledge to make informed decisions and excel in your trading
                journey.
              </p>
            </div>

            <div className="row d-flex justify-content-between course-card-section-spacing gy-4">
              {!latestBlogs.length ? (
                <div className='d-flex flex-column align-items-center gap-2'>
                  <img src={toAbsoluteUrl("/media/blog-images/no-blogs.svg")} alt="" className="no-blog-image" />
                  <span className="no-blog-text d-flex g_reg justify-content-center align-items-center text-center">
                   Currently there are no Blogs, we are actively working to bring you latest content. Stay tuned!
                  </span>
                </div>
              ) : (
                latestBlogs.map((blog) => (
                  <BlogCard
                    key={blog.id}
                    title={blog.title}
                    summaryHtml={blog.summaryHtml}
                    description={blog.description}
                    imageUrl={blog.imageUrl}
                    handle={blog.handle}
                    tags={blog.tags}
                    blogUrl={blog.blogUrl}
                  />
                )))}
            </div>
            {latestBlogs.length > 0 && (
              <div className="text-center">
                <button
                  className="button view-btn view-btn-hover g_mid"
                  onClick={handleViewAll}
                >
                  View All
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
