import axios, { AxiosResponse } from 'axios';
import { getUserData } from '../../../utils/getUserData';
import { gql } from '@apollo/client';
import { UserType } from 'types/globalTypes';
import { CourseApiGraphQL } from 'components/_v2/Course/type';
const { authToken: currentAuthToken } = getUserData() ?? { authToken: '' };
let authToken = currentAuthToken;
const API_URL = process.env.REACT_APP_API_URL;

// const AUTH_LOCAL_STORAGE_KEY = 'auth-react-v'
// const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
// let authToken = "";
// if (lsValue) {
//   const user = JSON.parse(lsValue);
//   authToken = user.access_token;
// }

export const LOGIN_USER = gql`
	mutation loginUser($email: String!, $password: String!) {
		loginUser(loginUserInput: { email: $email, password: $password }) {
			access_token
			user {
				id
				username
				firstName
				lastName
				email
				roleId
				userType
				resetPasswordKey
				createdAt
				updatedAt
				status
				isVerified
				isMobileNumberVerified
				signinType
				userProfile {
					ageRange
					country {
						countryCode
						countryName
						countryStdCode
						id
					}
					countryId
					createdAt
					createdById
					description
					dob
					gender
					goal {
						createdAt
						createdById
						id
						name
						status
						updatedAt
						updatedById
					}
					goalId
					id
					imageUrl
					instagramUrl
					linkedinUrl
					mobileNo
					mobileNoStdCode
					occupation {
						createdAt
						id
						isDefault
						name
						updatedAt
					}
					occupationId
					platformReferral {
						createdAt
						id
						isDefault
						name
						updatedAt
					}
					platformReferralId
					qualification
					stockMarketAndTradingUnderstandingLevel
					twitterUrl
					updatedAt
					updatedById
					userId
				}
				userAddress {
					address
					city
					state
					zipCode
				}
				role {
					name
					id
					slug
					permissions {
						id
						name
						slug
						updatedAt
					}
				}
			}
		}
	}
`;
export const STUDENTLOGINWITHSOCIALMEDIA = gql`
	mutation studentSignInWithSocialAccount(
		$email: String!
		$name: String!
		$signinType: UserSignInType!
	) {
		studentSignInWithSocialAccount(
			studentSignInWithSocialAccountInput: { email: $email, name: $name, signinType: $signinType }
		) {
			access_token
			user {
				id
				username
				firstName
				lastName
				email
				roleId
				resetPasswordKey
				createdAt
				updatedAt
				status
				isVerified
				signinType
			}
			message
		}
	}
`;
export async function login(email: string, password: string) {
	const data = await axios({
		url: API_URL,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			query: `
      mutation loginUser ( $email: String!, $password: String! ){
        loginUser(loginUserInput: { email: $email, password: $password }) {
          access_token
          user {
            id
            firstName
            lastName
            username
            email
            roleId
            createdAt
            updatedAt
            role {
              name
              id
              slug
              permissions {
                id
                name
                slug
              }
            }
          }
        }
      }
      `,
			variables: {
				email,
				password,
			},
		},
	});
	return data;
}

// export async function studentSignInWithSocialAccount(
//   email: string,
//   name: string,
//   signinType: string
// ) {
//   const data = await axios({
//     url: API_URL,
//     method: "post",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: {
//       query: `
//       mutation studentSignInWithSocialAccount($email: String!, $name: String!, $signinType: UserSignInType!){
//         studentSignInWithSocialAccount(studentSignInWithSocialAccountInput: { email: $email, name: $name, signinType: $signinType }) {
//           access_token
//           user {
//             id
//             username
//             firstName
//             lastName
//             email
//             roleId
//             resetPasswordKey
//             createdAt
//             updatedAt
//             status
//             isVerified
//             signinType
//           }
//           message
//         }
//       }
//       `,
//       variables: {
//         email,
//         name,
//         signinType,
//       },
//     },
//   });
//   return data;
// }
export async function getUserByToken(
	token: string,
): Promise<AxiosResponse<{ data?: { getMe: UserType }; errors?: any[] }>> {
	return axios({
		url: API_URL,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
		data: {
			query: `
      query {
        getMe {
          id
        username
        firstName
        lastName
        fullName
        email
        roleId
        userType
        resetPasswordKey
        createdAt
        updatedAt
        status
        isVerified
        isMobileNumberVerified
        signinType
        userProfile {
          ageRange
          country {
            countryCode
            countryName
            countryStdCode
            id
          }
          countryId
          createdAt
          createdById
          description
          dob
          gender
          goal {
            createdAt
            createdById
            id
            name
            status
            updatedAt
            updatedById
          }
          goalId
          id
          imageUrl
          instagramUrl
          linkedinUrl
          mobileNo
          mobileNoStdCode
          occupation {
            createdAt
            id
            isDefault
            name
            updatedAt
          }
          occupationId
          platformReferral {
            createdAt
            id
            isDefault
            name
            updatedAt
          }
          platformReferralId
          qualification
          stockMarketAndTradingUnderstandingLevel
          twitterUrl
          updatedAt
          updatedById
          userId      
        }
        userAddress {           
            address
            city
            state
            zipCode          
          }
        role {
          name
          id
          slug
          permissions {
            id
            name
            slug
            updatedAt
          }
        }
        }
      }
      `,
			variables: {},
		},
	});
}

interface StudentRegisterInput {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	mobileNoStdCode: string;
	mobileNo: string;
	confirmPassword: string;
}

// export const STUDENTREGISTER = gql`
// mutation studentRegister (
//   $input: StudentRegisterInput!
//   ){
//   studentRegister(
//       studentRegisterInput: $input
//     ) {
//       access_token
//       user {
//         id
//         username
//         firstName
//         lastName
//         email
//         roleId
//         resetPasswordKey
//         createdAt
//         updatedAt
//         status
//         signinType
//       }
//       message
//     }
//   }
// `

export const DOWNLOAD_CERTIFICATE = gql`
	mutation DownloadCertificate($course_id: String!) {
		downloadCertificate(course_id: $course_id)
	}
`;

export const GET_STUDENT_COURSES = (includeCurrentChapter: boolean) => gql`
  query GetStudentCourses {
    getStudentCourses(where: {}, paginate: {per_page: 0, page: 0}) {
      records {
        id
        userId
        courseId
        status
				allow_certificate_download
        startDatetime
        endDatetime
        orderId
        createdAt
        updatedAt
        deletedAt
        course {
      ${CourseApiGraphQL(includeCurrentChapter)}
        }
      user {
        email
        firstName
        id
        lastName
        userProfile{
          id
        }
      }
        completedDatetime
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }
`;

export const NEW_STUDENT_REGISTER = gql`
	mutation NewStudentRegister(
		$email: String!
		$password: String!
		$confirmPassword: String!
		$name: String!
	) {
		newStudentRegister(
			studentRegisterInput: {
				email: $email
				password: $password
				confirmPassword: $confirmPassword
				name: $name
			}
		) {
			access_token
			message
			is_success
			user {
				email
			}
		}
	}
`;

export const SUBMIT_QUIZ_PROGRESS = gql`
	mutation SubmitQuiz($input: UserQuizResultInputType!) {
		submitQuiz(userQuizResultInputType: $input) {
			endTime
			isSubmit
			isSuccess
			quizSubmitTime
		}
	}
`;

// export async function studentRegister(input: StudentRegisterInput) {
//   let data: any;
//   data = await axios({
//     url: API_URL,
//     method: "post",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: {
//       query: `
//       mutation studentRegister (
//         $input: StudentRegisterInput!
//         ){
//         studentRegister(
//             studentRegisterInput: $input
//           ) {
//             access_token
//             user {
//               id
//               username
//               firstName
//               lastName
//               email
//               roleId
//               resetPasswordKey
//               createdAt
//               updatedAt
//               status
//               signinType
//             }
//             message
//           }
//         }
//       `,
//       variables: {
//         input,
//       },
//     },
//   }).catch((e: any) => (data = e.response));
//   return data;
// }

export async function sendOtp(authTokens: string) {
	let data;
	data = await axios({
		url: API_URL,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${authTokens}`,
		},
		data: {
			query: `
            mutation {
              sendOtp
            }
            `,
			variables: {},
		},
	}).catch((e: any) => (data = e.response));
	return data;
}
// export const VERIFYOTP = gql `
// mutation verifyOtp (
//   $otp: String!
//   ){
//     verifyOtp(
//       otp: $otp
//     )
//   }
// `

export const VERIFYOTP = gql`
	mutation VerifyEmailOtp($otp: String!) {
		newStudentverification(studentRegisterVerificationInput: { otp: $otp }) {
			access_token
			message
			is_success
			user {
				id
				username
				firstName
				lastName
				email
				isVerified
				isMobileNumberVerified
			}
		}
	}
`;

// export async function verifyOtp(otp: string, authTokens: string) {
//   const API_URL = process.env.REACT_APP_API_URL;
//   let data: any;
//   data = await axios({
//     url: API_URL,
//     method: "post",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `bearer ${authTokens}`,
//     },
//     data: {
//       query: `
//       mutation verifyOtp (
//         $otp: String!
//         ){
//           verifyOtp(
//             otp: $otp
//           )
//         }
//       `,
//       variables: {
//         otp
//       },
//     },
//   }).catch((e: any) => (data = e.response));
//   return data;
// }
export async function getenrollFreeCourse(courseId: string) {
	const API_URL = process.env.REACT_APP_API_URL;
	let data;
	data = await axios({
		url: API_URL,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `bearer ${authToken}`,
		},
		data: {
			query: `
      mutation enrollFreeCourse($courseId: String!) {
        enrollFreeCourse(createFreeCourseEnrollmentInput: { courseId: $courseId })
      }`,
			variables: { courseId },
		},
	}).catch((e: any) => (data = e.response));
	return data;
}
export async function verifyEmail(emailVerificationInput: string) {
	const API_URL = process.env.REACT_APP_API_URL;
	let data;
	data = await axios({
		url: API_URL,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			query: `
      mutation verifyEmail($emailVerificationInput: String!) {
        verifyEmail(emailVerificationInput: { verification_token: $emailVerificationInput })
      }`,
			variables: { emailVerificationInput },
		},
	}).catch((e: any) => (data = e.response));
	return data;
}
export async function getCmsDetailBySlug(slug: string) {
	const API_URL = process.env.REACT_APP_API_URL;
	let data;
	data = await axios({
		url: API_URL,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
		},
		data: {
			query: `
       query GetCmsDetailBySlug($slug: String!) {
         getCmsDetailBySlug(slug:$slug) {
           id
           name
           seoTitle
           seoDescription
           keywords
           slug
           content
         }
       }`,
			variables: { slug },
		},
	}).catch((e: any) => (data = e.response));
	return data;
}
// export async function getCmsDetailBySlug(cmsdetail:string) {
//   const API_URL = process.env.REACT_APP_API_URL;
//   const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
//   let authToken = "";
//   if (lsValue) {
//     const user = JSON.parse(lsValue);
//     authToken = user.access_token;
//   }
//   let data;
//   data = await axios({
//     url: API_URL,
//     method: "post",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: {
//       query: `
//        GetCmsDetailBySlug($slug: String!) {
//         getCmsDetailBySlug(slug:$slug) {
//           id
//           name
//           seoTitle
//           seoDescription
//           keywords
//           slug
//           content
//         }
//       }`,
//       variables: {cmsdetail},
//     },
//   }).catch((e: any) => (data = e.response));
//   return data;
// }
