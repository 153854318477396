import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DetailHeroSection from './DetailHeroSection';
import CourseHighLights from 'components/_v2/CourseDetail/CourseHighlights/CourseHighLights';
import CourseOverView from 'components/_v2/CourseDetail/CourseOverview/CourseOverView';
import CourseContent from 'components/_v2/CourseDetail/CourseContent/CourseContent';
import InstructorProfile from 'components/_v2/CourseDetail/InstructorProfile/InstructorProfile';
import CourseTestimonials from 'components/_v2/CourseDetail/CourseTestimonials/CourseTestimonials';
import CourseFAQS from 'components/_v2/CourseDetail/CourseFAQS/CourseFAQS';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import { useNavigate, useParams } from 'react-router-dom';
import { Course } from 'components/_v2/Course/type';
import './DetailHeroSection.css';
import PopoverCourseDetailCard from './PopoverCourseDetailCard';
import useTestimonialsAndFaq from 'hooks/useTestimonialsAndFaq';
import { RUPEE_SYMBOL } from 'utils/consts';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import {
	COURSE_URL,
	getCoursePaymentSubUrl,
	getViewCourseWithSearchSlug,
} from 'pages/routing/routePages';
import { useUserContext } from 'context/UserContext';
import useLoginRegister from 'hooks/useLoginRegister';
import { Login } from 'pages/auth/Login';
import { Register } from 'pages/auth/Register';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const DetailPage = () => {
	const { hasValueSet, courses, userCourses } = useCourseContext();
	const isSmallScreen = useIsSmallScreen(992);
	const { user } = useUserContext();
	const [isVisible, setIsVisible] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [shouldTranslate, setShouldTranslate] = useState(true);

	const {
		toggleLoginOpen,
		handleRegisterClose,
		open,
		openRegister,
		toggleRegisterOpen,
		setOpen,
		setOpenRegister,
	} = useLoginRegister();

	const handleScroll = () => {
		const currentScrollY = window.scrollY;

		if (currentScrollY > lastScrollY) {
			// Scrolling down
			setIsVisible(true);
		} else {
			// Scrolling up
			setIsVisible(false);
		}

		setLastScrollY(currentScrollY);
	};

	const { getCourses } = useCourses();
	const navigate = useNavigate();
	const { courseSlug } = useParams();

	useEffect(() => {
		const loadScript = async () => {
			const script = document.createElement('script');
			script.src = '/assets/commonJs/global.js';
			script.async = true;
			document.body.appendChild(script);

			script.onload = () => {
				console.log('Global.js script loaded successfully.');
			};

			script.onerror = () => {
				console.error('Error loading Global.js script.');
			};
		};

		loadScript();

		return () => {
			const script = document.querySelector(`script[src="/assets/commonJs/global.js"]`);
			if (script) {
				document.body.removeChild(script);
			}
		};
	}, []);

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [lastScrollY]);
	useEffect(() => {
		const handleScroll = () => {
			const footer = document.querySelector('#footer');

			if (footer) {
				const footerRect = footer.getBoundingClientRect();
				const viewportHeight = window.innerHeight;

				if (footerRect.top < viewportHeight) {
					setShouldTranslate(false);
				} else {
					setShouldTranslate(true);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);
	const currentCourse = useMemo(() => {
		return courses.find((course: Course) => course.slug == courseSlug);
	}, [courses, courseSlug]);

	useEffect(() => {
		if (courses.length !== 0 && !currentCourse) {
			navigate(COURSE_URL);
		}
	}, [currentCourse, courseSlug]);

	const {
		loadingTestimonials,
		getTestimonials,
		errorTestimonials,
		testimonialsData,
		errorFaqs,
		faqsData,
		getFaqsList,
		loadingFaqs,
	} = useTestimonialsAndFaq(currentCourse?.id);

	useEffect(() => {
		if (currentCourse) {
			getTestimonials();
			getFaqsList();
		}
	}, [currentCourse]);

	const isCurrentDateBehindCourseStartDate = moment(currentCourse?.startDatetime).isAfter(moment());

	const isCourseSubscribed = userCourses.some((userCourse) => userCourse.id === currentCourse?.id);

	const handleContinueLearning = useCallback(() => {
		navigate(getViewCourseWithSearchSlug(currentCourse?.slug));
	}, [currentCourse]);

	const handleEnrollPageReroute = useCallback(() => {
		if (user) {
			navigate(getCoursePaymentSubUrl(currentCourse?.slug));
		} else {
			setOpenRegister(true);
		}
	}, [currentCourse?.slug, navigate, user]);

	if (!currentCourse) {
		return <p>Loading...</p>;
	}

	return (
		<>
			<div>
				<PopoverCourseDetailCard course={currentCourse} />
				<DetailHeroSection course={currentCourse} />
				<CourseHighLights highlights={currentCourse?.highlights || []} />
				<CourseOverView
					description={currentCourse?.description || ''}
					preRequisites={currentCourse?.requirements || ''}
					objectives={currentCourse?.objectives || []}
					skills={currentCourse?.courseSkills || []}
				/>
				<CourseContent sections={currentCourse?.sections} />
				<InstructorProfile instructors={currentCourse?.mentors} />
				{/*<CourseCertificate courseName={currentCourse?.name} />*/}
				{loadingTestimonials || errorTestimonials || testimonialsData.length === 0 ? (
					<></>
				) : (
					<CourseTestimonials testimonialsData={testimonialsData} />
				)}
				{loadingFaqs || errorFaqs || faqsData.length === 0 ? (
					<></>
				) : (
					<CourseFAQS faqsData={faqsData} />
				)}
			</div>
			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={toggleLoginOpen}
				/>
			)}
			{isSmallScreen && (
				<div
					className="w-100 p-lg-5 p-sm-3 p-2"
					style={{
						position: 'sticky',
						bottom: 0,
						backgroundColor: 'white',
						transition: 'transform 0.3s',
						...(shouldTranslate && {
							transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
						}),
						height: '10vh',
						display: 'flex',
						alignItems: 'center',
					}}>
					<div className="d-flex h-100 w-100">
						<div className="ms-3 w-50 d-flex flex-column">
							<span className="g_semi detail-discount-price">
								{RUPEE_SYMBOL}
								{currentCourse?.sellPrice}/-
							</span>
							<span className="g_reg detail-actual-price mt-1">
								<del>
									{RUPEE_SYMBOL}
									<span className="color17">{currentCourse?.amount}/-</span>
								</del>
								&nbsp; (
								{(
									((currentCourse?.amount - currentCourse?.sellPrice) / currentCourse?.amount) *
									100
								).toFixed(0)}
								% off)
							</span>
						</div>

						<div
							style={{ color: '#B32D0F' }}
							className="w-50 d-flex align-items-center justify-content-center h-100">
							<div className="d-flex flex-column w-100 gap-1">
								{isCurrentDateBehindCourseStartDate && !isCourseSubscribed && (
									<div className="d-flex justify-content-center align-items-center gap-1">
										<Image src={toAbsoluteUrl('/media/course-detail-images/timer-left.svg')} />
										{moment(currentCourse?.startDatetime).diff(moment(), 'days')} &nbsp; Days Left
										to Enroll
									</div>
								)}
								<button
									onClick={isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute}
									className="button bg-color2 signup-btn-hover g_mid px-1 py-2"
									style={{ borderRadius: '12px' }}>
									{isCourseSubscribed ? 'Keep Learning' : 'Enroll Now'}
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DetailPage;
