import { useNavigate } from "react-router-dom";
import {
  GETMYPROFILE,
  GET_COUNTRIES_WITH_CODE,
  UPDATE_STUDENT_PROFILE_EMAIL_MUTATION,
  UPDATE_STUDENT_PROFILE_MUTATION,
} from "../core/requests";
import Sidebar from "./Sidebar";
import { useQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../pages/auth/core/Auth";
import * as authHelper from "../../../pages/auth/core/AuthHelpers";
import * as yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
enum Gender {
  MALE,
  FEMALE,
  OTHER,
}
const ProfileSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  mobileNo: yup
    .string()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Mobile Number must be exactly 10 digits"),
  dob: yup.date().required("Date of Birth is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  pincode: yup
    .string()
    .matches(/^[1-9][0-9]{5}$/, "Invalid Pin Code")
    .required("PinCode is required"),
  // countryId: yup
  //     .string()
  //     .required("Please select a country"),
  // imageUrl: yup.mixed().test('required', 'Image is required', (value) => {
  //     return value !== null && value !== undefined;
  // }),
});
interface FormData {
  firstName: string;
  lastName: string;
  gender: number;
  mobileNo?: string;
  countryId?: string;
  city: string;
  state: string;
  pincode: string;
  description: string;
  dob: string;
  imageUrl: string;
  newImage?: any;
  address?: string;
  email?: string;
}

const PersonalDetailsEdit = () => {
  const [selectedGender, setSelectedGender] = useState<Gender | null>(null);
  const [commitEditProfile] = useMutation(UPDATE_STUDENT_PROFILE_MUTATION);
  const [commitEditProfileemail] = useMutation(
    UPDATE_STUDENT_PROFILE_EMAIL_MUTATION,
  );
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [learning, setLearning] = useState(false);
  const [order, setOrder] = useState(false);
  const [bookmark, setBookmark] = useState(false);
  const [, setProfile] = useState(false);
  const { data: profile, refetch } = useQuery(GETMYPROFILE);
  const userDob = profile?.getMyProfile?.userProfile[0]?.dob || "";
  const profileGender = profile?.getMyProfile?.userProfile[0]?.gender;
  const imageUrl: any = profile?.getMyProfile?.userProfile[0]?.imageUrl ?? "";
  const [resultImages, setResultImages] = useState<any>({});
  const [selectedImages, setSelectedImages] = useState({});
  const initialFormData: FormData = {
    firstName: profile?.getMyProfile?.firstName || "",
    lastName: profile?.getMyProfile?.lastName || "",
    gender: profileGender || "",
    mobileNo: profile?.getMyProfile?.userProfile[0]?.mobileNo || "",
    countryId: profile?.getMyProfile?.userProfile[0]?.countryId || "",
    city:
      (profile &&
        profile?.getMyProfile &&
        profile?.getMyProfile?.userAddress &&
        profile?.getMyProfile?.userAddress[0] &&
        profile?.getMyProfile?.userAddress[0]?.city) ||
      "",
    state:
      (profile &&
        profile?.getMyProfile &&
        profile?.getMyProfile?.userAddress &&
        profile?.getMyProfile?.userAddress[0] &&
        profile?.getMyProfile?.userAddress[0]?.state) ||
      "",
    pincode:
      profile &&
      profile?.getMyProfile &&
      profile?.getMyProfile?.userAddress &&
      profile?.getMyProfile?.userAddress[0] &&
      profile?.getMyProfile?.userAddress[0]?.zipCode,
    description: "hello url",
    dob: moment(userDob).format("YYYY-MM-DD") || "",
    imageUrl: imageUrl,
    address:
      (profile &&
        profile?.getMyProfile &&
        profile?.getMyProfile?.userAddress &&
        profile?.getMyProfile?.userAddress[0] &&
        profile?.getMyProfile?.userAddress[0]?.address) ||
      "",
    email: profile?.getMyProfile?.email,
    newImage: {},
  };
  const { data: countrydata } = useQuery(GET_COUNTRIES_WITH_CODE);
  const countryRecord = countrydata?.getCountriesWithCode ?? [];
  const countryOptions = countryRecord.map((country: any) => ({
    value: country.id,
    label: country.countryName,
  }));
  const countryId = profile?.getMyProfile?.userProfile[0]?.countryId;
  const defaultCountryId = "India";
  const defaultCountryOption = countryOptions.find(
    (country: any) => country.label === defaultCountryId,
  );
  useEffect(() => {
    if (profileGender === "MALE") {
      setSelectedGender(Gender.MALE);
    } else if (profileGender === "FEMALE") {
      setSelectedGender(Gender.FEMALE);
    } else {
      setSelectedGender(Gender.OTHER);
    }
  }, [profileGender]);
  const goback = () => {
    navigate("/my-profile");
  };

  const handleLearning = () => {
    setLearning(true);
    setProfile(false);
    setOrder(false);
    setBookmark(false);
  };
  const { refetch: profilerefetch } = useQuery(GETMYPROFILE, {
    onCompleted: () => {
      profilerefetch();
    },
  });
  const handleProfile = () => {
    navigate(`/my-profile`);
  };
  const handleorder = () => {
    setOrder(true);
    setProfile(false);
    setLearning(false);
    setBookmark(false);
  };
  const handleBookmark = () => {
    setBookmark(true);
    setProfile(false);
    setLearning(false);
    setOrder(false);
  };
  const handleLogout = () => {
    logout();
    authHelper.removeAuth();
    navigate("/");
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const resultString = event.target?.result as string | undefined;
        if (resultString) {
          const img = new Image();
          img.onload = () => {
            resolve(resultString);
          };
          img.src = resultString;
        }
      };
    });
  };

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: ProfileSchema,
    onSubmit: async (values) => {
      const genderEnum =
        values.gender === Gender.MALE
          ? "MALE"
          : values.gender === Gender.FEMALE
          ? "FEMALE"
          : "OTHER";
      const shouldIncludeMobileNo =
        values.mobileNo !== profile?.getMyProfile?.userProfile[0]?.mobileNo;
      const shouldIncludeemail = values.email !== profile?.getMyProfile?.email;
      const updateStudentProfileInput = {
        ...values,
        gender: genderEnum,
      };
      if (shouldIncludeMobileNo) {
        updateStudentProfileInput.mobileNo = values.mobileNo;
      } else {
        delete updateStudentProfileInput.mobileNo;
      }
      if (selectedImages && Object.values(selectedImages).length > 0) {
        updateStudentProfileInput.newImage = selectedImages;
      } else {
        delete updateStudentProfileInput.newImage;
      }
      if (
        shouldIncludeemail &&
        profile?.getMyProfile?.signinType !== "GOOGLE"
      ) {
        commitEditProfileemail({
          variables: {
            updateStudentEmailInput: {
              email: values.email,
            },
          },
          onCompleted: () => {
            toast.success("Successfully updated Profile email", {
              style: {
                padding: "16px",
              },
            });
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      } else {
        delete updateStudentProfileInput.email;
      }

      commitEditProfile({
        variables: {
          updateStudentProfileInput,
        },
        onCompleted: () => {
          toast.success("Successfully updated Profile", {
            style: {
              padding: "16px",
            },
          });
          refetch();
          setResultImages({});
          setSelectedImages({});
          navigate(`/my-profile`);
        },
        onError: (err: any) => {
          toast.error(err.message);
        },
      });
    },
  });
  useEffect(() => {
    if (profileGender === "MALE") {
      formik.setFieldValue("gender", Gender.MALE);
    } else if (profileGender === "FEMALE") {
      formik.setFieldValue("gender", Gender.FEMALE);
    } else {
      formik.setFieldValue("gender", Gender.OTHER);
    }
  }, [profileGender]);
  useEffect(() => {
    if (profile && profile?.getMyProfile) {
      formik.setFieldValue("firstName", profile.getMyProfile.firstName);
      formik.setFieldValue("lastName", profile.getMyProfile.lastName);
      formik.setFieldValue(
        "mobileNo",
        profile?.getMyProfile?.userProfile[0]?.mobileNo,
      );
      formik.setFieldValue("dob", moment(userDob).format("YYYY-MM-DD"));
      formik.setFieldValue(
        "address",
        profile &&
          profile?.getMyProfile &&
          profile?.getMyProfile?.userAddress &&
          profile?.getMyProfile?.userAddress[0] &&
          profile?.getMyProfile?.userAddress[0]?.address,
      );
      formik.setFieldValue(
        "city",
        profile?.getMyProfile?.userAddress &&
          profile?.getMyProfile?.userAddress[0]?.city,
      );
      formik.setFieldValue("email", profile?.getMyProfile?.email);
      formik.setFieldValue(
        "state",
        profile?.getMyProfile?.userAddress &&
          profile?.getMyProfile?.userAddress[0]?.state,
      );
      formik.setFieldValue(
        "pincode",
        profile?.getMyProfile?.userAddress &&
          profile?.getMyProfile?.userAddress[0]?.zipCode,
      );
      formik.setFieldValue("imageUrl", imageUrl);
      formik.setFieldValue(
        "countryId",
        profile?.getMyProfile?.userProfile[0]?.countryId,
      );
    }
  }, [profile]);

  const onSelectFile = (event: any) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray: any = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const name = selectedFilesArray[i].name;
      getBase64(selectedFilesArray[i])
        .then((result) => {
          const imageObj: any = { name: name, content: result };
          setSelectedImages(imageObj);
          setResultImages({ content: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    event.target.value = "";
  };
  function deleteHandler(images: any) {
    setResultImages((resultImages: any) =>
      Object.values(resultImages).filter((item: any) => item.id !== images),
    );
    setSelectedImages((selectedImages: any) =>
      Object.values(selectedImages).filter((item: any) => item.id !== images),
    );
    setResultImages({});
    setSelectedImages({});
  }
  return (
    <>
      <section>
        <div className="container mb-5 d-lg-block d-md-block d-none">
          <div className="row" style={{ paddingTop: "5%" }}>
            <Sidebar
              profile={profile?.getMyProfile}
              learning={learning}
              order={order}
              bookmark={bookmark}
              handleBookmark={handleBookmark}
              handleorder={handleorder}
              handleLearning={handleLearning}
              handleLogout={handleLogout}
              handleProfile={handleProfile}
            />
            {learning || order || bookmark ? (
              ""
            ) : (
              <div className="col-lg-9">
                <div className="">
                  <h3 className="font-title fw-bold fs-4 mt-5 ">
                    Personal Information
                  </h3>
                </div>
                <div className="p-2">
                  <form onSubmit={formik.handleSubmit} className="mt-3 w-50">
                    <div className="row gx-2">
                      <div className="col-12">
                        {(resultImages &&
                          Object.values(resultImages).length > 0) ||
                        (imageUrl !== null && imageUrl !== "") ? (
                          <>
                            {resultImages &&
                            Object.values(resultImages).length > 0
                              ? Object.values(resultImages).map(
                                  (image: any) => (
                                    <>
                                      {/* <label htmlFor="exampleInputEmail1" className="font-subtitle form-label mt-4 text-muted">Upload Your Image</label> */}
                                      <div className="profile-box rounded-circle text-center">
                                        <div className="avatar-img h-100">
                                          <img
                                            src={image}
                                            alt=""
                                            className="img-fluid h-100"
                                          />
                                        </div>
                                        <div
                                          className="close-avatar-icon-box"
                                          onClick={() => deleteHandler(image)}
                                        >
                                          <i className="fa-solid fa-xmark"></i>
                                        </div>
                                      </div>
                                    </>
                                  ),
                                )
                              : imageUrl !== null &&
                                imageUrl !== "" && (
                                  <>
                                    {/* <label htmlFor="exampleInputEmail1" className="font-subtitle form-label mt-4 text-muted">Upload Your Image</label> */}
                                    <div className="profile-box rounded-circle text-center">
                                      <div className="avatar-img h-100">
                                        <img
                                          src={
                                            profile?.getMyProfile
                                              ?.userProfile[0]?.imageUrl !=
                                              null &&
                                            profile?.getMyProfile
                                              ?.userProfile[0]?.imageUrl !== ""
                                              ? profile?.getMyProfile
                                                  ?.userProfile[0]?.imageUrl
                                              : "../media/icons/upimage.jpg"
                                          }
                                          alt="Uploaded Image"
                                          className="img-fluid h-100"
                                        />
                                      </div>
                                      <div className="edit-avatar-icon-box">
                                        <input
                                          type="file"
                                          className="position-absolute form-control"
                                          id="upload"
                                          accept="image/*"
                                          style={{ opacity: 0 }}
                                          onChange={onSelectFile}
                                        />
                                        <i className="fa-solid fa-pen"></i>
                                      </div>
                                    </div>
                                  </>
                                )}
                          </>
                        ) : (
                          <>
                            <label
                              className="profile-box align-items-center justify-content-center d-flex border mt-lg-9 mt-3 btn bg-gray-100 btn-outline btn-outline-dashed btn-outline-default w-100 p-3 m-0 fw-bold fs-5 cursor-pointer"
                              data-tooltip-id="upload-image"
                              data-tooltip-content="Upload Image"
                            >
                              Upload Image
                              <input
                                id="upload"
                                type="file"
                                accept="image/png, image/jpeg, image/webp"
                                name="uploadImage"
                                className="form-control d-none"
                                onChange={onSelectFile}
                              />
                            </label>
                            {formik.touched.imageUrl &&
                              formik.errors.imageUrl && (
                                <div className="text-danger mt-1 fs-7">
                                  <i className="fa fa-exclamation-circle me-1"></i>
                                  <>{formik.errors.imageUrl}</>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                      <div className="col-lg-6 mb-3 ">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          First Name{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your FirstName"
                          {...formik.getFieldProps("firstName")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.firstName &&
                              formik.errors.firstName,
                          })}
                          type="text"
                          name="firstName"
                        />
                        {formik.touched.firstName &&
                          formik.errors.firstName && (
                            <div className="text-danger mt-1 fs-7">
                              <i className="fa fa-exclamation-circle me-1"></i>
                              <>{formik.errors.firstName}</>
                            </div>
                          )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          Last Name{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your lastName"
                          {...formik.getFieldProps("lastName")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.lastName && formik.errors.lastName,
                          })}
                          type="text"
                          name="lastName"
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <div className="text-danger mt-1 fs-7">
                            <i className="fa fa-exclamation-circle me-1"></i>
                            <>{formik.errors.lastName}</>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          Phone Number{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your Mobile No"
                          {...formik.getFieldProps("mobileNo")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.mobileNo && formik.errors.mobileNo,
                          })}
                          type="text"
                          name="mobileNo"
                        />
                        {formik.touched.mobileNo && formik.errors.mobileNo && (
                          <div className="text-danger mt-1 fs-7">
                            <i className="fa fa-exclamation-circle me-1"></i>
                            <>{formik.errors.mobileNo}</>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          Email{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your Email"
                          {...formik.getFieldProps("email")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.email && formik.errors.email,
                          })}
                          type="email"
                          name="email"
                          readOnly={
                            profile?.getMyProfile?.signinType === "GOOGLE"
                          }
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger mt-1 fs-7">
                            <i className="fa fa-exclamation-circle me-1"></i>
                            <>{formik.errors.email}</>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <h3 className="font-title fw-bold mt-5 ">
                        Other Information
                      </h3>
                      <div className="mt-3 w-100 ">
                        <div className="row gy-3">
                          <div className="col-lg-12">
                            <label
                              htmlFor="inputState"
                              className="font-subtitle m-1 mx-1 text-muted"
                            >
                              Gender{" "}
                              <span
                                className="required"
                                style={{
                                  color: "red",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className="d-flex">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="flexRadioDefault"
                                  value={Gender.MALE}
                                  checked={formik.values.gender === Gender.MALE}
                                  onChange={() =>
                                    formik.handleChange({
                                      target: {
                                        name: "gender",
                                        value: Gender.MALE,
                                      },
                                    })
                                  }
                                />
                                <label
                                  className="font-subtitle form-check-label "
                                  htmlFor="flexRadioDefault1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check mx-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="flexRadioDefault1"
                                  value={Gender.FEMALE}
                                  checked={
                                    formik.values.gender === Gender.FEMALE
                                  }
                                  onChange={() =>
                                    formik.handleChange({
                                      target: {
                                        name: "gender",
                                        value: Gender.FEMALE,
                                      },
                                    })
                                  }
                                />
                                <label
                                  className="font-subtitle form-check-label "
                                  htmlFor="flexRadioDefault2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check mx-2">
                                {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" /> */}
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="flexRadioDefault2"
                                  value={Gender.OTHER}
                                  checked={
                                    formik.values.gender === Gender.OTHER
                                  }
                                  onChange={() =>
                                    formik.handleChange({
                                      target: {
                                        name: "gender",
                                        value: Gender.OTHER,
                                      },
                                    })
                                  }
                                />
                                <label
                                  className="font-subtitle form-check-label "
                                  htmlFor="flexRadioDefault2"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 ">
                            <label
                              htmlFor="date"
                              className="font-subtitle form-label m-1 text-muted"
                            >
                              Date of Birth{" "}
                              <span className="required text-danger">*</span>
                            </label>
                            <input
                              placeholder="Enter Your Dob"
                              {...formik.getFieldProps("dob")}
                              className={clsx("form-control bg-transparent", {
                                "is-invalid":
                                  formik.touched.dob && formik.errors.dob,
                              })}
                              type="date"
                              name="dob"
                            />
                            {formik.touched.dob && formik.errors.dob && (
                              <div className="text-danger mt-1 fs-7">
                                <i className="fa fa-exclamation-circle me-1"></i>
                                <>{formik.errors.dob}</>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="font-subtitle form-label m-1 text-muted"
                            >
                              Address{" "}
                              <span className="required text-danger">*</span>
                            </label>
                            <input
                              placeholder="Enter Your Address"
                              {...formik.getFieldProps("address")}
                              className={clsx("form-control bg-transparent", {
                                "is-invalid":
                                  formik.touched.address &&
                                  formik.errors.address,
                              })}
                              type="textarea"
                              name="address"
                            />
                            {formik.touched.address &&
                              formik.errors.address && (
                                <div className="text-danger mt-1 fs-7">
                                  <i className="fa fa-exclamation-circle me-1"></i>
                                  <>{formik.errors.address}</>
                                </div>
                              )}
                          </div>
                          <div className="col-lg-12">
                            <div className="row gx-3">
                              <div className="col-lg-6 mb-3">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  City{" "}
                                  <span className="required text-danger">
                                    *
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Your city"
                                  {...formik.getFieldProps("city")}
                                  className={clsx(
                                    "form-control bg-transparent",
                                    {
                                      "is-invalid":
                                        formik.touched.city &&
                                        formik.errors.city,
                                    },
                                  )}
                                  type="textarea"
                                  name="city"
                                />
                                {formik.touched.city && formik.errors.city && (
                                  <div className="text-danger mt-1 fs-7">
                                    <i className="fa fa-exclamation-circle me-1"></i>
                                    <>{formik.errors.city}</>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 mb-3">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  State{" "}
                                  <span className="required text-danger">
                                    *
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Your state"
                                  {...formik.getFieldProps("state")}
                                  className={clsx(
                                    "form-control bg-transparent",
                                    {
                                      "is-invalid":
                                        formik.touched.state &&
                                        formik.errors.state,
                                    },
                                  )}
                                  type="textarea"
                                  name="state"
                                />
                                {formik.touched.state &&
                                  formik.errors.state && (
                                    <div className="text-danger mt-1 fs-7">
                                      <i className="fa fa-exclamation-circle me-1"></i>
                                      <>{formik.errors.state}</>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="row gx-3">
                              <div className="col-lg-6">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  Country
                                </label>
                                <Select
                                  id="country"
                                  className={clsx("bg-transparent", {
                                    "is-invalid":
                                      formik.touched.countryId &&
                                      formik.errors.countryId,
                                  })}
                                  name="countryId"
                                  options={[defaultCountryOption]}
                                  defaultValue={defaultCountryOption}
                                  onChange={(selectedOption) =>
                                    formik.handleChange({
                                      target: {
                                        name: "countryId",
                                        value: selectedOption?.value,
                                      },
                                    })
                                  }
                                  isSearchable={false}
                                />
                                {/* {formik.touched.countryId && formik.errors.countryId && (
                                                            <div className="text-danger mt-1 fs-7">
                                                                <i className="fa fa-exclamation-circle me-1"></i>
                                                                <>{formik.errors.countryId}</>
                                                            </div>
                                                        )} */}
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  Pin Code{" "}
                                  <span className="required text-danger">
                                    *
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Your pincode"
                                  {...formik.getFieldProps("pincode")}
                                  className={clsx(
                                    "form-control bg-transparent",
                                    {
                                      "is-invalid":
                                        formik.touched.pincode &&
                                        formik.errors.pincode,
                                    },
                                  )}
                                  type="textarea"
                                  name="pincode"
                                />
                                {formik.touched.pincode &&
                                  formik.errors.pincode && (
                                    <div className="text-danger mt-1 fs-7">
                                      <i className="fa fa-exclamation-circle me-1"></i>
                                      <>{formik.errors.pincode}</>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="font-subtitle btn btn-primary mt-4 me-3"
                        >
                          Save
                        </button>
                        <button
                          className="font-subtitle btn btn-primary mt-4 "
                          onClick={() => goback()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section>
        <div className="container mb-5 d-lg-none d-md-none d-block">
          <div className="row" style={{ paddingTop: "5%" }}>
            <Sidebar
              profile={profile?.getMyProfile}
              learning={learning}
              order={order}
              bookmark={bookmark}
              handleBookmark={handleBookmark}
              handleorder={handleorder}
              handleLearning={handleLearning}
              handleLogout={handleLogout}
              handleProfile={handleProfile}
            />
            {learning || order || bookmark ? (
              ""
            ) : (
              <div className="col-lg-9">
                <div className="">
                  <h3 className="font-title fw-bold fs-4 mt-5 text-center">
                    Personal Information
                  </h3>
                </div>
                <div className="p-2">
                  <form onSubmit={formik.handleSubmit} className="mt-3 w-50">
                    <div className="row gx-2">
                      <div className="col-12">
                        {(resultImages &&
                          Object.values(resultImages).length > 0) ||
                        (imageUrl !== null && imageUrl !== "") ? (
                          <>
                            {resultImages &&
                            Object.values(resultImages).length > 0
                              ? Object.values(resultImages).map(
                                  (image: any) => (
                                    <>
                                      {/* <label htmlFor="exampleInputEmail1" className="font-subtitle form-label mt-4 text-muted">Upload Your Image</label> */}
                                      <div className="profile-box rounded-circle text-center">
                                        <div className="avatar-img h-100">
                                          <img
                                            src={image}
                                            alt=""
                                            className="img-fluid h-100"
                                          />
                                        </div>
                                        <div
                                          className="close-avatar-icon-box"
                                          onClick={() => deleteHandler(image)}
                                        >
                                          <i className="fa-solid fa-xmark"></i>
                                        </div>
                                      </div>
                                    </>
                                  ),
                                )
                              : imageUrl !== null &&
                                imageUrl !== "" && (
                                  <>
                                    {/* <label htmlFor="exampleInputEmail1" className="font-subtitle form-label mt-4 text-muted">Upload Your Image</label> */}
                                    <div className="profile-box rounded-circle text-center">
                                      <div className="avatar-img h-100">
                                        <img
                                          src={
                                            profile?.getMyProfile
                                              ?.userProfile[0]?.imageUrl !=
                                              null &&
                                            profile?.getMyProfile
                                              ?.userProfile[0]?.imageUrl !== ""
                                              ? profile?.getMyProfile
                                                  ?.userProfile[0]?.imageUrl
                                              : "../media/icons/upimage.jpg"
                                          }
                                          alt="Uploaded Image"
                                          className="img-fluid h-100"
                                        />
                                      </div>
                                      <div className="edit-avatar-icon-box">
                                        <input
                                          type="file"
                                          className="position-absolute form-control"
                                          id="upload"
                                          accept="image/*"
                                          style={{ opacity: 0 }}
                                          onChange={onSelectFile}
                                        />
                                        <i className="fa-solid fa-pen"></i>
                                      </div>
                                    </div>
                                  </>
                                )}
                          </>
                        ) : (
                          <>
                            <label
                              className="profile-box align-items-center justify-content-center d-flex border mt-lg-9 mt-3 btn bg-gray-100 btn-outline btn-outline-dashed btn-outline-default w-100 p-3 m-0 fw-bold fs-5 cursor-pointer"
                              data-tooltip-id="upload-image"
                              data-tooltip-content="Upload Image"
                            >
                              Upload Image
                              <input
                                id="upload"
                                type="file"
                                accept="image/png, image/jpeg, image/webp"
                                name="uploadImage"
                                className="form-control d-none"
                                onChange={onSelectFile}
                              />
                            </label>
                            {formik.touched.imageUrl &&
                              formik.errors.imageUrl && (
                                <div className="text-danger mt-1 fs-7">
                                  <i className="fa fa-exclamation-circle me-1"></i>
                                  <>{formik.errors.imageUrl}</>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                      <div className="col-lg-6 mb-3 ">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          First Name{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your FirstName"
                          {...formik.getFieldProps("firstName")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.firstName &&
                              formik.errors.firstName,
                          })}
                          type="text"
                          name="firstName"
                        />
                        {formik.touched.firstName &&
                          formik.errors.firstName && (
                            <div className="text-danger mt-1 fs-7">
                              <i className="fa fa-exclamation-circle me-1"></i>
                              <>{formik.errors.firstName}</>
                            </div>
                          )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          Last Name{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your lastName"
                          {...formik.getFieldProps("lastName")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.lastName && formik.errors.lastName,
                          })}
                          type="text"
                          name="lastName"
                        />
                        {formik.touched.lastName && formik.errors.lastName && (
                          <div className="text-danger mt-1 fs-7">
                            <i className="fa fa-exclamation-circle me-1"></i>
                            <>{formik.errors.lastName}</>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          Phone Number{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your lastName"
                          {...formik.getFieldProps("mobileNo")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.mobileNo && formik.errors.mobileNo,
                          })}
                          type="text"
                          name="mobileNo"
                        />
                        {formik.touched.mobileNo && formik.errors.mobileNo && (
                          <div className="text-danger mt-1 fs-7">
                            <i className="fa fa-exclamation-circle me-1"></i>
                            <>{formik.errors.mobileNo}</>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 mb-3">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="font-subtitle form-label mt-2 text-muted"
                        >
                          Email{" "}
                          <span
                            className="required"
                            style={{
                              color: "red",
                            }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          placeholder="Enter Your Email"
                          {...formik.getFieldProps("email")}
                          className={clsx("form-control bg-transparent", {
                            "is-invalid":
                              formik.touched.email && formik.errors.email,
                          })}
                          type="email"
                          name="email"
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="text-danger mt-1 fs-7">
                            <i className="fa fa-exclamation-circle me-1"></i>
                            <>{formik.errors.email}</>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="">
                        <h3 className="font-title fw-bold fs-4 mt-5 ">
                          Other Information
                        </h3>
                      </div>
                      <div className="mt-3 w-100 ">
                        <div className="row gy-3">
                          <div className="col-lg-12">
                            <label
                              htmlFor="inputState"
                              className="font-subtitle m-1 mx-1 text-muted"
                            >
                              Gender{" "}
                              <span
                                className="required"
                                style={{
                                  color: "red",
                                }}
                              >
                                *
                              </span>
                            </label>
                            <div className="d-flex">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault"
                                  checked={selectedGender === Gender.MALE}
                                  onChange={() =>
                                    setSelectedGender(Gender.MALE)
                                  }
                                />
                                <label
                                  className="font-subtitle form-check-label "
                                  htmlFor="flexRadioDefault1"
                                >
                                  Male
                                </label>
                              </div>
                              <div className="form-check mx-2">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="flexRadioDefault1"
                                  value={Gender.FEMALE}
                                  checked={
                                    formik.values.gender === Gender.FEMALE
                                  }
                                  onChange={() =>
                                    formik.handleChange({
                                      target: {
                                        name: "gender",
                                        value: Gender.FEMALE,
                                      },
                                    })
                                  }
                                />
                                <label
                                  className="font-subtitle form-check-label "
                                  htmlFor="flexRadioDefault2"
                                >
                                  Female
                                </label>
                              </div>
                              <div className="form-check mx-2">
                                {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" /> */}
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="gender"
                                  id="flexRadioDefault2"
                                  value={Gender.OTHER}
                                  checked={
                                    formik.values.gender === Gender.OTHER
                                  }
                                  onChange={() =>
                                    formik.handleChange({
                                      target: {
                                        name: "gender",
                                        value: Gender.OTHER,
                                      },
                                    })
                                  }
                                />
                                <label
                                  className="font-subtitle form-check-label "
                                  htmlFor="flexRadioDefault2"
                                >
                                  Other
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 ">
                            <label
                              htmlFor="date"
                              className="font-subtitle form-label m-1 text-muted"
                            >
                              Date of Birth{" "}
                              <span className="required text-danger">*</span>
                            </label>
                            <input
                              placeholder="Enter Your Dob"
                              {...formik.getFieldProps("dob")}
                              className={clsx("form-control bg-transparent", {
                                "is-invalid":
                                  formik.touched.dob && formik.errors.dob,
                              })}
                              type="date"
                              name="dob"
                            />
                            {formik.touched.dob && formik.errors.dob && (
                              <div className="text-danger mt-1 fs-7">
                                <i className="fa fa-exclamation-circle me-1"></i>
                                <>{formik.errors.dob}</>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="font-subtitle form-label m-1 text-muted"
                            >
                              Address{" "}
                              <span className="required text-danger">*</span>
                            </label>
                            <input
                              placeholder="Enter Your Address"
                              {...formik.getFieldProps("address")}
                              className={clsx("form-control bg-transparent", {
                                "is-invalid":
                                  formik.touched.address &&
                                  formik.errors.address,
                              })}
                              type="textarea"
                              name="address"
                            />
                            {formik.touched.address &&
                              formik.errors.address && (
                                <div className="text-danger mt-1 fs-7">
                                  <i className="fa fa-exclamation-circle me-1"></i>
                                  <>{formik.errors.address}</>
                                </div>
                              )}
                          </div>
                          <div className="col-lg-12">
                            <div className="row gx-3">
                              <div className="col-lg-6 mb-3">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  City{" "}
                                  <span className="required text-danger">
                                    *
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Your city"
                                  {...formik.getFieldProps("city")}
                                  className={clsx(
                                    "form-control bg-transparent",
                                    {
                                      "is-invalid":
                                        formik.touched.city &&
                                        formik.errors.city,
                                    },
                                  )}
                                  type="textarea"
                                  name="city"
                                />
                                {formik.touched.city && formik.errors.city && (
                                  <div className="text-danger mt-1 fs-7">
                                    <i className="fa fa-exclamation-circle me-1"></i>
                                    <>{formik.errors.city}</>
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6 mb-3">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  State{" "}
                                  <span className="required text-danger">
                                    *
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Your state"
                                  {...formik.getFieldProps("state")}
                                  className={clsx(
                                    "form-control bg-transparent",
                                    {
                                      "is-invalid":
                                        formik.touched.state &&
                                        formik.errors.state,
                                    },
                                  )}
                                  type="textarea"
                                  name="state"
                                />
                                {formik.touched.state &&
                                  formik.errors.state && (
                                    <div className="text-danger mt-1 fs-7">
                                      <i className="fa fa-exclamation-circle me-1"></i>
                                      <>{formik.errors.state}</>
                                    </div>
                                  )}
                              </div>
                            </div>
                            <div className="row gx-3">
                              <div className="col-lg-6">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  Country{" "}
                                  <span className="required text-danger">
                                    *
                                  </span>
                                </label>
                                <Select
                                  id="country"
                                  className={clsx("bg-transparent", {
                                    "is-invalid":
                                      formik.touched.countryId &&
                                      formik.errors.countryId,
                                  })}
                                  name="countryId"
                                  options={[defaultCountryOption]}
                                  defaultValue={defaultCountryOption}
                                  onChange={(selectedOption) =>
                                    formik.handleChange({
                                      target: {
                                        name: "countryId",
                                        value: selectedOption?.value,
                                      },
                                    })
                                  }
                                  // isSearchable
                                />
                                {/* {formik.touched.countryId && formik.errors.countryId && (
                                                            <div className="text-danger mt-1 fs-7">
                                                                <i className="fa fa-exclamation-circle me-1"></i>
                                                                <>{formik.errors.countryId}</>
                                                            </div>
                                                        )} */}
                              </div>
                              <div className="col-lg-6">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="font-subtitle form-label mt-2 text-muted"
                                >
                                  Pin Code{" "}
                                  <span className="required text-danger">
                                    *
                                  </span>
                                </label>
                                <input
                                  placeholder="Enter Your pincode"
                                  {...formik.getFieldProps("pincode")}
                                  className={clsx(
                                    "form-control bg-transparent",
                                    {
                                      "is-invalid":
                                        formik.touched.pincode &&
                                        formik.errors.pincode,
                                    },
                                  )}
                                  type="textarea"
                                  name="pincode"
                                />
                                {formik.touched.pincode &&
                                  formik.errors.pincode && (
                                    <div className="text-danger mt-1 fs-7">
                                      <i className="fa fa-exclamation-circle me-1"></i>
                                      <>{formik.errors.pincode}</>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          disabled={formik.isSubmitting || !formik.isValid}
                          className="font-subtitle btn btn-primary mt-4 me-3"
                        >
                          Save
                        </button>
                        <button
                          className="font-subtitle btn btn-primary mt-4 "
                          onClick={() => goback()}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default PersonalDetailsEdit;
