import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import './PaymentHistory.css';

import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { FormGroup, InputGroup } from 'reactstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useUserContext } from 'context/UserContext';
import Loading from '../CommonComponents/Loading';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const DOWNLOAD_INVOICE = gql`
	mutation DownloadInvoice($order_id: String!) {
		downloadInvoice(order_id: $order_id)
	}
`;

const GET_STUDENT_ORDER_HISTORY = gql`
	query PaymentHistory_Query(
		$where: StudentProfileResolver_GetStudentOrderHistory_FilterInputType
		$paginate: PaginatorArgs!
	) {
		getStudentOrderHistory(where: $where, paginate: $paginate) {
			records {
				id
				userId
				subTotal
				total
				tax
				gstNo
				status
				transactionId
				paymentGatewayId
				paymentGatewayMode
				transactionStatus
				transactionDateTime
				totalDiscount
				type
				createdAt
				deletedAt
				platform
				paymentLink
				invoiceStatus
				orderDetails {
					orderId
					price
					course {
						name
					}
				}
				address_id
				companyName
				registerAddress
				userAddress {
					userId
					fullname
				}
				user {
					id
					username
				}
			}
			pagination {
				totalRecords
				currentPage
			}
		}
	}
`;
const dateArray = [
	{ id: 'filterAll', label: 'All' },
	{ id: 'filterToday', label: 'Today' },
	{ id: 'filterLast7Days', label: 'Last 7 Days' },
	{ id: 'filterLast30Days', label: 'Last 30 Days' },
	{ id: 'filterThisMonth', label: 'This Month' },
	{ id: 'filterLastMonth', label: 'Last Month' },
	{ id: 'filterCustomRange', label: 'Custom Range' },
];

const statusArray = [
	{ id: 'radioAll', label: 'All', value: '', status: null },
	{
		id: 'radioSuccessful',
		label: 'Successful',
		value: 'SUCCESSFUL',
		status: 'COMPLETED',
	},
	{
		id: 'radioProcessing',
		label: 'Processing',
		value: 'PROCESSING',
		status: 'PENDING',
	},
	{
		id: 'radioFailed',
		label: 'Failed',
		value: 'FAILED',
		status: 'FAILED',
	},
];

const tableColumnHeaders = [
	'Order ID',
	'Date',
	'Course Name',
	'Price',
	'Payment Type',
	'Status',
	'Invoice',
];

const StatusDisplay = ({
	icon,
	label,
	className,
}: {
	icon: string;
	label: string;
	className: string;
}) => (
	<div className="d-flex align-items-center payment-inner-layout-set">
		<img
			src={toAbsoluteUrl(`/media/PaymentHistory/${icon}`)}
			style={{ width: '20px', height: '20px' }}
			alt={label}
		/>
		<div className={`ms-2 status-label ${className}`}>{label}</div>
	</div>
);

const PaymentPopup = ({
	status,
	transactionDateTime,
}: {
	status: string;
	transactionDateTime: string;
}) => {
	const formatDate = (date: string) => {
		const formattedDate = moment.utc(date).utcOffset('+05:30').format('Do MMMM YYYY');
		return formattedDate !== 'Invalid date' ? formattedDate : 'No Date Available';
	};

	const statusText =
		status === 'PAID'
			? `Your Payment Was Successful`
			: status === 'PENDING'
			? `Your Payment Is Under Process`
			: `Your Payment Has Failed`;

	const dateText =
		status === 'PAID'
			? `You have completed this payment on ${formatDate(transactionDateTime)}`
			: status === 'PENDING'
			? `Your payment is under process. Expect to hear back from us by ${formatDate(
					transactionDateTime,
			  )}`
			: `You have Failed this payment on ${formatDate(transactionDateTime)}`;

	const statusIcon =
		status === 'PAID' ? 'checkmark.svg' : status === 'PENDING' ? 'time.svg' : 'alert.svg';

	return (
		<div className="payment-popup">
			<div className="in-status-title g_mid">
				<img
					src={toAbsoluteUrl(`/media/PaymentHistory/${statusIcon}`)}
					alt={status}
					className="me-3"
				/>
				{statusText}
			</div>
			<div className="in-status-label">{dateText}</div>
		</div>
	);
};

const DownloadButton = ({ isDisabled, onClick }: { isDisabled: boolean; onClick: () => void }) => (
	<button
		className={`color3 g_reg rounded-1 bg-color15 d-flex btn-payment-space border-0 ${
			isDisabled ? 'disabled' : ''
		}`}
		disabled={isDisabled}
		onClick={onClick}>
		Download
		<img
			src={toAbsoluteUrl('/media/PaymentHistory/download.svg')}
			alt="download"
			className="ms-2"
		/>
	</button>
);

const InvoiceDownloadLink = ({
	invoiceData,
	paymentRecords,
	setInvoiceData,
	id,
}: {
	invoiceData: string;
	paymentRecords: any[];
	setInvoiceData: (data: null) => void;
	id: string;
}) => {
	const orderName =
		paymentRecords.find((record: { orderDetails: { orderId: string }[] }) => {
			return record.orderDetails?.[0]?.orderId === id;
		})?.orderDetails?.[0]?.course?.name ?? 'unknown';

	const orderDate = paymentRecords.find(
		(record: { orderDetails: { orderId: string }[] }) => record.orderDetails?.[0]?.orderId === id,
	)?.transactionDateTime
		? new Date(
				paymentRecords.find(
					(record: { orderDetails: { orderId: string }[] }) =>
						record.orderDetails?.[0]?.orderId === id,
				)?.transactionDateTime!,
		  ).toLocaleDateString()
		: 'unknown';

	return (
		<a
			href={`data: application / pdf; base64, ${invoiceData} `}
			download={`Invoice_${orderName}_${orderDate}.pdf`}
			style={{ display: 'none' }}
			ref={(ref) => {
				if (ref) {
					ref.click();
					URL.revokeObjectURL(ref.href);
					setInvoiceData(null);
				}
			}}>
			Downloading...
		</a>
	);
};

const PaymentHistory = () => {
	const { localStorageAuthToken } = useUserContext();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [paymentRecords, setPaymentRecords] = useState([]);
	const [showPicker, setShowPicker] = useState(false);
	const [dateRange, setDateRange] = useState([]);
	const [status, setStatus] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [selectedDateFilter, setSelectedDateFilter] = useState('filterAll');
	const [whereClause, setWhereClause] = useState({});

	const [hoveredRecord, setHoveredRecord] = useState<any>(null);
	const [invoiceData, setInvoiceData] = useState<string | null>(null);
	const [invoiceOrderId, setInvoiceOrderId] = useState<string | null>(null);
	const [apiSource, setApiSource] = useState<'paymentDetails' | 'orderHistory' | null>(null);
	const isMobileScreen = useIsSmallScreen(576);

	useEffect(() => {}, [selectedFilter]);
	// Query for student order history
	const {
		loading: loadingOrderHistory,
		error: errorOrderHistory,
		data: dataOrderHistory,
		refetch: refetchOrderHistory,
	} = useQuery(GET_STUDENT_ORDER_HISTORY, {
		variables: {
			where: whereClause,
			paginate: { page: currentPage - 1, per_page: 6 },
		},
		context: {
			headers: {
				authorization: localStorageAuthToken ? `Bearer ${localStorageAuthToken} ` : '',
			},
		},
		fetchPolicy: 'network-only',
		onError: (graphQLError) => {
			console.error('GraphQL error:', graphQLError);
		},
		onCompleted: (data) => {
			setApiSource('orderHistory');
			const totalRecords = data.getStudentOrderHistory.pagination.totalRecords;
			setPaymentRecords(data.getStudentOrderHistory.records);
			const perPage = 6;
			setTotalPages(Math.ceil(totalRecords / perPage));
		},
	});
	const [downloadInvoice] = useMutation(DOWNLOAD_INVOICE, {
		onError: (error) => {
			console.error('An error occurred while fetching the invoice data:', error);
			toast.error('An error occurred while fetching the invoice data.');
		},
		onCompleted: (data) => {
			if (data.downloadInvoice) {
				setInvoiceData(data.downloadInvoice);
				toast.success('Invoice downloaded successfully.');
				refetchOrderHistory(); // Use the refetch method for student order history
			} else {
				toast.error('Failed to fetch invoice data. Please try again later.');
			}
		},
	});

	const applyDateFilter = () => {
		let where = { ...whereClause };

		if (selectedDateFilter === 'filterAll') {
			where = {}; // Empty object for no filter
		} else {
			const today = new Date();
			const startOfDay = (date) => date.toISOString().split('T')[0] + 'T00:00:00.000Z';
			const endOfDay = (date) => date.toISOString().split('T')[0] + 'T23:59:59.999Z';

			switch (selectedDateFilter) {
				case 'filterToday':
					where = {
						...where,
						transaction_date_time: {
							between: [startOfDay(today), endOfDay(today)],
						},
					};
					break;
				case 'filterLast7Days':
					where = {
						...where,
						transaction_date_time: {
							between: [
								startOfDay(new Date(today.setDate(today.getDate() - 7))),
								endOfDay(new Date()),
							],
						},
					};
					break;
				case 'filterLast30Days':
					where = {
						...where,
						transaction_date_time: {
							between: [
								startOfDay(new Date(today.setDate(today.getDate() - 30))),
								endOfDay(new Date()),
							],
						},
					};
					break;
				case 'filterThisMonth':
					where = {
						...where,
						transaction_date_time: {
							between: [
								startOfDay(new Date(today.getFullYear(), today.getMonth(), 1)),
								endOfDay(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
							],
						},
					};
					break;
				case 'filterLastMonth':
					where = {
						...where,
						transaction_date_time: {
							between: [
								startOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1)),
								endOfDay(new Date(today.getFullYear(), today.getMonth(), 0)),
							],
						},
					};
					break;
				case 'filterCustomRange':
					where = {
						...where,
						transaction_date_time: {
							between: [startOfDay(new Date(dateRange[0])), endOfDay(new Date(dateRange[1]))],
						},
					};
					break;
				default:
					where = { ...where };
			}
		}

		setWhereClause(where);
		handlePageChange(1);
		refetchOrderHistory({
			whereClause,
			paginate: { page: 0, per_page: 6 },
		});
	};

	useEffect(() => {
		if (selectedDateFilter === 'filterAll') {
			applyDateFilter();
		}
	}, [selectedDateFilter]);

	// useEffect(() => {
	//   if (selectedFilter === "") {
	//     applyFilter();
	//   }
	// }, [selectedFilter]);
	const handleDownloadInvoice = (orderId: string) => {
		setInvoiceOrderId(orderId);
		downloadInvoice({ variables: { order_id: orderId } });
	};

	const handleMouseEnter = (record: any) => {
		setHoveredRecord(record);
	};

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
		refetchOrderHistory();
	};

	const handleMouseLeave = () => {
		setHoveredRecord(null);
	};

	const dateArrayFilterLabel = useMemo(() => {
		return dateArray.find((item) => item.id === selectedDateFilter)?.label;
	}, [selectedDateFilter]);

	const statusArrayFilterLabel = useMemo(() => {
		return statusArray.find((item) => item.status === selectedFilter)?.label;
	}, [selectedFilter]);

	if (loadingOrderHistory) return <Loading width={250} height="100vh" />;
	if (errorOrderHistory) {
		console.error('GraphQL error:', errorOrderHistory);
		return <p>Error: {errorOrderHistory?.message}</p>;
	}

	const handleCheckboxChange = () => {
		setShowPicker(!showPicker);
	};

	const handleStatusChange = (newStatus) => {
		setStatus(newStatus);
		applyFilter();
	};

	const handleRadioChange = (filterValue) => {
		setSelectedFilter(filterValue);
	};

	const handleDateRadioChange = (event) => {
		const filterDateValue = event.target.id;
		setSelectedDateFilter(filterDateValue);
		setShowPicker(filterDateValue === 'filterCustomRange');
	};
	// const handleDateClear = () => {
	//   setSelectedDateFilter("filterAll");
	//   applyDateFilter();
	// };
	const handleCancelClick = () => {
		applyFilter();
	};

	const applyFilter = () => {
		let where = { ...whereClause };
		if (selectedFilter !== null) {
			where = {
				...where,
				transaction_status: { eq: selectedFilter },
			};
		} else {
			where = {};
		}

		setWhereClause(where);
		handlePageChange(1);
		refetchOrderHistory({
			whereClause,
			paginate: { page: 0, per_page: 6 },
		});
	};

	return (
		<>
			<div className="d-flex justify-content-lg-end gap-2">
				<div className="dropdown-center dropdown-center-w-50 text-lg-end">
					<button
						className="btn sortby-dropdown dropdown-toggle dropdown_toggle w-100"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false">
						{dateArrayFilterLabel === 'All' ? 'Filter By Date' : dateArrayFilterLabel}
					</button>

					<ul className="dropdown-menu mt-2 px-3">
						<div className="d-flex justify-content-between align-content-center mt-2">
							<p className="sortby-title g_mid">Filter By Date</p>
							<img
								role="button"
								src={toAbsoluteUrl('/media/logo/cross.png')}
								alt="Close"
								className="img-fluid image-cross-style"
							/>
						</div>

						<div className="dropdown-border mb-3"></div>

						{dateArray.map((option) => (
							<div className="form-check mt-1" key={option.id}>
								<input
									className="form-check-input"
									type="radio"
									name="filterDate"
									id={option.id}
									checked={selectedDateFilter === option.id}
									onChange={handleDateRadioChange}
								/>
								<label
									className="form-check-label g_reg w-100"
									htmlFor={option.id}
									onClick={(e) => e.stopPropagation()}>
									{option.label}
								</label>
							</div>
						))}

						{selectedDateFilter === 'filterCustomRange' && showPicker && (
							<FormGroup>
								<InputGroup>
									<Flatpickr
										className="form-control d-block"
										placeholder="dd M, yyyy"
										options={{
											mode: 'range',
											dateFormat: 'Y-m-d',
										}}
										value={dateRange}
										onChange={(date) => setDateRange(date)}
									/>
								</InputGroup>
							</FormGroup>
						)}

						<div className="my-3">
							<button
								className="btn dp-cancel g_mid me-3"
								onClick={() => setSelectedDateFilter('filterAll')}
								// onClick={handleDateClear}
							>
								Clear
							</button>
							<button className="btn dp-apply g_mid" onClick={applyDateFilter}>
								Apply
							</button>
						</div>
					</ul>
				</div>
				<div className="dropdown-center dropdown-center-w-50 mx-lg-4 text-lg-end">
					<button
						className="btn sortby-dropdown dropdown-toggle dp_tg w-100"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false">
						{statusArrayFilterLabel === 'All' ? 'Filter By Status' : statusArrayFilterLabel}
					</button>

					<ul className="dropdown-menu mt-2 px-3">
						<div className="d-flex justify-content-between align-content-center mt-2">
							<p className="sortby-title g_mid">Filter By Status</p>
							<img
								role="button"
								src={toAbsoluteUrl('/media/logo/cross.png')}
								alt="Close"
								className="img-fluid image-cross-style"
							/>
						</div>

						<div className="dropdown-border"></div>

						{statusArray.map((option) => (
							<div className="form-check mt-1" key={option.id}>
								<input
									className="form-check-input"
									type="radio"
									name="statusFilter"
									id={option.id}
									value={option.value}
									checked={
										selectedFilter === option.status ||
										(selectedFilter === null && option.status === null)
									}
									onChange={() => {
										handleRadioChange(option.status);
									}}
								/>
								<label
									className="form-check-label fs-14 g_reg w-100"
									htmlFor={option.id}
									onClick={(e) => e.stopPropagation()}>
									{option.label}
								</label>
							</div>
						))}

						<div className="my-3">
							<button
								className="btn dp-cancel g_mid me-3"
								onClick={() => {
									setSelectedFilter(null);
									applyFilter();
								}}>
								Clear
							</button>
							<button className="btn dp-apply g_mid" onClick={applyFilter}>
								Apply
							</button>
						</div>
					</ul>
				</div>
			</div>

			<div className="border-light-subtle rounded-4 m-lg-4 my-4 overflow-x-auto border p-4">
				{paymentRecords.length === 0 ? (
					<div className="align-items-center d-flex vh-90 justify-content-center">
						<div className="text-center">
							<div>
								<img src={toAbsoluteUrl('/media/PaymentHistory/payment.svg')} alt="Logo" />
							</div>
							<div className="payment-nodata-title lora">No Purchase History</div>
							<div className="payment-nodata-label g_reg">
								You can see Purchase History once you’ve made a payment
							</div>
						</div>
					</div>
				) : (
					<div className="w-100">
						<table className="responsive-table table">
							<thead>
								<tr className="payment-table-title g_reg">
									{tableColumnHeaders.map((header, index) => (
										<th key={index}>{header}</th>
									))}
								</tr>
							</thead>
							<tbody>
								{paymentRecords.map((record: any, index: number) => (
									<tr
										key={record.id}
										className={
											index === paymentRecords.length - 1 ? '' : 'border-color7 border-light-subtle'
										}>
										<td className="payment-inner-details">
											{record.orderDetails?.[0]?.orderId ?? 'N/A'}
										</td>
										<td className="payment-inner-details">
											{`${new Date(record.transactionDateTime).getDate()} ${new Date(
												record.transactionDateTime,
											).toLocaleString('default', { month: 'long' })} ${new Date(
												record.transactionDateTime,
											).getFullYear()} `}
										</td>
										<td className="payment-inner-details">
											{record.orderDetails?.[0]?.course?.name ?? 'N/A'}
										</td>
										<td className="payment-inner-details">{record.total}</td>
										<td className="payment-inner-details">{record.status ?? 'N/A'}</td>

										<td
											className={hoveredRecord === record ? 'position-relative' : ''}
											onMouseEnter={() => handleMouseEnter(record)}
											onMouseLeave={handleMouseLeave}>
											{record.status === 'PAID' && (
												<StatusDisplay icon="checkmark.svg" label="Success" className="color9" />
											)}
											{record.status === 'FAILED' && (
												<StatusDisplay icon="alert.svg" label="Failed" className="color10" />
											)}
											{record.status === 'PENDING' && (
												<StatusDisplay icon="time.svg" label="Pending" className="color12" />
											)}

											{hoveredRecord === record && (
												<PaymentPopup
													status={record.status}
													transactionDateTime={record.transactionDateTime}
												/>
											)}
										</td>

										<td className="pt-4">
											<DownloadButton
												isDisabled={record.status !== 'PAID'}
												onClick={() => handleDownloadInvoice(record.orderDetails?.[0]?.orderId)}
											/>
										</td>
									</tr>
								))}
								{invoiceData && (
									<InvoiceDownloadLink
										invoiceData={invoiceData}
										paymentRecords={paymentRecords}
										setInvoiceData={setInvoiceData}
										id={invoiceOrderId}
									/>
								)}
							</tbody>
						</table>
					</div>
				)}
			</div>
			{paymentRecords.length !== 0 ? (
				<div className="d-flex justify-content-sm-between justify-content-center align-items-center px-4">
					{!isMobileScreen && (
						<div className="d-flex">
							<p className="pagination-style g_mid m-0">
								Page {currentPage} of {totalPages}
							</p>
						</div>
					)}
					<ul className="pagination align-items-center">
						<li
							className={`page-item ${currentPage === 1 ? 'disabled' : ''} `}
							onClick={() => handlePageChange(currentPage - 1)}>
							<a className="page-link page_link" href="#">
								<img src={toAbsoluteUrl('/media/logo/pleft.png')} alt="Previous" className="me-2" />
							</a>
						</li>
						{isMobileScreen ? (
							// Mobile Screen: Show only previous, current, and next page
							<>
								{currentPage > 1 && (
									<li className="page-item" onClick={() => handlePageChange(currentPage - 1)}>
										<a className="page-link page_link" href="#">
											{currentPage - 1}
										</a>
									</li>
								)}
								<li className="page-item active">
									<a className="page-link page_link" href="#">
										{currentPage}
									</a>
								</li>
								{currentPage < totalPages && (
									<li className="page-item" onClick={() => handlePageChange(currentPage + 1)}>
										<a className="page-link page_link" href="#">
											{currentPage + 1}
										</a>
									</li>
								)}
							</>
						) : (
							(() => {
								const pageNumbers = [];
								const maxVisiblePages = 5;
								const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
								const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

								// Adjust startPage when close to the end
								if (endPage - startPage < maxVisiblePages - 1) {
									const adjustedStart = Math.max(1, endPage - maxVisiblePages + 1);
									for (let page = adjustedStart; page <= endPage; page++) {
										pageNumbers.push(page);
									}
								} else {
									for (let page = startPage; page <= endPage; page++) {
										pageNumbers.push(page);
									}
								}

								return pageNumbers.map((page) => (
									<li
										key={page}
										className={`page-item ${page === currentPage ? 'active' : ''}`}
										onClick={() => handlePageChange(page)}>
										<a className="page-link page_link" href="#">
											{page}
										</a>
									</li>
								));
							})()
						)}
						<li
							className={`page-item ${currentPage === totalPages ? 'disabled' : ''} `}
							onClick={() => handlePageChange(currentPage + 1)}>
							<a className="page-link page_link" href="#">
								<img src={toAbsoluteUrl('/media/logo/pright.png')} alt="Next" />
							</a>
						</li>
					</ul>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default PaymentHistory;
