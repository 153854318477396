import React from "react";
import "./PaymentInformation.css";
import { toAbsoluteUrl } from "assets/helpers/AssetHelpers";
import {HOME_URL, MY_COURSE_URL} from "../../../pages/routing/routePages";
import {useNavigate} from "react-router-dom";

interface PaymentStatus {
  image: string;
  alt: string;
  title: string;
  description: React.ReactNode;
}

const PaymentSuccess: React.FC<PaymentStatus> = ({
  image,
  alt,
  title,
  description,
}) => {
  const navigate = useNavigate();

  return (
    <section className="payment-detail-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="payment-layout">
            <div className="card px-4">
              <div className="layout-success text-center">
                <img
                  src={toAbsoluteUrl("/media/logo/hoGrowthBlack.svg")}
                  alt="Logo"
                  className="pb-4"
                />
                <div>
                  <img src={image} alt={alt} className="mb-4" />
                </div>
                <h2 className="payment-status lora">{title}</h2>
                <p className="payment-desc g_mid">{description}</p>
                <div className="mb-4 mt-4 flex-column-reverse d-md-block d-flex gap-4">
                  <button
                    className="button back-btn back-btn-hover g_mid w-auto me-md-3"
                    onClick={() => navigate(HOME_URL)}
                  >
                    Back to Home Page
                  </button>
                  <button
                    className="button myCourse-btn myCourse-btn-hover g_mid w-auto"
                    onClick={() => navigate(MY_COURSE_URL)}
                  >
                    Go to My Courses
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSuccess;
