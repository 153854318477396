import {
	LessonStatusEnum,
	LessonType,
	LessonTypeEnum,
	UsersGendersEnum,
} from 'components/_v2/Course/type';

export enum EHeaderType {
	WHITE = 'white',
	BLACK = 'black',
}

export interface UserType {
	id: string;
	username: string;
	firstName: string;
	lastName: string;
	fullName: string;
	email: string;
	roleId: string;
	userType: UserTypeEnum;
	resetPasswordKey: string;
	createdAt: Date;
	updatedAt: Date;
	status: UserStatusEnum;
	isVerified: boolean;
	isMobileNumberVerified: boolean;
	signinType: UserSigninType;
	userProfile: UserProfile[];
	userAddress: UserAddressType[];
	role: RoleObjectType;
}

export interface UserAddressType {
	address: string;
	city: string;
	createdAt: Date;
	fullname: string;
	id: string;
	state: string;
	updatedAt: Date;
	userId: string;
	zipCode: string;
}

export enum UserTypeEnum {
	ADMIN = 'ADMIN',
	INSTRUCTOR = 'INSTRUCTOR',
	STUDENT = 'STUDENT',
}

export enum UserStatusEnum {
	IN_REVIEW = 'IN_REVIEW',
	APPROVED = 'APPROVED',
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export enum UserSigninType {
	EMAIL = 'EMAIL',
	GOOGLE = 'GOOGLE',
	FACEBOOK = 'FACEBOOK',
	MOBILE = 'MOBILE',
	APPLE = 'APPLE',
}

export interface UserProfile {
	ageRange: AgeRangeEnum;
	country: CountryObjectType;
	countryId: string;
	createdAt: Date;
	createdBy: UserType;
	createdById: string;
	description: string;
	dob: Date;
	gender: UsersGendersEnum;
	goal: GoalObjectType;
	goalId: string;
	id: string;
	imageUrl: string;
	instagramUrl: string;
	linkedinUrl: string;
	mobileNo: string;
	mobileNoStdCode: string;
	occupation: OccupationObjectType;
	occupationId: string;
	platformReferral: PlatformReferralObjectType;
	platformReferralId: string;
	qualification: string;
	stockMarketAndTradingUnderstandingLevel: UnderstandingLevelEnum;
	twitterUrl: string;
	updatedAt: string;
	updatedById: string;
	userId: string;
}

export interface AgeRangeEnum {
	UNDER_18: 'UNDER_18';
	BETWEEN_18_AND_24: 'BETWEEN_18_AND_24';
	BETWEEN_25_TO_34: 'BETWEEN_25_TO_34';
	BETWEEN_35_TO_44: 'BETWEEN_35_TO_44';
	ABOVE_45: 'ABOVE_45';
}

export interface CountryObjectType {
	countryCode: string;
	countryName: string;
	countryStdCode: string;
	id: string;
}

export interface GoalObjectType {
	createdAt: Date;
	createdBy: UserType;
	createdById: string;
	id: string;
	name: string;
	status: GoalStatusEnum;
	updatedAt: Date;
	updatedBy: UserType;
	updatedById: string;
}

export enum GoalStatusEnum {
	ACTIVE = 'ACTIVE',
	INACTIVE = 'INACTIVE',
}

export interface OccupationObjectType {
	createdAt: Date;
	id: string;
	isDefault: boolean;
	name: string;
	updatedAt: Date;
}

export interface PlatformReferralObjectType {
	createdAt: Date;
	id: string;
	isDefault: boolean;
	name: string;
	updatedAt: Date;
}

export enum UnderstandingLevelEnum {
	BEGINNER = 'BEGINNER',
	INTERMEDIATE = 'INTERMEDIATE',
	ADVANCED = 'ADVANCED',
}

export interface RoleObjectType {
	createdAt: Date;
	id: string;
	name: string;
	permissions: PermissionObjectType[];
	slug: string;
	updatedAt: Date;
}

export interface PermissionObjectType {
	createdAt: Date;
	id: string;
	name: string;
	slug: string;
	updatedAt: Date;
}

export interface CreateOrderDetailsInput {
	courseId: string;
	noOfSeat: number;
	price: number;
	totalPrice: number;
	tax: number;
	couponDiscountAmount?: number;
	discountType?: string;
	taxAmount: number;
	subTotal: number;
	discountValue?: number;
}

export interface BreadcrumbDataProps {
	name: string;
	href?: string;
}

export enum ButtonType {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
}

export const CERTIFICATE_LESSON = 'CERTIFICATE';

export const certificateSectionLesson = ({
	isLocked,
	nameOfCertificate,
	sectionId,
	disabledSelection,
}: {
	isLocked: boolean;
	nameOfCertificate: string;
	sectionId: string;
	disabledSelection: boolean;
}): LessonType => ({
	completed: false,
	description: 'Certificate',
	id: CERTIFICATE_LESSON,
	introduction: 'Certificate',
	isLock: isLocked,
	name: nameOfCertificate,
	sectionId,
	status: LessonStatusEnum.NOT_STARTED,
	type: LessonTypeEnum.DOCS,
	disabledSelection,
});
