import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { LessonStatusEnum, LessonType, LessonTypeEnum } from 'components/_v2/Course/type';
import { useQuizContext } from 'context/QuizContext';
import moment from 'moment';
import { Image } from 'react-bootstrap';

const LessonStatus = ({ lessonData }: { lessonData: LessonType }) => {
	const lessonStatus = lessonData.status;
	const isQuiz = lessonData.type === LessonTypeEnum.QUIZ;
	const isLocked = lessonData.isLock;
	const { quiz } = useQuizContext();

	if (isLocked) {
		const startDate = moment(lessonData.startDate).format('MMMM Do, YYYY [at] h:mm A');
		const isStartDateBehindCurrentDate = moment(lessonData.startDate).isBefore(moment());
		return (
			<div className="d-flex flex-column align-items-center gap-1">
				<div className="d-flex align-items-center pe-2">
					<Image src={toAbsoluteUrl('/media/course-detail-images/course-locked.svg')} />
					<span className="g_reg ms-2 text-justify">
						{isStartDateBehindCurrentDate
							? `Due to some technical error, the ${
									isQuiz ? 'quiz' : 'session'
							  } couldn't be unlocked on`
							: `This ${isQuiz ? 'quiz' : 'session'} will unlock on`}
					</span>
				</div>
				<span className="g_mid">{startDate}</span>
			</div>
		);
	}

	switch (lessonStatus) {
		case LessonStatusEnum.COMPLETED:
			return (
				<div className="d-flex flex-column align-items-center">
					<div className="d-flex align-items-center pe-2">
						<Image src={toAbsoluteUrl('/media/course-detail-images/status_completed.svg')} />
						<span className="g_reg ms-2">
							This {isQuiz ? 'quiz' : 'session'} was completed on &nbsp;
							<span className="g_mid">
								{moment(
									lessonData?.completedAt
										? lessonData?.completedAt
										: isQuiz
										? quiz.endTime
										: lessonData?.completedAt,
								).format('hh:mm A, DD MMMM,YYYY')}
							</span>
						</span>
					</div>
				</div>
			);
		case LessonStatusEnum.IN_PROGRESS:
			return (
				<div className="d-flex align-items-center">
					<Image src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')} />
					<span className="g_reg ms-2">This {isQuiz ? 'quiz' : 'session'} is live</span>
				</div>
			);

		case LessonStatusEnum.NOT_STARTED: {
			const startDate = moment(lessonData.startDate).format('MMMM Do, YYYY [at] h:mm A');
			return (
				<div className="d-flex flex-column align-items-center gap-1">
					<div className="d-flex align-items-center pe-2">
						<Image src={toAbsoluteUrl('/media/course-detail-images/course-locked.svg')} />
						<span className="g_reg ms-2">
							This {isQuiz ? 'quiz' : 'session'} will start on &nbsp;
						</span>
					</div>
					<span className="g_mid">{startDate}</span>
				</div>
			);
		}

		default:
			break;
	}
};

export default LessonStatus;
