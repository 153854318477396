import React, { useEffect } from 'react';
import './allcourse.css';
import OurCourse from 'components/_v2/Course/OurCourse';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import TopTitleSection from '../CommonComponents/TopTitleSection';

const AllCourse = () => {
	const { hasValueSet } = useCourseContext();
	const { getCourses, courses, error, loading } = useCourses();

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}
	}, [hasValueSet]);

	return (
		<section>
			<div className="container-fluid info-layout">
				<div className="row">
					<TopTitleSection
						title="Courses"
						description="Explore the courses that our learners love the most. Tried,
                tested, and highly recommended for your success."
					/>
				</div>
			</div>
			<OurCourse loading={loading} error={error} courses={courses} />
		</section>
	);
};

export default AllCourse;
