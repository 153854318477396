import React, { useState, useEffect } from 'react';
import { useCourseContext } from 'context/CourseContext';
import useCourses from 'hooks/useCourses';
import HeroSection from '../_v2/HeroSection/HeroSection';
import Partners from 'components/_v2/Partners/Partners';
import OurCourse from 'components/_v2/Course/OurCourse';
import Best from 'components/_v2/BeTheBest/Best';
import HowItWork from 'components/_v2/HowItWork/HowItWork';
import CourseCta from 'components/_v2/CTA/CourseCta';
import Discover from 'components/_v2/Discover/Discover';
import Stories from 'components/_v2/Stories/Stories';
import Faq from 'components/_v2/HgFAQ/Faq';
import DownloadApp from 'components/_v2/DownloadApp/DownloadApp';
import Blog from 'components/_v2/Blog/Blog';
import Popup from 'components/_v2/popup/Popup';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonSlider from 'components/_v2/CommonComponents/CommonSlider';
import { useUserContext } from 'context/UserContext';
import useLoginRegister from 'hooks/useLoginRegister';
import { Login } from 'pages/auth/Login';
import { Register } from 'pages/auth/Register';
import { COURSE_URL } from '../../pages/routing/routePages';

export const HomePage = () => {
	const { hasValueSet, userCourses } = useCourseContext();
	const { courses, error, getCourses, loading } = useCourses();
	const { user } = useUserContext();
	const [showPopup, setShowPopup] = useState(false);
	const {
		toggleLoginOpen,
		handleRegisterClose,
		open,
		openRegister,
		toggleRegisterOpen,
		setOpen,
		setOpenRegister,
	} = useLoginRegister();

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const targetId = location.hash.replace('#', '');
		if (!targetId) return;

		const observer = new MutationObserver(() => {
			const targetElement = document.getElementById(targetId);

			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth' });
				observer.disconnect();
			}
		});

		observer.observe(document.body, { childList: true, subtree: true });

		return () => {
			observer.disconnect();
		};
	}, [location]);

	useEffect(() => {
		if (!hasValueSet) {
			getCourses();
		}

		const cookiesAccepted = localStorage.getItem('cookiesAccepted');
		if (!cookiesAccepted) {
			setShowPopup(true);
		}
	}, [hasValueSet]);

	const handleAcceptCookies = () => {
		localStorage.setItem('cookiesAccepted', 'true');
		setShowPopup(false);
	};

	return (
		<div style={{ position: 'relative' }}>
			<HeroSection />
			<Partners smColClass="col-12" mdColClass="col-md-10" lgColClass="col-lg-10"/>
			<OurCourse courses={courses} error={error} loading={loading} />
			{/*<CommonSlider*/}
			{/*	headingText="Upcoming Offline Batches"*/}
			{/*	headingSubText="Learn from the Experienced Pro Traders"*/}
			{/*	courses={courses}*/}
			{/*	viewAllAction={() => navigate(COURSE_URL)}*/}
			{/*	userCourses={userCourses}*/}
			{/*	user={user}*/}
			{/*	showBackdropShadow={false}*/}
			{/*	handleRegistration={toggleLoginOpen}*/}
			{/*	slidesToShow={4}*/}
			{/*/>*/}
			<Best />
			<HowItWork />
			<CourseCta />
			<Discover />
			<Stories />
			<Faq />
			<DownloadApp />
			<Blog />
			{showPopup && <Popup onClose={handleAcceptCookies} />}
			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={toggleLoginOpen}
				/>
			)}
		</div>
	);
};
