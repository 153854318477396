import { gql, useLazyQuery } from '@apollo/client';
import { FaqsData, FaqsType } from 'components/_v2/CourseDetail/CourseFAQS/types';
import {
  TestimonialData,
  TestimonialsType
} from 'components/_v2/CourseDetail/CourseTestimonials/type';
import { useState } from 'react';

const useTestimonialsAndFaq = (courseId: string) => {
  const GET_PUBLIC_TESTIMONIALS = gql`
  query GetPublicTestimonials {
        getPublicTestimonials(where: {reference_id: {eq:"${courseId}"}}, paginate: {}) {
            records {
                id
                name
                designation
                content
                image
            }
            pagination {
                totalRecords
                currentPage
            }
        }
  }
`;

  const GET_PUBLIC_FAQS = gql`
  query GetPublicFaqsList {
  getPublicFaqsList(where: {course_id: {eq:"${courseId}"}}, paginate: {}) {
      records {
        id
        question
        answer
        sortOrder
        type
        status
        courseId
      }
      pagination {
        totalRecords
        currentPage
      }
    }
  }`;

  const [testimonials, setTestimonials] = useState<TestimonialsType[] | undefined>([]);
  const [loadTestimonials, { loading: loadingTestimonials, error: errorTestimonials }] =
    useLazyQuery<TestimonialData>(GET_PUBLIC_TESTIMONIALS, {
      onCompleted: (data: TestimonialData) =>
        setTestimonials(() => data?.getPublicTestimonials?.records)
    });

  const getTestimonials = () => {
    loadTestimonials();
  };

  const [faqsList, setFaqsList] = useState<FaqsType[] | undefined>([]);
  const [loadFaqs, { loading: loadingFaqs, error: errorFaqs }] = useLazyQuery<FaqsData>(
    GET_PUBLIC_FAQS,
    {
      onCompleted: (data: FaqsData) => setFaqsList(() => data?.getPublicFaqsList?.records)
    }
  );

  const getFaqsList = () => {
    loadFaqs();
  };

  return {
    getTestimonials,
    testimonialsData: testimonials,
    errorTestimonials,
    loadingTestimonials,
    getFaqsList,
    faqsData: faqsList,
    loadingFaqs,
    errorFaqs
  };
};

export default useTestimonialsAndFaq;
