import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ChangePassword.css";
import { toast } from "react-hot-toast";
import { toAbsoluteUrl } from "assets/helpers/AssetHelpers";
import { useUserContext } from "context/UserContext";

// GraphQL Mutations
const SEND_EMAIL_OTP = gql`
  mutation SendEmailOtp {
    sendEmailOtp
  }
`;

const NEW_CHANGE_PASSWORD = gql`
  mutation NewChangePassword(
    $otp: String!
    $newPassword: String!
    $confirmNewPassword: String!
  ) {
    newChangePassword(
      newChangePasswordInput: {
        otp: $otp
        newPassword: $newPassword
        confirmNewPassword: $confirmNewPassword
      }
    )
  }
`;

const OtpForm = ({
  otp,
  setOtp,
  canResend,
  seconds,
  handleResendOTP,
  handleVerificationClose,
  handleVerifyOtp,
  changingPassword,
}) => (
  <form>
    <div className="fv-row mb-10 px-4">
      <label className="label-style g_reg">OTP</label>
      <input
        className="form-control input-inner-style g_reg"
        name="otp"
        placeholder="Enter OTP"
        type="password"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />
      <div className="veri-desc text-start mt-2 mb-4">
        {canResend ? (
          <button className="btn resend-btn" onClick={handleResendOTP}>
            Resend OTP
          </button>
        ) : (
          <>
            Please wait&nbsp;
            <span className="veri-desc-strong">{seconds}s</span>
            &nbsp;before requesting another code
          </>
        )}
      </div>
    </div>
    <div className="mb-4 col-12 text-center">
      <button
        className="btn g_mid me-3 new-btn-fg-cancel-layout"
        type="button"
        onClick={handleVerificationClose}
      >
        Cancel
      </button>
      <button
        className="btn bg-color2 g_mid new-btn-fg-layout"
        type="button"
        onClick={() =>
          handleVerifyOtp({ newPassword: "", confirmNewPassword: "" })
        }
        disabled={changingPassword}
      >
        {changingPassword ? "Changing Password..." : "Continue"}
      </button>
    </div>
  </form>
);

const ChangePassword = () => {
  const [openVerification, setOpenVerification] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [canResend, setCanResend] = useState(false);
  const [seconds, setSeconds] = useState(89);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { user }: any = useUserContext();
  const ChangePasswordEmail = user?.email;

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const [sendEmailOtp, { loading: sendingOtp }] = useMutation(SEND_EMAIL_OTP, {
    onCompleted: () => {
      toast.success("OTP sent successfully!", {
        className: "g_mid",
      });
      setOpenVerification(true);
      setModalOpen(true);
    },
    onError: (error) => {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP", {
        className: "g_mid",
      });
    },
  });

  const [newChangePassword, { loading: changingPassword }] = useMutation(
    NEW_CHANGE_PASSWORD,
    {
      onCompleted: () => {
        toast.success("Password changed successfully!", {
          className: "g_mid",
        });
        setModalOpen(false);
        setOpenVerification(false);
        setOtp("");
      },
      onError: (error) => {
        console.error("Error changing password:", error);
        if (error.message.includes("otp not matched")) {
          toast.error("Invalid OTP!", {
            className: "g_mid",
          });
        } else {
          toast.error("Error changing password:");
        }
      },
    }
  );

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, "Minimum of 8 characters")
      .matches(/(?=.*\d)/, "At least 1 number")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])/,
        "At least 1 uppercase and 1 lowercase letter"
      )
      .matches(/(?=.*[!@#$%^&*])/, "At least 1 special character")
      .required("Please enter new password "),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please enter confirm password"),
  });

  const handleVerifyClick = (values, { resetForm }) => {
    setSeconds(89);
    sendEmailOtp();
    resetForm(); // Reset fields here
  };

  const handleVerificationClose = () => {
    setOpenVerification(false);
    setModalOpen(false);
  };

  const handleVerifyOtp = (values) => {
    newChangePassword({
      variables: {
        otp,
        newPassword: values.newPassword,
        confirmNewPassword: values.confirmNewPassword,
      },
    });
  };

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
    }
  }, [seconds]);

  const handleResendOTP = () => {
    setSeconds(89);
    setCanResend(false);
    sendEmailOtp();
  };

  return (
    <div className="row mt-5">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-4">
            <h2 className="change-password-title lora">Change Password</h2>
            <Formik
              initialValues={{ newPassword: "", confirmNewPassword: "" }}
              validationSchema={validationSchema}
              onSubmit={handleVerifyClick}
            >
              {({ isSubmitting, resetForm }) => (
                <Form>
                  <div className="col-md-11 my-4">
                    <label
                      htmlFor="newPassword"
                      className="change-labels g_reg"
                    >
                      New Password
                    </label>
                    <br />
                    <div className="password-input-wrapper position-relative">
                      <Field
                        className="input-box-style form-control g_reg"
                        type={showPassword ? "text" : "password"}
                        name="newPassword"
                        placeholder="Enter New Password"
                        disabled={sendingOtp} // Disable field when sending OTP
                      />
                      <button
                        type="button"
                        className="btn eye-icon eye-icon-outline position-absolute"
                        style={{
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={`fa ${
                            showPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                        ></i>
                      </button>
                    </div>
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="error-message mt-1 fs-14 text-danger g_reg"
                    />
                  </div>
                  <div className="col-md-11">
                    <label
                      htmlFor="confirmNewPassword"
                      className="change-labels g_reg"
                    >
                      Confirm Password
                    </label>
                    <br />
                    <div className="password-input-wrapper position-relative">
                      <Field
                        className="input-box-style form-control g_reg"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmNewPassword"
                        placeholder="Enter Confirm Password"
                        disabled={sendingOtp} // Disable field when sending OTP
                      />
                      <button
                        type="button"
                        className="btn eye-icon eye-icon-outline position-absolute"
                        style={{
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        <i
                          className={`fa ${
                            showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                          }`}
                        ></i>
                      </button>
                    </div>
                    <ErrorMessage
                      name="confirmNewPassword"
                      component="div"
                      className="error-message mt-1 fs-14 text-danger g_reg"
                    />
                  </div>
                  <div className="mt-4 mb-4 mb-lg-0">
                    <button
                      className="change-otp-btn btn g_mid"
                      type="submit"
                      style={{ cursor: "pointer" }}
                      disabled={sendingOtp}
                    >
                      {sendingOtp ? "Sending OTP..." : "Send OTP"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-lg-8">
            <div className="rules-layout">
              <h4 className="rules-title g_semi">Password Rules</h4>
              <p className="rules-label g_reg mb-4">
                To create a new password, you have to meet all of the following
                rules:
              </p>
              <ul className="rules-label g_reg">
                <li>Minimum of 8 characters</li>
                <li>At least 1 special character</li>
                <li>At least 1 number</li>
                <li>Cannot be the same as the previous password</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <div className="modal-backdrop">
          <div
            className="modal fade show"
            id="exampleModalToggle"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabIndex={-1}
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered mx-auto">
              <div className="modal-content container">
                <div className="modal-header border-0">
                  <div className="mx-auto text-center">
                    <img
                      src={toAbsoluteUrl("/media/logo/veri.png")}
                      alt="Verification"
                    />
                    <h2 className="veri-title lora">Verification</h2>
                    <p className="veri-label veri-otp-layout g_reg">
                      Please enter the 6-digit code sent to <br />
                      <span className="veri-label-strong">
                        {ChangePasswordEmail}
                      </span>
                    </p>
                  </div>
                </div>
                <OtpForm
                  otp={otp}
                  setOtp={setOtp}
                  canResend={canResend}
                  seconds={seconds}
                  handleResendOTP={handleResendOTP}
                  handleVerificationClose={handleVerificationClose}
                  handleVerifyOtp={handleVerifyOtp}
                  changingPassword={changingPassword}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
