import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import ButtonRenderer from 'components/_v2/CommonComponents/ButtonRenderer';
import { useQuizContext } from 'context/QuizContext';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import { ButtonType } from 'types/globalTypes';
import { LessonChange } from '../ViewCourseData';
import { QuizResultStatusEnum } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';

const QuizCompleted = ({
	totalMarksAttained = 0,
	handleLessonChange,
	isLastLessonOfLastSection,
	allowReattempt,
	handleReattemptQuiz,
	totalQuizMarks,
	reattemptStatus,
}: {
	totalMarksAttained: number;
	handleLessonChange: (shiftType: LessonChange) => void;
	isLastLessonOfLastSection: boolean;
	allowReattempt: boolean;
	handleReattemptQuiz: () => void;
	totalQuizMarks: number;
	reattemptStatus: QuizResultStatusEnum;
}) => {
	const { quizResult } = useQuizContext();

	return (
		<div className="d-flex flex-column align-items-center gap-1">
			{!allowReattempt && reattemptStatus === QuizResultStatusEnum.FAILED ? (
				<Image
					src={toAbsoluteUrl('/media/course-detail-images/quiz-failed-all-attempts.svg')}
					height={274}
					width={369}
				/>
			) : (
				<Image
					src={toAbsoluteUrl('/media/course-detail-images/quiz-completed.svg')}
					height={274}
					width={369}
				/>
			)}
			<div className="d-flex flex-column align-items-center mb-5 mt-4 gap-1">
				<p className="course-submit-text g_reg">
					Submitted on &nbsp;
					{moment(quizResult?.quizSubmitTime).format('DD MMMM YYYY')}
				</p>
				<p className="course-yourscore-style g_mid">
					{!allowReattempt
						? `Your Score: ${totalMarksAttained}`
						: `Previous Score: ${totalMarksAttained} / ${totalQuizMarks}`}
				</p>
				{allowReattempt && (
					<span className="g_mid mb-1" style={{ color: '#757575' }}>
						Required Percentage is 70%
					</span>
				)}
				<div className="d-flex align-items-center gap-3">
					{!isLastLessonOfLastSection && !allowReattempt && (
						<ButtonRenderer
							text="Continue Learning"
							typeOfButton={ButtonType.PRIMARY}
							handleClick={() => handleLessonChange(LessonChange.NEXT)}
						/>
					)}
					{allowReattempt && (
						<ButtonRenderer
							text="Retake Quiz"
							typeOfButton={ButtonType.PRIMARY}
							handleClick={handleReattemptQuiz}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default QuizCompleted;
