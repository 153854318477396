import React, { useCallback, useState } from 'react';
import './ourcourse.css';
import { ApolloError } from '@apollo/client';
import { Course } from './type';
import { useLocation } from 'react-router-dom';
import { EXPLORE_COURSE_SECTION } from 'utils/consts';
import CardInformation from 'components/_v2/CommonComponents/CardInformation';
import { useUserContext } from 'context/UserContext';
import { useCourseContext } from 'context/CourseContext';
import { CardHeight } from 'components/_v2/CommonComponents/types';
import { Login } from 'pages/auth/Login';
import { Register } from 'pages/auth/Register';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import useCourses from 'hooks/useCourses';
import useLoginRegister from 'hooks/useLoginRegister';
import Loading from '../CommonComponents/Loading';

interface Props {
  loading: boolean;
  error: ApolloError | undefined;
  courses: Course[];
}

const OurCourse: React.FC<Props> = ({ courses, error, loading }) => {
	const location = useLocation();
	const { user } = useUserContext();
	const { downloadCertificateForCourse } = useCourses();
	const { userCourses } = useCourseContext();
	const {
		toggleLoginOpen,
		handleRegisterClose,
		open,
		openRegister,
		toggleRegisterOpen,
		setOpen,
		setOpenRegister,
	} = useLoginRegister();

	if (loading) return <Loading width={200} />;
	if (error) {
		console.error('Error fetching courses:', error);
		return (
			<div className="course-card-section-spacing d-flex justify-content-center">
				There was some error in fetching courses. Please try again is some time.
			</div>
		);
	}

	const isHomePage = location.pathname === '/';

	return (
		<section className="our-course-wrapper" id={EXPLORE_COURSE_SECTION}>
			<div className="container">
				<div className="row">
					<div className="col-lg-10 layout-set">
						{isHomePage && (
							<div className="text-center">
								<h2 className="lora course-section-title">Explore Our Courses</h2>
								<p className="course-section-subtitle g_reg my-3">
									Explore the courses that our learners love the most. Tried, tested, and highly
									recommended for your success.
								</p>
							</div>
						)}
						<div className="row d-flex justify-content-start course-card-section-spacing">
							{!courses.length ? (
								<div className="d-flex flex-column align-items-center gap-2">
									<img
										src={toAbsoluteUrl('/media/course/no-courses.svg')}
										alt=""
										style={{ width: 400 }}
									/>
									<span className="d-flex g_reg fs-20 justify-content-center align-items-center">
										There are no currently active or upcoming Courses. Stay tuned!
									</span>
								</div>
							) : (
								courses.map((course: Course) => (
									<CardInformation
										key={course.id}
										course={course}
										handleRegistration={toggleLoginOpen}
										isUserSpecificContent={false}
										disableHoverInteraction={false}
										handleDownloadPDF={() => downloadCertificateForCourse({ courseId: course.id })}
										user={user}
										userCourses={userCourses}
										cardHeight={CardHeight.LARGE}
										showBackdropShadow={true}
									/>
								))
							)}
						</div>
					</div>
				</div>
			</div>
			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={toggleLoginOpen}
				/>
			)}
		</section>
	);
};

export default OurCourse;