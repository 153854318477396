import React from 'react';
import { useQuizContext } from 'context/QuizContext';
import CurrentQuestionProgress from './CurrentQuestionProgress';
import FullScreenButton from './FullScreenButton';
import TimerComponent from './TimerComponent';
import ButtonRenderer from 'components/_v2/CommonComponents/ButtonRenderer';
import { ButtonType } from 'types/globalTypes';
import { QuizObjectType } from './type';
import useCourses from 'hooks/useCourses';
import useQuiz from 'hooks/useQuiz';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import './OngoingQuizButtons.css';

const VerticalLine = () => <div className="verticalSeparationLine" />;

const OngoingQuizButtons = ({
	handleFullScreen,
	isFullScreen = false,
	lessonQuiz,
	onTimeFinish,
	courseId,
	sectionId,
	lessonId,
}: {
	isFullScreen?: boolean;
	handleFullScreen?: () => void;
	lessonQuiz: QuizObjectType;
	onTimeFinish: () => void;
	sectionId: string;
	courseId: string;
	lessonId: string;
}) => {
	const { quiz, setCurrentQuestion } = useQuizContext();

	const { handleQuizSubmit } = useQuiz();
	const { getUserCourses } = useCourses();
	const finalQuestion = quiz.currentQuestion === lessonQuiz.questions.length;
	const isSmallScreen = useIsSmallScreen(576);

	const handleNextButtonClick = async () => {
		if (!finalQuestion) {
			handleQuizSubmit({
				courseId,
				sectionId,
				isSubmit: false,
				lessonId,
				quizId: lessonQuiz.id,
				questionAnswer: quiz.answersProvided,
			});
		}
		setCurrentQuestion(
			quiz.currentQuestion + 1,
			finalQuestion
				? {
						isSubmit: !!finalQuestion,
						quizId: lessonQuiz.id,
						sectionId,
						courseId,
				  }
				: undefined,
		);
		if (finalQuestion) {
			await getUserCourses();
		}
	};

	if (!quiz.endTime) {
		return null;
	}

	return (
		<div className={`${isSmallScreen ? '' : 'lectureButtons start-0'} w-100`}>
			<div className={`${isSmallScreen ? '' : 'dashedLine'} w-100`}>
				<div className="d-flex w-100 justify-content-between px-sm-0 mb-4 mt-3 px-4">
					{!isSmallScreen && (
						<div className="d-flex gap-3">
							<TimerComponent remainingTime={quiz.remainingTime} onTimeFinish={onTimeFinish} />
							<VerticalLine />
							<CurrentQuestionProgress
								questionNumber={quiz.currentQuestion}
								totalQuestions={lessonQuiz.questions.length}
							/>
							<VerticalLine />
							<FullScreenButton isFullScreen={isFullScreen} handleFullScreen={handleFullScreen} />
						</div>
					)}
					<div className="d-flex justify-content-between ongoingQuizButtons gap-3">
						{quiz.currentQuestion !== 1 ? (
							<ButtonRenderer
								handleClick={() => setCurrentQuestion(quiz.currentQuestion - 1)}
								text="Previous Question"
								typeOfButton={ButtonType.SECONDARY}
								textClass="g_mid"
							/>
						) : (
							<div></div>
						)}
						<ButtonRenderer
							handleClick={handleNextButtonClick}
							text={finalQuestion ? 'Submit' : 'Next Question'}
							typeOfButton={ButtonType.PRIMARY}
							textClass="g_mid"
							buttonClass=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OngoingQuizButtons;
