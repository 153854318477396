import React from 'react';
import './CourseHighLights.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { CourseHighlights } from 'components/_v2/Course/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const CourseHighLights = ({
	highlights,
	fullWidth = false,
}: {
	highlights: CourseHighlights[];
	fullWidth?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();
	return (
		<section className={fullWidth ? '' : isSmallScreen ? 'pb-3' : 'course-highlights-wrapper p-3'}>
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div
						className={`${
							fullWidth ? 'col-lg-12' : `col-xxl-9 col-lg-7 ${isSmallScreen ? 'w-100' : ''}`
						}`}>
						<h2 className="highlights-title lora">Course Highlights</h2>
						<p className="highlights-label g_reg">
							Students who successfully complete our course will obtain a HoGrowth certificate as
							confirmation of their learning.
						</p>
						<div className={`feature-list col-lg-12 col-xxl-9`}>
							{highlights.map((highlight, index) => (
								<div className="feature-item" key={highlight.id}>
									<div className="feature-icon">
										{/* <img
                      src={toAbsoluteUrl(
                        highlight.highlightIcon ??
                          "/media/course-detail-images/checkbox.png"
                      )}
                      alt="Check"
                    /> */}
										<img
											src={toAbsoluteUrl('/media/course-detail-images/checkbox.png')}
											alt="Check"
										/>
									</div>
									<div className="feature-text text-break g_reg">{highlight.highlightText}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseHighLights;
