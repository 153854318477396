import { FC, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { LOGIN_USER, STUDENTLOGINWITHSOCIALMEDIA } from './core/_requests';
import { useAuth } from './core/Auth';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ForGotPassword } from '../../components/common/ForGotPassword/ForGotPassword';
import { useMutation } from '@apollo/client';
import './login.css';
import { toAbsoluteUrl } from '../../assets/helpers/AssetHelpers';
import { useUserContext } from 'context/UserContext';
import { UserType } from 'types/globalTypes';
import useCourses from 'hooks/useCourses';
import Loading from 'components/_v2/CommonComponents/Loading';

type Props = {
	open: any;
	setOpen: any;
	toggleLoginOpen: () => void;
	toggleRegisterOpen: () => void;
	openRegister: any;
	setOpenRegister: any;
};

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Please enter a valid email address')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	password: Yup.string()
		.required('Password is required')
		.matches(
			passwordRegExp,
			'Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter',
		),
});
const initialValues = {
	email: '',
	password: '',
	confirmPassword: '',
};

export const Login: FC<Props> = ({
	open,
	setOpen,
	toggleRegisterOpen,
	openRegister,
	setOpenRegister,
	toggleLoginOpen,
}: any) => {
	const { getUserCourses } = useCourses();
	const { refetchLocalStorageTokenDetails } = useUserContext();

	const handleClose = () => setOpen(false);
	const handleCloseLogin = () => setOpen(false);

	const [login] = useMutation(LOGIN_USER);
	const [studentSignInWithSocialAccount] = useMutation(STUDENTLOGINWITHSOCIALMEDIA);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const { saveAuth, setCurrentUser } = useAuth();
	const { setUser } = useUserContext();
	const [status, setStatus] = useState<any>('');
	const [showAccountSelection, setShowAccountSelection] = useState(true);

	const loginGoogle = useGoogleLogin({
		onSuccess: async (codeResponse: any) => {
			try {
				if (showAccountSelection) {
					handleCloseLogin();
				}
				const userInfoResponse = await axios.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${codeResponse.access_token}`,
							Accept: 'application/json',
						},
					},
				);

				const { data } = await studentSignInWithSocialAccount({
					variables: {
						email: userInfoResponse.data.email,
						name: userInfoResponse.data.name,
						signinType: 'GOOGLE',
					},
					onCompleted: async (data: any) => {
						localStorage.setItem(
							'auth-react-v',
							JSON.stringify(data?.studentSignInWithSocialAccount),
						);
						refetchLocalStorageTokenDetails();
						await getUserCourses();
						toast.success('Successfully signed in with the correct credentials.', {
							className: 'g_mid',
						});
						toast.success('Successfully signed in with the correct credentials.', {
							className: 'g_mid',
						});
					},
					onError: (err: any) => {
						toast.error(err.message);
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
		onError: (error: any) => {
			toast.error(error.message);
			toast.error('Please Select proper account for the google signin');
		},
	});
	const handleForgotPasswordOpen = () => {
		setShowForgotPasswordModal(true);
	};

	const handleForgotPasswordClose = () => {
		setShowForgotPasswordModal(false);
		setOpen(true);
	};

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			try {
				await login({
					variables: {
						email: values.email,
						password: values.password,
					},
					onCompleted: async (data: { loginUser?: { access_token: string; user: UserType } }) => {
						const result = data?.loginUser;
						if (result && result.user.userType === 'STUDENT') {
							localStorage.setItem('auth-react-v', JSON.stringify(result));
							setUser(result.user);
							refetchLocalStorageTokenDetails();
							await getUserCourses();
							toast.success('Successfully signed in with the correct credentials.', {
								className: 'g_mid',
							});
							handleClose();
						} else {
							setStatus(<div className="g_reg">Only students are allowed to login.</div>);
						}
					},
					onError: (err: any) => {
						toast.error(err.message);
					},
				});
			} catch (error) {
				console.error(error);
				setStatus('The login details are incorrect');
				setSubmitting(false);
				setLoading(false);
			}
			setLoading(false);
		},
	});

	return (
		<>
			{open === true && (
				<>
					<Modal
						centered
						show={open}
						onHide={showAccountSelection ? handleCloseLogin : undefined}
						backdrop={true}>
						<Modal.Header className="header-top-layout">
							<Modal.Title className="border-0">
								<div className="row">
									<div className="col-md-12 lora login-title">Log In</div>
									<div className="login-new-label g_reg">
										You Don’t have an Account? &nbsp;
										<span
											className="login-new-label-span g_reg cursor-pointer"
											onClick={toggleRegisterOpen}>
											Sign Up
										</span>
									</div>
								</div>
							</Modal.Title>
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary close-btn-style g_reg"
								onClick={handleCloseLogin}>
								<i className="fa fa-close"></i>
							</div>
						</Modal.Header>

						<Modal.Body className="body-layout">
							<form onSubmit={formik.handleSubmit}>
								<div className="row">
									{formik.status ? (
										<div className="col-12 mb-3">
											<div className="mb-lg-15 alert alert-danger">
												<div className="alert-text font-weight-bold">{formik.status}</div>
											</div>
										</div>
									) : (
										<></>
									)}
									<div className="fv-row">
										<label className="label-style g_reg">
											Email <span style={{ color: 'red' }}>*</span>
										</label>
										<input
											{...formik.getFieldProps('email')}
											className={clsx(
												'form-control input-inner-style g_reg',
												{
													'is-invalid': formik.touched.email && formik.errors.email,
												},
												{
													'is-valid': formik.touched.email && !formik.errors.email,
												},
											)}
											type="email"
											name="email"
											placeholder="Enter Email Address"
											autoComplete="off"
										/>
										{formik.touched.email && formik.errors.email && (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{formik.errors.email}
											</div>
										)}
									</div>
									<div className="col-12 distance">
										<label className="label-style g_reg">
											Password <span style={{ color: 'red' }}>*</span>
										</label>
										<div className="input-group">
											<input
												placeholder="Enter Password"
												type={showPassword ? 'text' : 'password'}
												autoComplete="off"
												{...formik.getFieldProps('password')}
												className={clsx(
													'form-control input-inner-style g_reg',
													{
														'is-invalid': formik.touched.password && formik.errors.password,
													},
													{
														'is-valid': formik.touched.password && !formik.errors.password,
													},
												)}
											/>
											<button
												type="button"
												className="btn eye-icon position-absolute"
												style={{ right: '30px', top: '50%', transform: 'translateY(-50%)' }}
												onClick={() => setShowPassword(!showPassword)}>
												<i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
											</button>
										</div>
										{formik.touched.password && formik.errors.password && (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{formik.errors.password}
											</div>
										)}
										<a
											className="float-end fg-style g_reg cursor-pointer"
											onClick={handleForgotPasswordOpen}>
											Forgot Password?
										</a>
									</div>
									<div className="d-flex flex-stack fs-base fw-semibold distance-bottom flex-wrap gap-3"></div>

									<div className="col-12 distance">
										<button
											type="submit"
											className="btn bg-color2 w-100 new-btn-layout mb-3"
											disabled={formik.isSubmitting || !formik.isValid}>
											{!loading && <span className="g_mid">Continue</span>}
											{loading && (
												<span className="d-flex w-100 justify-content-center gap-2">
													<Loading width={50} />
													Please wait...
												</span>
											)}
										</button>
										<div className="divider">Or</div>
										<div className="col-12 distance mb-5">
											<button
												type="button"
												className="btn cwg-style w-100 g_mid"
												onClick={() => {
													loginGoogle();
												}}>
												<img
													alt="Logo"
													src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
													style={{ width: '25px' }}
													className="h-17px me-3 mb-1"
												/>
												{/* <FcGoogle className="me-2" /> */}
												Continue with Google
											</button>
										</div>
									</div>
								</div>
							</form>
						</Modal.Body>
					</Modal>
				</>
			)}
			{showForgotPasswordModal && (
				<ForGotPassword
					showForgotPasswordModal={showForgotPasswordModal}
					handleForgotPasswordClose={handleForgotPasswordClose}
				/>
			)}
		</>
	);
};
