import React, { useState } from 'react';
import './MyCourse.css';
import OnGoingCourse from '../OnGoingCourse/OnGoingCourse';
import CompletedCourse from '../Completed/CompletedCourse';
import { useCourseContext } from '../../../context/CourseContext';

const MyCourse = () => {
	const { userCourseData } = useCourseContext();

	const [activeTab, setActiveTab] = useState('ongoing');

	const handleTabChange = (tab: string) => {
		setActiveTab(tab);
	};

	return (
		<section style={{ backgroundColor: 'white' }}>
			<div className="container-fluid">
				<ul className="px-0" style={{ marginBottom: '-5px' }} id="myTab" role="tablist">
					<li className="nav-item nav_dash_item" role="presentation">
						<button
							className={`nav-style g_mid ${activeTab === 'ongoing' && 'active'}`}
							type="button"
							onClick={() => handleTabChange('ongoing')}>
							Ongoing
						</button>
					</li>
					<li className="nav-item nav_dash_item" role="presentation">
						<button
							className={`nav-style g_mid ${activeTab === 'completed' && 'active'}`}
							type="button"
							onClick={() => handleTabChange('completed')}>
							Completed
						</button>
					</li>
				</ul>
				<div id="myTabContent">
					<div
						className={`tab-pane fade ${activeTab === 'ongoing' && 'show active'}`}
						id="home-tab-pane"
						role="tabpanel">
						{activeTab === 'ongoing' && (
							<OnGoingCourse enrollmentCourses={userCourseData.inProgressCourses} />
						)}
					</div>
					<div
						className={`tab-pane fade ${activeTab === 'completed' && 'show active'}`}
						id="profile-tab-pane"
						role="tabpanel">
						{activeTab === 'completed' && (
							<CompletedCourse
								enrollmentCourses={userCourseData.completedCourses}
								hasPurchasedAnyCourse={
									!!userCourseData.inProgressCourses.length ||
									!!userCourseData.completedCourses.length
								}
								onGoToMyCourse={() => handleTabChange('ongoing')}
							/>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default MyCourse;
