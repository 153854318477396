import React, { useEffect } from 'react';
import './CourseTestimonials.css';
import { toAbsoluteUrl } from '../../../../assets/helpers/AssetHelpers';
import 'bootstrap/dist/css/bootstrap.min.css';
import useTestimonialsAndFaq from 'hooks/useTestimonialsAndFaq';
import { TestimonialsType } from 'components/_v2/CourseDetail/CourseTestimonials/type';

const CourseTestimonials = ({
	testimonialsData,
	fullWidth,
}: {
	testimonialsData: TestimonialsType[];
	fullWidth?: boolean;
}) => {
	const cardRenderer = ({
		key,
		imgSrc,
		name,
		label,
		desc,
		isOnlySingleTestimonial,
	}: {
		key: string | number;
		imgSrc: string;
		name: string;
		label: string;
		desc: string;
		isOnlySingleTestimonial: boolean;
	}) => {
		return (
			<div
				className={`${isOnlySingleTestimonial ? 'col-xxl-12' : 'col-xxl-6'} col-lg-12`}
				key={key}>
				<div className="card blackBorder">
					<div className="card-body">
						<div className="testimonials-icon d-flex align-items-center">
							<div>
								<img
									src={toAbsoluteUrl(imgSrc)}
									alt={name}
									className="img-fluid rounded-circle"
									style={{ width: '50px', height: '50px' }}
								/>
							</div>
							<div className="ms-3">
								<div className="testimonial-profile-name g_mid">{name}</div>
								<div className="testimonial-profile-label g_reg">{label}</div>
							</div>
						</div>
						<div className="testimonials-main-desc g_reg">{desc}</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<section className="course-testimonials-wrapper">
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-10'}`}>
						<div className="row">
							<div className={fullWidth ? 'col-lg-12' : 'col-lg-8'}>
								<h2 className="testimonials-heading lora">Testimonials</h2>

								<div className="testimonials-list row">
									{(testimonialsData || []).map((testimonial, index) =>
										cardRenderer({
											desc: testimonial.content,
											imgSrc: testimonial.image,
											key: index,
											label: testimonial.designation,
											name: testimonial.name,
											isOnlySingleTestimonial: testimonialsData.length === 1,
										}),
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseTestimonials;
