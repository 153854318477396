import React, { useCallback } from 'react';
import { LessonType, LessonTypeToText } from 'components/_v2/Course/type';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import {
	QuizReattemptDetails,
	QuizResultStatusEnum,
} from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';

const AccordionBody = ({
	lessons,
	showLockIcon,
	currentSelectedLessonId,
	quizReattemptList,
	handleLessonChange,
}: {
	lessons: LessonType[];
	showLockIcon: boolean;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
	handleLessonChange?: (lessonId: string) => void;
}) => {
	const listItemRenderer = useCallback(
		(lesson: LessonType) => {
			const showYellowBackground = lesson.id === currentSelectedLessonId;
			const showPendingOrFailedQuizStatus = quizReattemptList?.find(
				(quizDetails) => quizDetails.lessonId === lesson.id && quizDetails.quizId === lesson.quizId,
			);

			const allowReattemptQuiz =
				quizReattemptList?.find(
					(listItem) => listItem.lessonId === lesson.id && listItem.quizId === lesson.quizId,
				)?.allowReAttempt || false;

			const renderQuizStatus = (quizReattemptDetails: QuizReattemptDetails) => {
				switch (quizReattemptDetails?.status) {
					case QuizResultStatusEnum.PENDING:
						return (
							<span
								style={{ color: '#D66600', fontSize: '12px' }}
								className="d-flex align-items-center gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								Please attempt the quiz to continue
							</span>
						);
						break;
					case QuizResultStatusEnum.FAILED:
						return (
							<span
								style={{
									color: quizReattemptDetails?.allowReAttempt ? '#d66600' : '#EE0000',
									fontSize: '12px',
								}}
								className="d-flex align-items-center gap-2">
								<Image
									src={toAbsoluteUrl('/media/course-detail-images/information-circle.svg')}
									height="14px"
								/>
								{quizReattemptDetails?.allowReAttempt
									? 'Please reattempt the quiz to continue'
									: 'You Have failed all attempts on quiz'}
							</span>
						);
						break;
				}
			};

			const addExtraFunctions = handleLessonChange
				? { onClick: () => (lesson?.disabledSelection ? null : handleLessonChange(lesson.id)) }
				: {};

			return (
				<div
					className={`d-flex justify-content-between gap-1 p-10 ${
						showYellowBackground ? 'selectedLesson' : ''
					} ${lesson.disabledSelection ? 'disabledSelection' : ''}`}
					{...addExtraFunctions}>
					<div className="d-flex justify-content-start align-items-start mt-1">
						<Image src={toAbsoluteUrl('/media/icons/list-item.svg')} height={20} width={20} />
					</div>
					<div className="d-flex flex-column col-lg-10 text-xl-start text-center">
						<div>{lesson.name}</div>
						{lesson.quizId &&
							[QuizResultStatusEnum.PENDING, QuizResultStatusEnum.FAILED].includes(
								showPendingOrFailedQuizStatus?.status,
							) &&
							renderQuizStatus(showPendingOrFailedQuizStatus)}
					</div>
					<div className="row">
						<div className="offset-1 col-lg-1 d-flex justify-content-start align-items-start">
							{showLockIcon ? (
								showPendingOrFailedQuizStatus?.status === QuizResultStatusEnum.FAILED &&
								!allowReattemptQuiz ? (
									<Image src={toAbsoluteUrl('/media/course-detail-images/failed-quiz.svg')} />
								) : showPendingOrFailedQuizStatus?.status === QuizResultStatusEnum.FAILED &&
								  allowReattemptQuiz ? (
									<Image src={toAbsoluteUrl('/media/course-detail-images/reattempt-quiz.svg')} />
								) : (
									<Image
										src={toAbsoluteUrl(
											`/media/course-detail-images/square-${
												lesson.isLock ? 'lock.svg' : 'unlock.svg'
											}`,
										)}
									/>
								)
							) : (
								<button className="btn type-btn pe-none">{LessonTypeToText[lesson.type]}</button>
							)}
						</div>
					</div>
				</div>
			);
		},
		[currentSelectedLessonId, quizReattemptList],
	);

	return (
		<div className="accordion-body">
			{lessons.map((lesson) => (
				<div key={lesson.id} className="content-list">
					{listItemRenderer(lesson)}
				</div>
			))}
		</div>
	);
};

export default AccordionBody;
