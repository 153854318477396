import React from 'react';
import './loginbutton.css';
import { EHeaderType } from 'types/globalTypes';
import { Login } from 'pages/auth/Login';
import { Register } from 'pages/auth/Register';
import useLoginRegister from 'hooks/useLoginRegister';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const LoginButton = ({ headerType = EHeaderType.WHITE }: { headerType: EHeaderType }) => {
	const {
		toggleLoginOpen,
		handleRegisterClose,
		open,
		openRegister,
		toggleRegisterOpen,
		setOpen,
		setOpenRegister,
	} = useLoginRegister();
	const isSmallScreen = useIsSmallScreen(768);
	return (
		<>
			<button
				onClick={toggleLoginOpen}
				className={`button login-btn ms-3 g_mid me-3 
				${
					isSmallScreen
						? 'black blackBorder login-btn-hover-40'
						: headerType === EHeaderType.WHITE
						? 'black blackBorder login-btn-hover-40'
						: 'white login-btn-hover'
				}}`}>
				Log In
			</button>

			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}

			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={toggleLoginOpen}
				/>
			)}
		</>
	);
};

export default LoginButton;
