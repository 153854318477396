import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RESET_PASSWORD } from "../../components/Courses-Page/core/requests";
import toast from "react-hot-toast";
import * as Yup from "yup";
const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;
const Forgetpassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required("New Password is required")
      .matches(
        passwordRegExp,
        "Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required")
      .matches(
        passwordRegExp,
        "Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter",
      ),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await resetPassword({
          variables: {
            resetForgotPasswordInput: {
              resetPasswordKey: token,
              newPassword: values.newPassword,
              confirmNewPassword: values.confirmPassword,
            },
          },
          onCompleted: () => {
            toast.success("Successfully Reset Password");
          },
          onError: (err) => {
            toast.error(err.message);
          },
        });
      } catch (error) {
        console.error("Error resetting password", error);
      }
    },
  });

  const gotologin = () => {
    navigate("/");
  };

  return (
    <>
      <section className="section">
        <div className="container col-lg-4 mt-4 ">
          <h2 className="text-center mb-4 font-title">Forgot Password</h2>
          <form className="font-subtitle" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className={`form-control ${
                  formik.touched.newPassword && formik.errors.newPassword
                    ? "is-invalid"
                    : ""
                }`}
                id="newPassword"
                placeholder="Enter New Password"
                {...formik.getFieldProps("newPassword")}
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <div className="text-danger mt-1 fs-7">
                  <i className="fa fa-exclamation-circle me-1"></i>
                  {formik.errors.newPassword}
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className={`form-control ${
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? "is-invalid"
                    : ""
                }`}
                id="confirmPassword"
                placeholder="Confirm new password"
                {...formik.getFieldProps("confirmPassword")}
              />
              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <div className="text-danger mt-1 fs-7">
                    <i className="fa fa-exclamation-circle me-1"></i>
                    {formik.errors.confirmPassword}
                  </div>
                )}
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              Reset Password
            </button>
            <button
              type="button"
              className="btn btn-primary btn-block mx-2"
              onClick={gotologin}
            >
              Back to Login
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Forgetpassword;
