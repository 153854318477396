import React from 'react';
import './CourseFAQS.css';
import { FaqsType } from 'components/_v2/CourseDetail/CourseFAQS/types';

const CourseFAQS = ({ faqsData, fullWidth }: { faqsData: FaqsType[]; fullWidth?: boolean }) => {
	return (
		<section className="course-faqs-wrapper">
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-10'}`}>
						<div className="row">
							<div className={fullWidth ? 'col-lg-12' : 'col-lg-8'}>
								<h2 className="faqs-heading lora">FAQ’s</h2>

								<div>
									<div className="accordion" id="accordionFAQExample">
										{(faqsData || []).map((faq, index) => {
											const uniqueKey = `collapse-${faq.id}-${index}`;
											return (
												<div key={faq.id} className="accordion-item">
													<h2 className="accordion-header">
														<button
															className="accordion-button course-faqs-que g_reg"
															type="button"
															data-bs-toggle="collapse"
															data-bs-target={`#${uniqueKey}`}
															aria-expanded="true"
															aria-controls={uniqueKey}>
															{faq.question}
														</button>
													</h2>
													<div
														id={uniqueKey}
														className="accordion-collapse collapse"
														data-bs-parent="#accordionFAQExample">
														<div className="accordion-body course-faqs-ans">{faq.answer}</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseFAQS;
