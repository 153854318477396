import React from "react";
import "./partners.css";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";

const partnerLogos = [
  { src: "/media/partners-images/nsc.png", alt: "NSC" },
  { src: "/media/partners-images/bse.png", alt: "BSE" },
  { src: "/media/partners-images/mcx.png", alt: "MCX" },
  { src: "/media/partners-images/cdsl.png", alt: "CDSL" },
  { src: "/media/partners-images/ncdex.png", alt: "NCDEX" },
];

const Partners = ({ lgColClass = "", mdColClass = "", smColClass = "", title = "Our Trusted Partners", titleClass = "text-center partners-title" }) => {
  const logoElements = partnerLogos.map((partner) => (
    <span key={partner.alt}>
      <img
        src={toAbsoluteUrl(partner.src)}
        alt={partner.alt}
        className="logo-spacing"
      />
    </span>
  ));

  // Repeat logos for seamless looping effect
  const marqueeContent = [...logoElements, ...logoElements];

  return (
    <section>
      <div className="container">
        <div className="row my-5 d-flex justify-content-center">
          <div className={`${lgColClass} ${mdColClass} ${smColClass} p-md-2 p-0 lora`}>
            <h2 className={titleClass}>{title}</h2>
            <div className="marquee my-5">
              <div className="marquee-content my-3">{marqueeContent}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
