import React from 'react';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { useNavigate } from 'react-router-dom';
import { HOME_URL } from 'pages/routing/routePages';
import { CourseEnrollmentObjectType } from '../Course/type';
import CardInformation from 'components/_v2/CommonComponents/CardInformation';
import { CardHeight } from 'components/_v2/CommonComponents/types';
import { useCourseContext } from '../../../context/CourseContext';
import { useUserContext } from '../../../context/UserContext';

const OnGoingCourse = ({
	enrollmentCourses,
}: {
	enrollmentCourses: CourseEnrollmentObjectType[];
}) => {
	const navigate = useNavigate();
	const handleHome = () => {
		localStorage.setItem('activeIndex', '0');
		navigate(HOME_URL);
	};

	const { userCourses } = useCourseContext();
	const { user } = useUserContext();

	if (enrollmentCourses.length === 0) {
		return (
			<>
				<section
					className="d-flex align-items-center justify-content-center"
					style={{ height: '70vh' }}>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="d-flex justify-content-center align-items-center">
									<img
										className="smallImageWidth"
										src={toAbsoluteUrl('/media/nodata/course-nodata.png')}
										alt="Nodata_Found"
									/>
								</div>
								<div className="text-center">
									<h2 className="nodata-title lora">No Courses Here</h2>
									<p className="nodata-label">
										We Will notify you once you have been purchased a Course
									</p>
									<button className="btn btn_goto_course g_mid w-auto" onClick={handleHome}>
										Explore Courses
									</button>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}

	return (
		<div className="row d-flex course-card-section-spacing gy-lg-4 mt-0">
			{enrollmentCourses.map((course) => (
				<CardInformation
					course={course.course}
					disableHoverInteraction={true}
					isUserSpecificContent={true}
					userCourses={userCourses}
					user={user}
					cardHeight={CardHeight.AUTO}
					hasCardOffset
				/>
			))}
		</div>
	);
};

export default OnGoingCourse;
