import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './ContactUs.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import toast from 'react-hot-toast';

const CREATE_CONTACT_US = gql`
  mutation CreateContactUs($input: NewCreateContactUsInput!) {
    newCreateContactUs(createContactUsInput: $input)
  }
`;

const ContactDetail = ({ icon, title, subtitle }) => (
    <div className="row align-items-center justify-content-center my-4">
        <div className="col-2 col-md-1 col-lg-2 text-lg-center">
            <img src={toAbsoluteUrl(icon)} alt={`${title}_Image`} />
        </div>
        <div className="col-10 col-md-11 col-lg-10">
            <div className="info-title">{title}</div>
            <div className="info-subtitle">{subtitle}</div>
        </div>
    </div>
);

const GetInTouch = () => {
    const [createContactUs] = useMutation(CREATE_CONTACT_US);

    // Formik form handling with Yup validation schema
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            contactNo: '',
            subject: '',
            message: '',
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Full Name is required')
                .matches(/^[a-zA-Z\s]*$/, 'Full Name cannot contain numbers or special characters'),
            email: Yup.string()
                .email('Please enter a valid email address')
                .required('Email is required'),
            contactNo: Yup.string()
                .required('Phone Number is required')
                .matches(/^\d{10}$/, 'Phone Number must be exactly 10 digits'), // Ensures exactly 10 digits
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const { name, email, contactNo } = values;
                await createContactUs({
                    variables: {
                        input: { name, email, contactNo },
                    },
                });

                toast.success('Form submitted successfully!', {
                    className: 'g_mid',
                });

                resetForm();
            } catch (error) {
                console.error('Error submitting the form', error);
            }
        },
    });

    return (
        <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="mb-lg-5">
                            <h2 className="about-info-title">Get in touch with us</h2>
                            <p className="about-info-subtitle">
                                If you have business inquiries or other questions, please fill
                                out the following form to contact us. Thank you.
                            </p>
                        </div>

                        {/* Contact Information */}
                        <ContactDetail
                            icon="media/contact-us/c-call.svg"
                            title="Call us"
                            subtitle="097277 50015"
                        />
                        <ContactDetail
                            icon="media/contact-us/c-message.svg"
                            title="Email us"
                            subtitle="support@hogrowth.com"
                        />
                        <ContactDetail
                            icon="media/contact-us/c-location.svg"
                            title="Visit us"
                            subtitle="F-7, 3rd Floor, VIP Plaza, VIP Road, near Shyam Baba Mandir, Vesu, Surat, Gujarat 395007."
                        />
                        <ContactDetail
                            icon="media/contact-us/c-time.svg"
                            title="Hours of Operations"
                            subtitle="Mon - Sat: 09:00AM to 7:00PM IST"
                        />
                    </div>

                    <div className="form-wrapper custom-card-shadowl offset-lg-1 col-lg-7 col-md-12 col-12 mt-md-4 mt-4 mt-lg-0 p-3 p-md-4 p-lg-5">
                        <h2 className="form-title mb-4">Send Us a Message</h2>
                        <form className="contact-form" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="name" className="form-label">
                                        Full Name <span className="color22">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        placeholder="eg: John Doe"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className="error-message">{formik.errors.name}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="email" className="form-label">
                                        Email <span className="color22">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="eg: person@gmail.com"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        required
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="error-message">{formik.errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="contactNo" className="form-label">
                                        Phone Number <span className="color22">*</span>
                                    </label>
                                    <input
                                        className="input-box-style form-control g_reg"
                                        type="text"
                                        name="contactNo"
                                        pattern="[0-9]{10}"
                                        placeholder="ie: 9033245774"
                                        {...formik.getFieldProps('contactNo')}
                                        onInput={(e: React.FormEvent<HTMLInputElement>) => {
                                            const input = e.currentTarget;
                                            input.value = input.value.replace(/[^0-9]/g, '').slice(0, 10);
                                        }}
                                    />
                                    {formik.touched.contactNo && formik.errors.contactNo ? (
                                        <div className="error-message">{formik.errors.contactNo}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="subject" className="form-label">
                                        Subject
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="subject"
                                        name="subject"
                                        placeholder="Enter Subject"
                                        value={formik.values.subject}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="message" className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="message"
                                        name="message"
                                        rows={4}
                                        placeholder="Write Here ..."
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="row mt-lg-3 mb-2 mb-lg-0 mb-md-0">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-submit">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetInTouch;
