import { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FcGoogle } from 'react-icons/fc';
import { Verification } from './Verification';
import * as Yup from 'yup';
import { useGoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik';
import axios from 'axios';
import clsx from 'clsx';
import { STUDENTLOGINWITHSOCIALMEDIA, NEW_STUDENT_REGISTER } from './core/_requests';
import { useAuth } from './core/Auth';
import toast from 'react-hot-toast';
import { gql, useMutation, useSubscription } from '@apollo/client';
import './registration.css';
import { useUserContext } from 'context/UserContext';
import { AUTH_LOCAL_STORAGE_KEY } from './core/AuthHelpers';
import Loading from 'components/_v2/CommonComponents/Loading';

type Props = {
	openRegister: any;
	handleRegisterClose: () => void;
	setOpenRegister: any;
	toggleLoginOpen: () => void;
	toggleRegisterOpen: () => void;
};

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	confirmPassword: '',
	mobileNo: '',
	mobileNoStdCode: '',
};

// eslint-disable-next-line
const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
// eslint-disable-next-line
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;

const registrationSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.required('First name is required'),
	email: Yup.string()
		.email('Please enter valid email address')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	// lastName: Yup.string()
	//   .min(3, "Minimum 3 symbols")
	//   .max(50, "Maximum 50 symbols")
	//   .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
	//   .required("Last name is required"),
	password: Yup.string()
		.required('Password is required')
		.matches(
			passwordRegExp,
			'Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter',
		),
	confirmPassword: Yup.string()
		.required('Password confirmation is required')
		.oneOf([Yup.ref('password')], "Password and Re-Enter Password didn't match"),
	// mobileNo: Yup.string()
	//   .required("Mobile number is required")
	//   .matches(phoneRegExp, "Mobile number is not valid"),
});

export const Register: FC<Props> = ({
	openRegister,
	handleRegisterClose,
	setOpenRegister,
	toggleLoginOpen,
	toggleRegisterOpen,
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [openVerification, setOpenVerification] = useState(false);
	const { refetchLocalStorageTokenDetails, setUser } = useUserContext();
	const [studentSignInWithSocialAccount] = useMutation(STUDENTLOGINWITHSOCIALMEDIA);
	const handleVerificationClose = () => {
		setOpenVerification(false);
	};
	const [loading, setLoading] = useState(false);
	const [studentRegister] = useMutation(NEW_STUDENT_REGISTER);
	const [showAccountSelection, setShowAccountSelection] = useState(true);

	const loginGoogle = useGoogleLogin({
		onSuccess: async (codeResponse: any) => {
			try {
				if (showAccountSelection) {
					handleRegisterClose1();
				}
				setUser(codeResponse);
				const userInfoResponse = await axios.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
					{
						headers: {
							Authorization: `Bearer ${codeResponse.access_token}`,
							Accept: 'application/json',
						},
					},
				);

				await studentSignInWithSocialAccount({
					variables: {
						email: userInfoResponse.data.email,
						name: userInfoResponse.data.name,
						signinType: 'GOOGLE',
					},
					onCompleted: (data: any) => {
						localStorage.setItem(
							AUTH_LOCAL_STORAGE_KEY,
							JSON.stringify(data?.studentSignInWithSocialAccount),
						);
						refetchLocalStorageTokenDetails();
						toast.success('Successfully signed in with the correct credentials.', {
							className: 'g_mid',
						});
					},
					onError: (err: any) => {
						toast.error(err.message);
					},
				});
			} catch (error) {
				console.error(error);
			}
		},
		onError: (error: any) => {
			toast.error(error.message);
			toast.error('Please Select proper account for the google signin');
		},
	});
	const handleRegisterClose1 = () => {
		setOpenRegister(false);
	};
	// useEffect(() => {
	//   if (user.length !== 0) {
	//     axios
	//       .get(
	//         `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
	//         {
	//           headers: {
	//             Authorization: `Bearer ${user.access_token}`,
	//             Accept: "application/json",
	//           },
	//         }
	//       )
	//       .then(async (res: any) => {
	//         const googleLogin = await studentSignInWithSocialAccount({
	//           variables: {
	//             email: res.data.email,
	//             name: res.data.name,
	//             signinType: "GOOGLE"
	//           }
	//         }
	//         );
	//         if (googleLogin) {
	//           if (googleLogin.data.data) {
	//             const loggedInUserData = googleLogin?.data?.data?.studentSignInWithSocialAccount;
	//             if (loggedInUserData?.access_token !== "") {
	//               saveAuth(loggedInUserData);
	//               setCurrentUser(loggedInUserData?.user);
	//               setOpenRegister(false);
	//               toast.success("Google Registraion Successfully. You can explore all courses now....", {
	//                 style: {
	//                   padding: "16px",
	//                 },
	//               });
	//             } else {
	//               setStatus(loggedInUserData?.data?.errors[0].message);
	//               if (loggedInUserData?.access_token === "") {
	//                 //   toast.error(loggedInUserData.message, {
	//                 //     style: {
	//                 //       padding: "16px",
	//                 //     },
	//                 //   });
	//               }
	//             }
	//           } else {
	//             setStatus(googleLogin.data.errors[0].message);
	//           }
	//         }
	//       })
	//       .catch((err: any) => console.log(err));
	//   }
	//   // eslint-disable-next-line
	// }, [user]);

	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			try {
				const { data } = await studentRegister({
					variables: {
						email: values.email,
						password: values.password,
						confirmPassword: values.confirmPassword,
						name: values.firstName,
					},
				});
				if (data?.newStudentRegister?.is_success) {
					localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(data.newStudentRegister));
					refetchLocalStorageTokenDetails();
					setLoading(false);
					setOpenVerification(true);
				} else {
					toast.error(data?.newStudentRegister?.message);
					setSubmitting(false);
					setLoading(false);
				}
			} catch (error: any) {
				toast.error(error.message);
				setLoading(false);
			}
		},
	});
	const handleResendOTP = async () => {
		try {
			const { data } = await studentRegister({
				variables: {
					email: formik.values.email,
					password: formik.values.password,
					confirmPassword: formik.values.confirmPassword,
					name: formik.values.firstName,
				},
				onCompleted: (data) => {
					localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(data.newStudentRegister));
				},
			});

			if (data?.newStudentRegister) {
				toast.success('OTP has been resent.');
			}
		} catch (error: any) {
			toast.error('Failed to resend OTP. Please try again.');
		}
	};
	return (
		<>
			{openRegister === true && (
				<>
					<Modal
						centered
						show={openRegister}
						onHide={showAccountSelection ? handleRegisterClose1 : undefined}>
						<Modal.Header className="header-top-layout">
							<Modal.Title className="border-0">
								<div className="row">
									<div className="col-md-12 lora login-title">Sign Up</div>
									<div className="login-new-label g_reg">
										Already have an Account? &nbsp;
										<span
											className="login-new-label-span g_reg cursor-pointer"
											onClick={() => {
												toggleLoginOpen();
											}}>
											Sign In
										</span>
									</div>
								</div>
							</Modal.Title>
							<div
								className="btn btn-icon btn-sm btn-active-icon-primary close-btn-style g_reg"
								onClick={handleRegisterClose1}>
								<i className="fa fa-close"></i>
							</div>
						</Modal.Header>
						<Modal.Body className="body-layout">
							<form onSubmit={formik.handleSubmit} noValidate>
								<div className="row">
									{formik.status ? (
										<div className="col-12 mb-3">
											<div className="mb-lg-15 alert alert-danger">
												<div className="alert-text font-weight-bold">{formik.status}</div>
											</div>
										</div>
									) : (
										<></>
									)}
									<div className="col-12">
										<label className="label-style g_reg">
											Full Name <span style={{ color: 'red' }}>*</span>
										</label>
										<input
											placeholder="Enter Full Name"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('firstName')}
											className={clsx(
												'form-control input-inner-style g_reg',
												{
													'is-invalid': formik.touched.firstName && formik.errors.firstName,
												},
												{
													'is-valid': formik.touched.firstName && !formik.errors.firstName,
												},
											)}
										/>
										{formik.touched.firstName && formik.errors.firstName && (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{formik.errors.firstName}
											</div>
										)}
									</div>
									<div className="col-12 mt-3">
										<label className="label-style g_reg">
											Email <span style={{ color: 'red' }}>*</span>
										</label>
										<input
											placeholder="Enter Email Address"
											{...formik.getFieldProps('email')}
											className={clsx(
												'form-control input-inner-style g_reg',
												{
													'is-invalid': formik.touched.email && formik.errors.email,
												},
												{
													'is-valid': formik.touched.email && !formik.errors.email,
												},
											)}
											type="email"
											name="email"
											autoComplete="off"
										/>
										{formik.touched.email && formik.errors.email && (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{formik.errors.email}
											</div>
										)}
									</div>
									<div className="col-12 mt-3">
										<label className="label-style g_reg">
											Create Password <span style={{ color: 'red' }}>*</span>
										</label>
										<div className="input-group">
											<input
												type={showPassword ? 'text' : 'password'}
												placeholder="Enter Create Password"
												autoComplete="off"
												{...formik.getFieldProps('password')}
												className={clsx(
													'form-control input-inner-style g_reg',
													{
														'is-invalid': formik.touched.password && formik.errors.password,
													},
													{
														'is-valid': formik.touched.password && !formik.errors.password,
													},
												)}
											/>
											<button
												type="button"
												className={clsx('btn position-absolute eye-icon eye-icon-outline', {
													'pe-4': formik.touched.password && !formik.errors.password,
												})}
												onClick={() => setShowPassword(!showPassword)}>
												<i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
											</button>
										</div>

										{formik.touched.password && formik.errors.password && (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{formik.errors.password}
											</div>
										)}
									</div>
									<div className="col-12 mt-3">
										<label className="label-style g_reg">
											Confirm Password <span style={{ color: 'red' }}>*</span>
										</label>
										<div className="input-group">
											<input
												type={showConfirmPassword ? 'text' : 'password'}
												placeholder="Enter Confirm Password"
												autoComplete="off"
												{...formik.getFieldProps('confirmPassword')}
												className={clsx(
													'form-control input-inner-style g_reg',
													{
														'is-invalid':
															formik.touched.confirmPassword && formik.errors.confirmPassword,
													},
													{
														'is-valid':
															formik.touched.confirmPassword && !formik.errors.confirmPassword,
													},
												)}
											/>
											<button
												type="button"
												className={clsx('btn position-absolute eye-icon eye-icon-outline', {
													'pe-4': formik.touched.confirmPassword && !formik.errors.confirmPassword,
												})}
												onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
												<i className={`fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
											</button>
										</div>

										{formik.touched.confirmPassword && formik.errors.confirmPassword && (
											<div className="text-danger fs-7 mt-2">
												<i className="fa fa-exclamation-circle me-1"></i>
												{formik.errors.confirmPassword}
											</div>
										)}
									</div>
									<div className="col-12 btn-registyle">
										<button
											type="submit"
											className="btn bg-color2 w-100 new-btn-layout mb-3"
											// disabled={formik.isSubmitting || !formik.isValid}
										>
											{!loading && <span className="g_mid">Continue</span>}
											{loading && (
												<span className="d-flex w-100 justify-content-center gap-2">
													<Loading width={50} />
													Please wait...
												</span>
											)}
										</button>
									</div>
									<div className="divider">Or</div>
									<div className="col-12 mt-2">
										<button
											type="button"
											className="btn cwg-regi-style w-100 g_mid"
											onClick={() => loginGoogle()}>
											<FcGoogle className="me-2 fs-2" />
											Continue with Google
										</button>
									</div>
								</div>
							</form>
						</Modal.Body>
					</Modal>
				</>
			)}
			{openVerification === true && (
				<Verification
					handleRegisterClose1={handleRegisterClose1}
					openVerification={openVerification}
					handleVerificationClose={handleVerificationClose}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					email={formik.values.email}
					handleResendOTP={handleResendOTP}
				/>
			)}
		</>
	);
};
