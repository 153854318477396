import React from 'react';
import './CourseContent.css';
import AccordionBody from 'components/_v2/CommonComponents/AccordionBody';
import AccordionHeader from 'components/_v2/CommonComponents/AccordionHeader';
import { CourseSections } from 'components/_v2/Course/type';
import { Image } from 'react-bootstrap';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { QuizReattemptDetails } from 'components/_v2/ViewCourseData/LessonTypesComponents/Quiz/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const CourseContent = ({
	sections,
	fullScale = false,
	showLockIcon = false,
	showCloseIcon = false,
	onCloseClick,
	handleLessonChange,
	currentSelectedLessonId,
	quizReattemptList,
}: {
	sections: CourseSections[];
	fullScale?: boolean;
	showLockIcon?: boolean;
	showCloseIcon?: boolean;
	onCloseClick?: () => void;
	handleLessonChange?: (lessonId: string) => void;
	currentSelectedLessonId?: string;
	quizReattemptList?: QuizReattemptDetails[];
}) => {
	const isSmallScreen = useIsSmallScreen();
	return (
		<section className={fullScale ? '' : isSmallScreen ? 'pt-3' : 'course-content-wrapper p-3'}>
			<div className="container">
				<div className={`row ${fullScale ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className="col-lg-12">
						<div className="row">
							<div className={fullScale ? 'col-lg-12' : 'col-lg-8'}>
								<h2
									className={`course-content-title-${
										fullScale ? 'full-scale d-flex justify-content-between' : 'normal-scale'
									} lora`}>
									Course Content
									{showCloseIcon && (
										<Image
											onClick={onCloseClick}
											role="button"
											src={toAbsoluteUrl('/media/course-detail-images/cancel.svg')}
										/>
									)}
								</h2>
								<div>
									<div className="accordion" id="accordionExample">
										{sections?.map((individualSection, index) => {
											const uniqueKey = `collapse${index}`;
											const isCurrentSectionMarkedAsSelected = individualSection.lessons.some(
												(lesson) => lesson.id === currentSelectedLessonId,
											);
											return (
												<div key={uniqueKey} className="accordion-item">
													<AccordionHeader
														heading={individualSection.name}
														uniqueKey={uniqueKey}
														fullScale={fullScale}
														isSectionSelected={isCurrentSectionMarkedAsSelected}
													/>
													<div
														id={uniqueKey}
														className={`accordion-collapse collapse ${
															isCurrentSectionMarkedAsSelected ? 'show' : ''
														}`}
														data-bs-parent="#accordionExample">
														<AccordionBody
															lessons={individualSection.lessons}
															showLockIcon={showLockIcon}
															currentSelectedLessonId={currentSelectedLessonId}
															handleLessonChange={handleLessonChange}
															quizReattemptList={quizReattemptList}
														/>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CourseContent;
