import React from "react";
import "./privacypolicy.css";
import TopTitleSection from "../CommonComponents/TopTitleSection";

const PNP = () => {
  return (
    <section className="privacy-policy-wrapper">
      <div className="container-fluid info-layout">
        <div className="row mt-5">
          <TopTitleSection
            title="Privacy Policy"
            description="Explore the courses that our learners love the most. Tried, tested, and highly recommended for your success."
          />
        </div>
      </div>
      <div className="col-lg-10 layout-set my-3">
        <div className="terms-container text-black p-4">
          <p className="">
            <h2 className="desc-title lora">Introduction</h2>
          </p>
          <p className="g_reg">
            Welcome to HoGrowth Academy, Best Stock Market Institute. We value
            your privacy and are committed to protecting your personal
            information. This Privacy Policy outlines how we collect, use,
            disclose, and protect your information when you visit our website
            hogrowth.com, or use our services.
          </p>
          <h2 className="desc-title mt-4 mb-2 lora">Information We Collect</h2>
          <p className="g_reg">
            We collect various types of information to provide and improve our
            services to you:
          </p>
          <ol className="g_reg">
            <li>
              <strong className="g_semi">Personal Information:</strong> When you
              register on our Site, subscribe to our newsletter, or enroll in
              our courses, we may collect personal information such as your
              name, email address, phone number, and payment information.
            </li>
            <li>
              <strong className="g_semi">Usage Data:</strong> We automatically
              collect information about how you access and use our Site,
              including your IP address, browser type, operating system,
              referral source, pages viewed, and the dates/times of your visits.
            </li>
            <li>
              <strong className="g_semi">
                Cookies and Tracking Technologies:
              </strong>{" "}
              We use cookies and similar tracking technologies to track activity
              on our Site and hold certain information. You can instruct your
              browser to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be
              able to use some parts of our Site.
            </li>
          </ol>
          <h2 className="desc-title lora mt-4 mb-2">
            How We Use Your Information
          </h2>
          <p className="g_reg">
            We use the collected information for various purposes:
          </p>
          <ul className="list-disc list-inside">
            <li>To provide, operate, and maintain our Site and services.</li>
            <li>To improve, personalize, and expand our Site and services.</li>
            <li>
              To communicate with you, including customer service, updates, and
              promotional content.
            </li>
            <li>To process your transactions and manage your orders.</li>
            <li>
              To send you newsletters, marketing or promotional materials, and
              other information that may be of interest to you.
            </li>
            <li>
              To monitor and analyze usage and trends to improve your experience
              on our Site.
            </li>
            <li>To detect, prevent, and address technical issues.</li>
          </ul>
          <h2 className="desc-title lora mt-4 mb-2">
            Disclosure of Your Information
          </h2>
          <p className="g_reg">
            We may share your information in the following situations:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong className="g_semi">With Service Providers:</strong> We may
              share your information with third-party service providers to
              perform functions and provide services to us (e.g., payment
              processing, data analysis, email delivery, hosting services, and
              customer service).
            </li>
            <li>
              <strong className="g_semi">For Business Transfers:</strong> In the
              event of a merger, acquisition, or sale of all or a portion of our
              assets, your personal information may be transferred to the
              acquiring company.
            </li>
            <li>
              <strong className="g_semi">With Your Consent:</strong> We may
              disclose your information for any other purpose with your consent.
            </li>
            <li>
              <strong className="g_semi">Legal Requirements:</strong> We may
              disclose your information if required by law or in response to
              valid requests by public authorities (e.g., a court or a
              government agency).
            </li>
          </ul>
          <h2 className="desc-title lora mt-4 mb-2">
            Security of Your Information
          </h2>
          <p className="g_reg">
            We take reasonable measures to protect your personal information
            from loss, theft, misuse, unauthorized access, disclosure,
            alteration, and destruction. However, no method of transmission over
            the Internet or electronic storage is 100% secure, so we cannot
            guarantee absolute security.
          </p>
          <h2 className="desc-title lora mt-4 mb-2">Your Rights and Choices</h2>
          <p className="g_reg">
            You have certain rights regarding your personal information,
            including:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <strong className="g_semi">Access:</strong> You can request a copy
              of the personal information we hold about you.
            </li>
            <li>
              <strong className="g_semi">Correction:</strong> You can request
              that we correct any inaccurate or incomplete information.
            </li>
            <li>
              <strong className="g_semi">Deletion:</strong> You can request that
              we delete your personal information, subject to certain legal
              obligations.
            </li>
            <li>
              <strong className="g_semi">Opt-Out:</strong> You can opt out of
              receiving marketing communications from us by following the
              unsubscribe instructions provided in those communications.
            </li>
          </ul>
          <h2 className="desc-title lora mt-4 mb-2">Third-Party Links</h2>
          <p className="g_reg">
            Our Site may contain links to third-party websites. We are not
            responsible for the privacy practices or content of those sites. We
            encourage you to review the privacy policies of any third-party
            sites you visit.
          </p>
          <h2 className="desc-title lora mt-4 mb-2">
            Changes to This Privacy Policy
          </h2>
          <p className="g_reg">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>
          <h2 className="desc-title lora mt-4 mb-2">Contact Us</h2>
          <p className="g_reg">
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p className="g_reg">
            HoGrowth Academy,
            <br />
            F-7, 3rd Floor, VIP Plaza, Vesu, Surat- 395-007
            <br />
            info@hogrowth.com
            <br />
            +91-97277-50015
          </p>
          <p className="g_reg">
            <strong className="g_semi">Effective Date:</strong> 15/02/2024
          </p>
          <p className="g_reg">
            Thank you for choosing HoGrowth Academy. We are committed to
            protecting your privacy and ensuring a secure experience on our
            Site.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PNP;
