import React from 'react';
import './InstructorProfile.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { Mentor } from 'components/_v2/Course/type';
import useIsSmallScreen from 'hooks/useIsSmallScreen';

const InstructorProfile = ({
	instructors,
	fullWidth = false,
}: {
	instructors: { mentor: Mentor }[];
	fullWidth?: boolean;
}) => {
	const isSmallScreen = useIsSmallScreen();
	return (
		<section
			className={fullWidth ? '' : isSmallScreen ? 'pt-3' : 'instructor-profile-wrapper px-3'}>
			<div className="container">
				<div className={`row ${fullWidth ? '' : 'px-lg-5 px-sm-3 px-2'}`}>
					<div className={`${fullWidth ? 'col-lg-12' : 'col-lg-10'}`}>
						<div className="row">
							<div className={fullWidth ? 'col-lg-12' : 'col-lg-9'}>
								<div>
									<h2 className="instructor-heading lora">Instructor Profile</h2>

									{instructors.map((instructor) => {
										const mentorDetails = instructor.mentor;
										const mentorFullName = mentorDetails.firstName + ' ' + mentorDetails.lastName;
										return (
											<div
												key={mentorDetails.id + mentorFullName}
												className="d-lg-flex align-items-center py-2">
												<div
													className={
														isSmallScreen
															? 'd-flex w-100 justify-content-center align-items-center'
															: ''
													}>
													<img
														src={toAbsoluteUrl(
															mentorDetails.userProfile?.[0]?.imageUrl ??
																'/media/instructor-images/instructor_profile.png',
														)}
														alt="Instructor_Image"
														style={{ borderRadius: '50%', objectFit: 'contain', width: '250px' }}
														className="instructor-profile-custom-style"
													/>
												</div>
												<div className="instructor-desc-layout">
													<div
														className={
															isSmallScreen
																? 'd-flex w-100 justify-content-center align-items-center flex-column pt-3'
																: ''
														}>
														<h2 className="instructor-name g_mid">{mentorFullName}</h2>
														<p className="instructor-expr g_reg">
															{mentorDetails.userProfile?.[0]?.qualification}
														</p>
													</div>
													<div>
														<p className="instructor-full-desc g_reg text-justify">
															{mentorDetails.userProfile?.[0]?.description}
														</p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default InstructorProfile;
