import { Link } from 'react-router-dom';
import './Breadcrumbs.css';
import { BreadcrumbDataProps } from 'types/globalTypes';

const Breadcrumbs = ({ breadcrumbData }: { breadcrumbData: BreadcrumbDataProps[] }) => {
	return (
		<div className="d-flex align-items-center gap-sm-2 ps-3 ps-sm-0 mb-4 flex-wrap">
			{breadcrumbData.map((breadcrumb, index) => {
				return (
					<div key={index} className="d-flex g_reg gap-2">
						{breadcrumb.href ? (
							<Link
								to={breadcrumb.href}
								className={index + 1 != breadcrumbData.length ? 'link-breadcrumb' : 'black'}>
								{breadcrumb.name}
							</Link>
						) : (
							<span className="breadcrumbs-title">{breadcrumb.name}</span>
						)}
						{index + 1 != breadcrumbData.length ? '<' : ''}
					</div>
				);
			})}
		</div>
	);
};

export default Breadcrumbs;
