import React, { useState } from 'react';

const useLoginRegister = () => {
	const [open, setOpen] = useState(false);
	const [openRegister, setOpenRegister] = useState(false);

	const handleRegisterClose = () => {
		setOpenRegister(false);
	};

	const toggleRegisterOpen = () => {
		setOpenRegister(true);
		setOpen(false);
	};

	const toggleLoginOpen = () => {
		setOpen(true);
		setOpenRegister(false);
	};

	return {
		open,
		openRegister,
		handleRegisterClose,
		toggleRegisterOpen,
		toggleLoginOpen,
		setOpen,
		setOpenRegister,
	};
};

export default useLoginRegister;
