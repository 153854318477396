import React, { useState } from 'react';
import './signupbutton.css';
import { Register } from 'pages/auth/Register';
import { Login } from 'pages/auth/Login';
import useLoginRegister from 'hooks/useLoginRegister';

const SignupButton = () => {
	const {
		toggleLoginOpen,
		handleRegisterClose,
		open,
		openRegister,
		toggleRegisterOpen,
		setOpen,
		setOpenRegister,
	} = useLoginRegister();

	return (
		<>
			<button onClick={toggleRegisterOpen} className="button signup-btn signup-btn-hover g_mid">
				Sign Up
			</button>

			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}

			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={toggleLoginOpen}
				/>
			)}
		</>
	);
};

export default SignupButton;
