import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../assets/helpers/AssetHelpers";
import "./Sidebar.css";
import { useUserContext } from "context/UserContext";
import { HOME_URL, MY_COURSE_URL, MY_PROFILE_URL, PAYMENT_HISTORY_URL } from "../routing/routePages";

type SidebarItem = {
  label: string;
  icon: string;
  iconActive?: string;
  link: string;
};

const Sidebar: React.FC = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  useEffect(() => {
    const storedActiveIndex = localStorage.getItem("activeIndex");
    if (storedActiveIndex !== null) {
      setActiveIndex(parseInt(storedActiveIndex));
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items: SidebarItem[] = [
    {
      label: "Home",
      icon: "home",
      iconActive: "home",
      link: HOME_URL,
    },
    {
      label: "My Learnings",
      icon: "book",
      iconActive: "book",
      link: MY_COURSE_URL,
    },
    {
      label: "Payment History",
      icon: "clock",
      iconActive: "clock",
      link: PAYMENT_HISTORY_URL,
    },
    {
      label: "My Profile",
      icon: "user",
      iconActive: "user",
      link: MY_PROFILE_URL,
    },
  ];
  useEffect(() => {
    const storedActiveIndex = localStorage.getItem("activeIndex");
    if (storedActiveIndex !== null) {
      setActiveIndex(parseInt(storedActiveIndex));
    }
    const currentPath = location.pathname;
    const currentIndex = items.findIndex((item) => item.link === currentPath);
    setActiveIndex(currentIndex);
  }, [location.pathname, items]);

  const handleItemClick = (index: number, link: string) => {
    setActiveIndex(index);
    localStorage.setItem("activeIndex", index.toString()); // Store activeIndex in localStorage
    navigate(link);
  };

  return (
    <div className="bg-transparent col-lg-3 col-xxl-2 ps-0 overflow-x-auto">
      <ul className="sidebar-nav mt-5 sidebar-mobile-view">
        {items.map((item, index) => (
          <li key={index} className="sidebar-nav-item my-1">
            <a
              href={item.link}
              className={`sidebar-nav-link g_reg ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                handleItemClick(index, item.link);
              }}
            >
              <img
                src={toAbsoluteUrl(
                  activeIndex === index
                    ? `/media/sidebar/${item.iconActive}.png`
                    : `/media/sidebar/${item.icon}.png`,
                )}
                alt={item.label}
                className="me-2 my-1 mb-2 d-none d-lg-inline-block"
              />
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
