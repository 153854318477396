import React from "react";
import "./howitwork.css";
import { toAbsoluteUrl } from "../../../assets/helpers/AssetHelpers";

const HowItWork = () => {
  return (
    <section className="how-it-work-wrapper">
      <div className="container">
        <div className="row">

          <div className="col-lg-10 col-md-10 col-11 layout-set">
            <div className="text-center">
              <h2 className="steps-title lora">
                How it Work in 3 Simple Steps
              </h2>
              <p className="steps-subtitle g_reg py-2">
                Unlock your potential with our straightforward process. Browse
                our courses, select your level, and begin your journey. It's
                that easy to start your path to success with us.
              </p>
            </div>

            <div className="row how-work-card-wrapper">
              <div className="col-lg-12 col-md-12 col-12 d-flex">
                <div className="row">

                  <div className="col-lg-3 col-md-6 col-6">
                    <div className="col-lg-11 col-md-11 col-11 image-card image-card-1">
                      <img
                        src={toAbsoluteUrl("/media/howwork-images/h4.png")}
                        alt="Four"
                        className="image-layout"
                      />
                    </div>

                    <div className="col-lg-11 col-md-11 col-11 my-4 image-card image-card-3">
                      <img
                        src={toAbsoluteUrl("/media/howwork-images/h3.png")}
                        alt="Three_Image"
                        className="image-layout"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 my-3">
                    <div className="col-lg-11 col-md-11 col-11 mt-4 image-card image-card-3">
                      <img
                        src={toAbsoluteUrl("/media/howwork-images/h2.png")}
                        alt="Second"
                        className="image-layout"
                      />
                    </div>

                    <div className="col-lg-11 col-md-11 col-11 my-4 image-card image-card-1">
                      <img
                        src={toAbsoluteUrl("/media/howwork-images/h1.png")}
                        alt="First_Image"
                        className="image-layout"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-12 desc-layout">

                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 col-md-12 col-12">
                      <div className="card-custom-shadow simple-step-category">

                        <div >
                          <img
                            src={toAbsoluteUrl("/media/howwork-images/d1.png")}
                            alt="D1"
                            className=""
                          />
                        </div>

                        <div className="p-2">
                          <h2 className="how-desc-title lora">
                            Browse Our Courses
                          </h2>
                          <p className="how-desc-subtitle g_reg">
                            We believe in active learning. Our courses include
                            interactive sessions, hands-on projects, and
                            real-time feedback to ensure you not only learn but
                            also apply the knowledge effectively.
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-1 bg-success"></div> */}
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-10">
                        <div className="card-custom-shadow my-5 simple-step-category">
                          <div>
                            <img
                              src={toAbsoluteUrl(
                                "/media/howwork-images/d2.png",
                              )}
                              alt="D2"
                              className=""
                            />
                          </div>
                          <div className="p-2">
                            <h2 className="how-desc-title lora">
                              Buy Safely and Quickly
                            </h2>
                            <p className="how-desc-subtitle g_reg">
                              We believe in active learning. Our courses include
                              interactive sessions, hands-on projects, and
                              real-time feedback to ensure you not only learn
                              but also apply the knowledge effectively.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-1"></div>
                      <div className="col-lg-10">
                        <div className="card-custom-shadow simple-step-category">
                          <div>
                            <img
                              src={toAbsoluteUrl(
                                "/media/howwork-images/d3.png",
                              )}
                              alt="D3"
                              className=""
                            />
                          </div>
                          <div className="p-2">
                            <h2 className="how-desc-title lora">
                              Get Learning Right Away
                            </h2>
                            <p className="how-desc-subtitle g_reg">
                              We believe in active learning. Our courses include
                              interactive sessions, hands-on projects, and
                              real-time feedback to ensure you not only learn
                              but also apply the knowledge effectively.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWork;
