import React from "react";

const AccordionHeader = ({
  heading,
  uniqueKey,
  fullScale,
  isSectionSelected,
}: {
  heading: string;
  uniqueKey: string;
  fullScale?: boolean;
  isSectionSelected?: boolean;
}) => {
  return (
    <h2 className="accordion-header">
      <button
        className={`accordion-button g_mid ac-heading-${
          fullScale ? "full" : "normal"
        } ${isSectionSelected ? "" : "collapsed"} `}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={"#" + uniqueKey}
        aria-expanded={isSectionSelected ? "true" : "false"}
        aria-controls={uniqueKey}
      >
        {heading}
      </button>
    </h2>
  );
};

export default AccordionHeader;
