import React, { useRef, useEffect, useCallback, useState } from 'react';
import './DetailHeroSection.css';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { RUPEE_SYMBOL } from 'utils/consts';
import { Course, CourseCostType, Mentor } from 'components/_v2/Course/type';
import StarRating from './StarRatings';
import { useUserContext } from 'context/UserContext';
import { useNavigate } from 'react-router-dom';
import { getCoursePaymentSubUrl, getViewCourseWithSearchSlug } from 'pages/routing/routePages';
import { useCourseContext } from 'context/CourseContext';
import useLoginRegister from 'hooks/useLoginRegister';
import moment from 'moment';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { Login } from '../../../../pages/auth/Login';
import { Register } from '../../../../pages/auth/Register';

const DetailHeroSection = ({ course }: { course: Course }) => {
	const { user } = useUserContext();
	const isSmallScreen = useIsSmallScreen(992);
	const navigate = useNavigate();
	const {
		toggleLoginOpen,
		handleRegisterClose,
		open,
		openRegister,
		toggleRegisterOpen,
		setOpen,
		setOpenRegister,
	} = useLoginRegister();
	const { userCourses } = useCourseContext();

	const handleEnrollPageReroute = useCallback(() => {
		if (user) {
			navigate(getCoursePaymentSubUrl(course.slug));
		} else {
			setOpenRegister(true);
		}
	}, [course.slug, navigate, user, openRegister]);

	const isCourseSubscribed = userCourses.some((userCourse) => userCourse.id === course.id);

	const handleContinueLearning = useCallback(() => {
		navigate(getViewCourseWithSearchSlug(course.slug));
	}, [course]);

	const mentorRenderer = useCallback((mentor: Mentor) => {
		const mentorImageUrl = mentor?.userProfile?.[0]?.imageUrl;
		const mentorFullName = mentor.firstName + ' ' + mentor.lastName;

		return (
			<div className="d-flex align-items-center mb-2 gap-2">
				<img
					src={toAbsoluteUrl(mentorImageUrl ?? '/media/detail-hero-section/detail-dp.png')}
					width={75}
					height={75}
					style={{ borderRadius: '50%' }}
					alt="Detail_profile_Image"
				/>
				<p className="dp-text g_mid ms-3 mt-3">{mentorFullName}</p> &nbsp; &nbsp;
			</div>
		);
	}, []);

	return (
		<>
			{!isSmallScreen && (
				<section
					style={{
						backgroundImage: toAbsoluteUrl('/media/background/detail-bg.png'),
						minHeight: '60vh',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						padding: '20px',
						backgroundColor: '#1A1A1A',
						color: 'white',
					}}
					className="px-3">
					<div className="h-100 container px-0">
						<div className="row h-100 align-items-center">
							<div className="col-lg-7 courseDetailsMediaQuery align-content-center offset-1">
								<h1 className="detail-heading white lora">{course.name}</h1>
								<p className="detail-label g_reg">{course.shortDescription}</p>
								<div className="d-flex">
									{course.mentors.map((mentor) => mentorRenderer(mentor.mentor))}
									<div className="ms-5 d-flex align-items-center">
										<span className="fs-5 pe-1">
											<StarRating rating={4.5} />
										</span>
										<span className="g_mid review-number-detail mb-2">({course.ratingCounts})</span>
									</div>
								</div>
								<div className="d-flex align-items-center pt-3">
									<button
										className="button enroll-btn enroll-btn-hover g_semi"
										onClick={isCourseSubscribed ? handleContinueLearning : handleEnrollPageReroute}>
										{isCourseSubscribed ? 'Keep Learning' : 'Enroll Now'}
									</button>
									{!isCourseSubscribed &&
										(course.costType === CourseCostType.PAID ? (
											<div className="ms-3 d-flex align-items-center">
												<span className="g_semi detail-discount-price">
													{RUPEE_SYMBOL}
													{course.sellPrice}/-
												</span>
												<span className="g_reg detail-actual-price ms-3 mt-1">
													<del>
														{RUPEE_SYMBOL}
														{course.amount}/-
													</del>
												</span>
											</div>
										) : (
											<div className="mx-3">
												<span className="g_semi detail-discount-price white lora">
													{CourseCostType.FREE}
												</span>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</section>
			)}

			{isSmallScreen && (
				<div className="drawer-align spacingHeaderTop">
					{course?.previewVideo && (
						<video className="w-100 align-self-center" controls controlsList="nodownload">
							<source src={course.previewVideo} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					)}
					<div className="p-lg-5 p-sm-3 p-2">
						<h1 className="detail-heading black lora pb-2">{course.name}</h1>
						<p className="detail-label g_reg">{course.shortDescription}</p>

						<div className="d-flex justify-content-between flex-wrap pt-5">
							{course.mentors.map((mentor) => mentorRenderer(mentor.mentor))}
							<div className="ms-lg-5 d-flex align-items-center align-self-center">
								<span className="fs-5 pe-1">
									<StarRating rating={4.5} />
								</span>
								<span className="g_mid review-number-detail mb-2">({course.ratingCounts})</span>
							</div>
						</div>
						<div className="card-body pt-4">
							{course?.courseSettings?.dateVisible &&
								course?.startDatetime &&
								course?.endDatetime && (
									<div className="d-flex align-items-center card-inner-layout">
										<div>
											<img
												src={toAbsoluteUrl('/media/detail-hero-section/cdate.svg')}
												alt="Date"
												className="between-space"
											/>
										</div>
										<div>
											<span className="card-sticky-content g_reg">
												{moment(course.startDatetime).format('DD MMMM YYYY')} -{' '}
												{moment(course.endDatetime).format('DD MMMM YYYY')}
											</span>
										</div>
									</div>
								)}

							{course?.courseSettings?.timeVisible &&
								course?.startDatetime &&
								course?.endDatetime && (
									<div className="d-flex align-items-center card-inner-layout">
										<div>
											<img
												src={toAbsoluteUrl('/media/detail-hero-section/ctime.svg')}
												alt="Time"
												className="between-space"
											/>
										</div>
										<div>
											<span className="card-sticky-content g_reg">
												{moment(course.startDatetime).format('hh a')} -{' '}
												{moment(course.endDatetime).format('hh a')}
											</span>
										</div>
									</div>
								)}

							{course?.courseSettings?.languageVisible && course?.courseLanguages.length && (
								<div className="d-flex align-items-center card-inner-layout">
									<div>
										<img
											src={toAbsoluteUrl('/media/detail-hero-section/clanguage.svg')}
											alt="Language"
											className="between-space"
										/>
									</div>
									<div>
										<span className="card-sticky-content g_reg">
											{course.courseLanguages.map((language, index) => (
												<span key={index}>
													{language.name}
													{index < course.courseLanguages.length - 1 && ', '}
												</span>
											))}
										</span>
									</div>
								</div>
							)}

							{course?.courseSettings?.levelVisible && course?.courseLevel?.name && (
								<div className="d-flex align-items-center card-inner-layout">
									<div>
										<img
											src={toAbsoluteUrl('/media/detail-hero-section/clevel.svg')}
											alt="Level"
											className="between-space"
										/>
									</div>
									<div>
										<span className="card-sticky-content g_reg">{course.courseLevel.name}</span>
									</div>
								</div>
							)}

							<div className="d-flex align-items-center card-inner-layout">
								<div>
									<img
										src={toAbsoluteUrl(
											'/media/detail-hero-section/' +
												(course?.courseMode?.name === 'Online' ? 'zoom' : 'clocation') +
												'.svg',
										)}
										alt="Location"
										className="between-space"
									/>
								</div>
								<div>
									<span className="card-sticky-content g_reg">
										{course?.courseMode?.name === 'Online' ? 'Zoom' : course?.address}
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{open && (
				<Login
					open={open}
					setOpen={setOpen}
					toggleLoginOpen={toggleLoginOpen}
					toggleRegisterOpen={toggleRegisterOpen}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
			{openRegister && (
				<Register
					openRegister={openRegister}
					handleRegisterClose={handleRegisterClose}
					setOpenRegister={setOpenRegister}
					toggleRegisterOpen={toggleRegisterOpen}
					toggleLoginOpen={toggleLoginOpen}
				/>
			)}
		</>
	);
};

export default DetailHeroSection;
