import React from 'react';
import { toAbsoluteUrl } from 'assets/helpers/AssetHelpers';
import { CourseEnrollmentObjectType } from '../Course/type';
import { useCourseContext } from 'context/CourseContext';
import { useUserContext } from 'context/UserContext';
import CardInformation from 'components/_v2/CommonComponents/CardInformation';
import { CardHeight } from 'components/_v2/CommonComponents/types';
import useCourses from 'hooks/useCourses';
import toast from 'react-hot-toast';

const CompletedCourse = ({
	enrollmentCourses,
	onGoToMyCourse,
	hasPurchasedAnyCourse,
}: {
	enrollmentCourses: CourseEnrollmentObjectType[];
	onGoToMyCourse: () => void;
	hasPurchasedAnyCourse: boolean;
}) => {
	const { userCourses } = useCourseContext();
	const { downloadCertificateForCourse } = useCourses();
	const { user } = useUserContext();
	if (enrollmentCourses.length === 0) {
		return (
			<section
				className="d-flex align-items-center justify-content-center"
				style={{ height: '70vh' }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-flex justify-content-center align-items-center">
								<img
									className="smallImageWidth"
									src={toAbsoluteUrl('/media/nodata/course-nodata.png')}
									alt="No data found"
								/>
							</div>
							<div className="text-center">
								<h2 className="nodata-title lora">No Courses Here</h2>
								<p className="nodata-label">
									{hasPurchasedAnyCourse
										? 'You have purchased courses but haven’t completed any yet. Keep learning to complete your courses.'
										: 'We will notify you once you have purchased a course.'}
								</p>
								<button className="btn btn_goto_course g_mid w-auto" onClick={onGoToMyCourse}>
									Go to My Course
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		<div className="row d-flex course-card-section-spacing gy-lg-4 mt-0">
			{enrollmentCourses.map((course) => (
				<CardInformation
					course={course.course}
					disableHoverInteraction={true}
					isUserSpecificContent={true}
					userCourses={userCourses}
					user={user}
					cardHeight={CardHeight.AUTO}
					allowDownloadCertificate={course.allow_certificate_download}
					areQuizzesCompleted={course.allow_certificate_download}
					hasCardOffset
					handleDownloadPDF={(setLoading) =>
						downloadCertificateForCourse({
							courseId: course.course.id,
							onCompletedCallback: () => setLoading(false),
							onErrorCallback: () => {
								toast.error('Certificate downloaded Failed!', {
									className: 'g_mid',
								});
								setLoading(false);
							},
						})
					}
				/>
			))}
		</div>
	);
};

export default CompletedCourse;
