// src/layouts/RootLayout.jsx
import React from "react";
import { useLocation } from "react-router-dom";
import { MasterLayout } from "./MasterLayout";
import { DashboardLayout } from "./DashboardLayout";

const RootLayout = () => {
  const location = useLocation();
  const isDashboardPath = location.pathname.startsWith("/dashboard");

  return isDashboardPath ? <DashboardLayout /> : <MasterLayout />;
};

export { RootLayout };
