export const BASE_PAGE_URL = '/';
export const DASHBOARD_URL = '/dashboard';

export const getBasePageSubUrl = (pageRoute: string) => {
	return `${BASE_PAGE_URL}/${pageRoute}`;
};

export const getDashboardSubUrl = (pageRoute: string) => {
	return `${DASHBOARD_URL}/${pageRoute}`;
};

export const getDetailsSubUrl = (courseId: string) => {
	return `${DETAIL_URL}/${courseId}`;
};

export const getCoursePaymentSubUrl = (courseSlug: string) => {
	return `${ENROLL_URL}/${courseSlug}`;
};

export const getViewCourseWithSearchSlug = (courseSlug: string) => {
	return `${VIEW_COURSE_DATA_URL}?course-slug=${courseSlug}`;
};

export const PAYMENT_HISTORY = 'payment-history';
export const MY_PROFILE = 'my-profile';
export const PRIVACY_POLICY_URL = '/privacy-policy';
export const TNC_URL = '/terms-condition';
export const CONTACT_US = '/contact-us';
export const ABOUT_US = '/about-us';
export const COURSE_URL = '/all-courses';
export const DETAIL_URL = '/course-details';
export const ENROLL_URL = '/payment-details';
export const PAYMENT_STATUS_URL = '/payment-status';
export const HOME_URL = '/dashboard/home';
export const OFFLINE_COURSE_URL = '/dashboard/offline-course';
export const ONLINE_COURSE_URL = '/dashboard/offline-course';
export const MY_COURSE_URL = '/dashboard/my-course';
export const ONGOING_COURSE_URL = '/dashboard/ongoing-course';
export const COMPLETED_COURSE_URL = '/dashboard/completed-course';
export const PERSONAL_DETAIL_URL = '/dashboard/personal-detail';
export const CHANGE_PASSWORD_URL = '/dashboard/change-password';
export const PAYMENT_HISTORY_URL = getDashboardSubUrl(PAYMENT_HISTORY);
export const MY_PROFILE_URL = getDashboardSubUrl(MY_PROFILE);
export const VIEW_COURSE = '/view-course';
export const VIEW_COURSE_DATA_URL = MY_COURSE_URL + VIEW_COURSE;
export const NOTIFICATIONS_LIST = 'notification-list';
export const ZOOM_MEETING = '/zoom-meeting';
