import clsx from "clsx";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { CHANGE_PASSWORD } from "../../Courses-Page/core/requests";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,20}$/;

const ChangePasswordSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .required("Old Password is required")
    .matches(
      passwordRegExp,
      "Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter",
    ),
  newpassword: Yup.string()
    .required("New Password is required")
    .matches(
      passwordRegExp,
      "Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter",
    ),
  confirmpassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
    .matches(
      passwordRegExp,
      "Password must include at least 8 character, one special character, one digit, one uppercase and one lowercase letter",
    ),
});
export const ChangePassword = (props: any) => {
  const { showForgotPasswordModal, handleForgotPasswordClose } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [nshowPassword, setnShowPassword] = useState(false);
  const [cshowPassword, setcShowPassword] = useState(false);
  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD);
  const initialValues = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      try {
        changePasswordMutation({
          variables: {
            changePasswordInput: {
              oldPassword: values.oldpassword,
              newPassword: values.newpassword,
              confirmNewPassword: values.confirmpassword,
            },
          },
          onCompleted: (data: any) => {
            toast.success("Password Updated Successfully");
            handleForgotPasswordClose();
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      } catch (error) {
        // Handle errors, e.g., display an error message
        console.error(error);
      }
    },
  });

  return (
    <Modal
      show={showForgotPasswordModal}
      onHide={handleForgotPasswordClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold head1 font-title">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mx-2">
        <form onSubmit={formik.handleSubmit}>
          <>
            <div className="col-12 mt-3">
              <label className="form-label text-muted fs-6">Old Password</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter Your Password"
                  autoComplete="off"
                  {...formik.getFieldProps("oldpassword")}
                  className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                        formik.touched.oldpassword && formik.errors.oldpassword,
                    },
                    {
                      "is-valid":
                        formik.touched.oldpassword &&
                        !formik.errors.oldpassword,
                    },
                  )}
                />
                <button
                  type="button"
                  className="btn position-absolute eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <i
                    className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                  ></i>
                </button>
              </div>
              {formik.touched.oldpassword && formik.errors.oldpassword && (
                <div className="text-danger mt-1 fs-7">
                  <i className="fa fa-exclamation-circle me-1"></i>
                  {formik.errors.oldpassword}
                </div>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="form-label text-muted fs-6">New Password</label>
              <div className="input-group">
                <input
                  type={nshowPassword ? "text" : "password"}
                  placeholder="Enter Your Password"
                  autoComplete="off"
                  {...formik.getFieldProps("newpassword")}
                  className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                        formik.touched.newpassword && formik.errors.newpassword,
                    },
                    {
                      "is-valid":
                        formik.touched.newpassword &&
                        !formik.errors.newpassword,
                    },
                  )}
                />
                <button
                  type="button"
                  className="btn position-absolute eye-icon"
                  onClick={() => setnShowPassword(!nshowPassword)}
                >
                  <i
                    className={`fa ${
                      nshowPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </button>
              </div>
              {formik.touched.newpassword && formik.errors.newpassword && (
                <div className="text-danger mt-1 fs-7">
                  <i className="fa fa-exclamation-circle me-1"></i>
                  {formik.errors.newpassword}
                </div>
              )}
            </div>
            <div className="col-12 mt-3">
              <label className="form-label text-muted fs-6">
                Re - Enter New Password
              </label>
              <div className="input-group">
                <input
                  type={cshowPassword ? "text" : "password"}
                  placeholder="Enter Your Password"
                  autoComplete="off"
                  {...formik.getFieldProps("confirmpassword")}
                  className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                        formik.touched.confirmpassword &&
                        formik.errors.confirmpassword,
                    },
                    {
                      "is-valid":
                        formik.touched.confirmpassword &&
                        !formik.errors.confirmpassword,
                    },
                  )}
                />
                <button
                  type="button"
                  className="btn position-absolute eye-icon"
                  onClick={() => setcShowPassword(!cshowPassword)}
                >
                  <i
                    className={`fa ${
                      cshowPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </button>
              </div>
              {formik.touched.confirmpassword &&
                formik.errors.confirmpassword && (
                  <div className="text-danger mt-1 fs-7">
                    <i className="fa fa-exclamation-circle me-1"></i>
                    {formik.errors.confirmpassword}
                  </div>
                )}
            </div>
            <div className="d-grid mt-4">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Continue
              </button>
            </div>
          </>
        </form>
      </Modal.Body>
    </Modal>
  );
};
