import { ButtonType } from 'types/globalTypes';
import './ButtonRenderer.css';

const ButtonRenderer = ({
	typeOfButton,
	handleClick,
	text,
	textClass,
	buttonClass,
}: {
	typeOfButton: ButtonType;
	text: string;
	handleClick: () => void;
	textClass?: string;
	buttonClass?: string;
}) => {
	return (
		<button
			className={`btn ${buttonClass ? buttonClass : ''} ${
				typeOfButton === ButtonType.PRIMARY ? 'button-primary' : 'button-secondary'
			}`}
			onClick={handleClick}>
			<span className={textClass}> {text}</span>
		</button>
	);
};

export default ButtonRenderer;
